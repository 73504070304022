import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { has } from 'lodash-es';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { formatName } from '~/helper/formatting';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Member } from 'types';

type Props = {
  groupID: number;
  member: Member;
  onRequestClose: OnRequestCloseType;
};

const BlockMemberModal = ({ member, groupID, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<{ data: Member }>();
  const displayName = formatName(member);

  useEffect(() => {
    if (fetcher.type === 'done') {
      const isError = has(fetcher.data, 'data.error');
      let closeAnnouncement = t('shared.errorOccurred');

      if (!isError) {
        closeAnnouncement = t('blockMemberModal.successfullyBlockedMember');
      }

      onRequestClose(closeAnnouncement);
    }
  }, [fetcher.data, fetcher.state, fetcher.type, onRequestClose, t]);

  return (
    <Modal show onClose={onRequestClose}>
      <fetcher.Form method="post" action={`groups/${groupID}/members`}>
        <h1 className="fk-modalHeader">{t('blockMemberModal.blockStudentUsername', { student: displayName })}</h1>
        <p className="fk-modalBody">
          <Trans i18nKey="blockMemberModal.blockedStudentsLocationExplanation">
            <span className="fk-fontWeight__bold">{{ student: displayName }}</span> will be removed from the group and
            won't be allowed to ask to join again. You can unblock students at any time by going to the blocked section
            of the students tab.
          </Trans>
        </p>
        <Modal.Actions className="mt2">
          <Button theme="tertiary" data-testid="blockMemberModal__button__cancel" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="blockMember"
            theme="danger"
            loading={fetcher.state === 'submitting' || fetcher.state === 'loading'}
            data-testid="blockMemberModal__button__block"
          >
            {t('common.block')}
          </ButtonSubmit>
          <input type="hidden" name="blocked" value="true" />
          <input type="hidden" name="blockedMemberID" value={member.id} />
          <input type="hidden" name="gridID" value={groupID} />
        </Modal.Actions>
      </fetcher.Form>
    </Modal>
  );
};
export default BlockMemberModal;
