import {
  ModalConsumer,
  IcFluentPersonRemove24Regular,
  IcFluentPersonAdd24Regular,
  IcFluentMoreHorizontal24Regular,
  IcFluentBlock24Regular,
  Dropdown,
} from '@flipgrid/flipkit';
import { useContext, type KeyboardEvent, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import MemberUsernameActions from './MemberUsernameActions';
import BlockMemberModal from '../Modals/MemberActions/BlockMemberModal';
import PromoteToCoLeadModal, {
  links as promoteToCoLeadModalStyles,
} from '../Modals/MemberActions/PromoteToCoLeadModal';
import RemoveMemberModal from '../Modals/MemberActions/RemoveMemberModal';
import RemoveCoLeadModal from '../Modals/RemoveCoLeadModal';
import globalContext from '~/contexts/globalContext';
import { formatName } from '~/helper/formatting';
import { isFlagPresent } from '~/helper/helper';
import { isMemberGroupOwner } from '~/helper/userRoles';
import { useGroupsStateMachine } from '~/statemachine/GroupsStateMachineContext';

import type { LinksFunction } from '@remix-run/node';
import type { Group, Member } from 'types';

export const links: LinksFunction = () => [...promoteToCoLeadModalStyles()];

type Props = {
  group: Group;
  member: Member;
};

const MemberActionsDropdown = ({ group, member }: Props) => {
  const { t } = useTranslation();
  const isGroupOwner = isMemberGroupOwner(group);
  const { membership } = group;
  const isUsernameGroup = group.access_control === 'student';
  const isInvitedUser = !member.first_name;
  const isMemberOwner = member.role === 'owner' || member.role === 'co_owner';
  const isCurrentUser = member.user_id === membership?.user_id;
  const groupStateMachine = useGroupsStateMachine();
  const { send } = groupStateMachine.groupsService;

  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  if (isDeprecated) {
    return null;
  }

  return (
    <Dropdown
      button={
        <Dropdown.Button
          aria-label={t('memberActionsDropdown.dropdownButton')}
          data-testid="memberActionsDropdown__button__dropdown"
          icon={<IcFluentMoreHorizontal24Regular />}
          onClick={(e: MouseEvent | KeyboardEvent) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          size="36"
          theme="secondary"
        />
      }
    >
      <ModalConsumer>
        {({ showModal }) =>
          // Show for username groups and group leads/co-leads
          isMemberOwner && isUsernameGroup && isGroupOwner ? (
            <MemberUsernameActions group={group} member={member} showModal={showModal} />
          ) : // Show 'Remove as co-lead" modal for self when current user is a co-lead
          isCurrentUser && member.role === 'co_owner' ? (
            <Dropdown.Item
              data-testid="memberActionsDropdown__dropdownItem__removeCoLeadSelf"
              icon={<IcFluentPersonRemove24Regular />}
              onSelect={() =>
                showModal(RemoveCoLeadModal, {
                  accessControl: group.access_control,
                  removingSelf: true,
                  groupId: group.id,
                  membership,
                  updateLeftRailOnRemoval: () => send('REMOVE', { group }),
                })
              }
            >
              {t('shared.removeAsCoLead')}
            </Dropdown.Item>
          ) : // Show 'Remove as co-lead" modal for other when current user is lead
          isGroupOwner && member.role === 'co_owner' ? (
            <Dropdown.Item
              data-testid="memberActionsDropdown__dropdownItem__removeCoLeadOther"
              icon={<IcFluentPersonRemove24Regular />}
              onSelect={() =>
                showModal(RemoveCoLeadModal, {
                  removingSelf: false,
                  groupId: group.id,
                  email: member.email,
                  userName: member.first_name,
                })
              }
            >
              {t('shared.removeAsCoLead')}
            </Dropdown.Item>
          ) : (
            <>
              {!isInvitedUser && (
                <>
                  {isGroupOwner &&
                    !['owner', 'co_owner', 'pending', 'invited'].includes(member.role) &&
                    member.email && (
                      <Dropdown.Item
                        icon={<IcFluentPersonAdd24Regular />}
                        onSelect={() => showModal(PromoteToCoLeadModal, { member, groupID: group.id })}
                        data-testid="memberActionsDropdown__dropdownItem__makeLead"
                      >
                        {t('memberActionsDropdown.makeLead')}
                      </Dropdown.Item>
                    )}
                  {isUsernameGroup && <MemberUsernameActions group={group} member={member} showModal={showModal} />}
                  <Dropdown.Item
                    data-testid="memberActionsDropdown__dropdownItem__block"
                    icon={<IcFluentBlock24Regular />}
                    onSelect={() => showModal(BlockMemberModal, { groupID: group.id, member })}
                  >
                    {t('common.block')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item
                data-testid="memberActionsDropdown__dropdownItem__removeMember"
                icon={<IcFluentPersonRemove24Regular />}
                onSelect={() => showModal(RemoveMemberModal, { groupID: group.id, member })}
              >
                {formatName(member)
                  ? t('memberActionsDropdown.removeName', { name: formatName(member) })
                  : t('memberActionsDropdown.removeMember')}
              </Dropdown.Item>
            </>
          )
        }
      </ModalConsumer>
    </Dropdown>
  );
};

export default MemberActionsDropdown;
