import {
  ActionGroup,
  Anchor,
  Button,
  IcFluentDismissCircle24Filled,
  IcFluentWindowNew,
  Input,
  Loader,
  PartyPopper } from
'@flipgrid/flipkit';
import { useFetcher, useLocation, useMatches } from '@remix-run/react';
import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  msTeamsInit,
  msTeamsSetMicrosoftSettings,
  msTeamsSetValidityState } from
'~/helper/microsoftTeamsService.client';
import teamsTabPageStyles from '~/styles/components/Teams/TeamsTabPage.css';

import type { MutableRefObject } from 'react';

export const links = () => [{ rel: 'stylesheet', href: teamsTabPageStyles }];

type SearchData = {
  // this type may not be the full response object
  data: {
    name: string;
  };
  joinCode: string;
  response_status: 'success' | 'failure' | 'undefined';
  searchInput: string;
};

const TeamsTabPage = () => {
  const fetcher = useFetcher<SearchData | undefined>();
  const formRef = (useRef() as MutableRefObject<HTMLFormElement>);
  const location = useLocation();
  const [rootData] = useMatches();
  const { t } = useTranslation();

  const [hasInitialized, setHasInitialized] = useState(false);
  const [isClearingSearch, setIsClearingSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState<SearchData>();
  const [error, setError] = useState({ foundGroup: false });

  const { env } = rootData.data;

  useEffect(() => {
    if (!hasInitialized) {
      msTeamsInit().
      then(() => {
        setHasInitialized(true);
      }).
      catch(() => {
        setHasInitialized(false);
      });
    }
  }, [hasInitialized, setHasInitialized]);

  useEffect(() => {
    if (isClearingSearch) formRef.current.reset();
    setIsClearingSearch(false);
  }, [isClearingSearch, setIsClearingSearch]);

  useEffect(() => {
    if (hasInitialized) {
      if (search) msTeamsSetValidityState(true);else
      msTeamsSetValidityState(false);
    }
  }, [hasInitialized, search]);

  useEffect(() => {
    if (hasInitialized) {
      const apiResponseStatus = fetcher.data?.response_status;
      if (!apiResponseStatus || apiResponseStatus === 'undefined' || apiResponseStatus === 'failure') {
        setSearch(undefined);
        setError({ foundGroup: false });
      } else if (apiResponseStatus === 'success' && fetcher?.data) {
        const validName = generateValidDisplayName(fetcher.data?.data && fetcher.data.data?.name || t('common.flip'));

        setSearch({ ...fetcher.data });
        setError({ foundGroup: true });

        msTeamsSetMicrosoftSettings(validName, fetcher.data?.joinCode, fetcher.data?.searchInput);
        msTeamsSetValidityState(true);
      }
    }
  }, [fetcher.data, hasInitialized, setSearch, setError, t]);

  const clearSearch = () => {
    setIsClearingSearch(true);
    setSearchTerm('');
    setSearch(undefined);
    setError({ foundGroup: false });
  };

  const generateValidDisplayName = (name: string) => {
    return name.length <= 30 ? name : `${name.substring(0, 27)}...`;
  };

  const extractJoinCode = (uri: string) => {
    return uri.trim().substring(uri.trim().lastIndexOf('/') + 1);
  };

  const handleFormChange = (event: React.SyntheticEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget);
    const searchInput = (formData.get('searchInput') as string);
    const joinCode = extractJoinCode(searchInput);

    searchGroups(joinCode, searchInput);
  };

  const searchGroups = debounce((joinCode: string, searchInput: string) => {
    setSearchTerm(searchInput);

    if (joinCode.length !== 0 && joinCode.length > 1) {
      const qs = new URLSearchParams([
      ['joinCode', joinCode],
      ['searchInput', searchInput]]
      );
      fetcher.load(`${location.pathname}?${qs}`);
    }
  }, 700);

  return (
    <div className="teamsTabPage__container">
      <div className="teamsTabPage__searchForm">
        <fetcher.Form className="searchForm__form" onChange={handleFormChange} ref={formRef}>
          <Input
            autoComplete="off"
            autoFocus
            className="form__searchInput"
            data-testid="teamsTabPage__input__search"
            error={searchTerm.length > 1 && fetcher.state === 'idle' && !error.foundGroup}
            floatingLabel
            label={t('teamsTabPage.enterJoinCode')}
            name="searchInput"
            placeholder={t('teamsTabPage.enterJoinCode')}
            type="text" />

          {searchTerm.length > 0 && fetcher.state === 'idle' &&
          <Button
            aria-label={t('teamsTabPage.clearSearch')}
            className="form__closeButton"
            data-testid="teamsTabPage__button__clearSearch"
            icon={<IcFluentDismissCircle24Filled />}
            onClick={clearSearch}
            theme="clear" />}


          {(fetcher.state === 'submitting' || fetcher.state === 'loading') && <Loader container />}
          {searchTerm.length > 1 && hasInitialized && fetcher.state === 'idle' && error.foundGroup &&
          <div className="teamsTabPage__resultsSummary" data-testid="teamsTabPage__div__successWrapper">
              <div className="resultsSummary__success animated fadeIn">
                <>
                  <b>{t('teamsTabPage.foundIt')}</b>&nbsp;
                  <PartyPopper height={25} width={25} />
                  <br />
                  <Trans i18nKey="teamsTabPage.addToChannel">
                    Click <i>Save</i> below to add it to your Channel.
                  </Trans>
                </>
              </div>
            </div>}

          {searchTerm.length > 1 && hasInitialized && fetcher.state === 'idle' && !error.foundGroup &&
          <div className="teamsTabPage__resultsSummary" data-testid="teamsTabPage__div__failureWrapper">
              <div className="resultsSummary__failure animated fadeIn">
                <>
                  <Trans i18nKey="teamsTabPage.incorrectJoinCode">
                    <b>Whoops!</b> It looks like you entered an incorrect join code.
                  </Trans>
                </>
              </div>
            </div>}

          {searchTerm.length > 1 && !hasInitialized && fetcher.state === 'idle' && !error.foundGroup &&
          <div className="teamsTabPage__resultsSummary" data-testid="teamsTabPage__div__failureWrapper">
              <div className="resultsSummary__failure animated fadeIn">{t('teamsTabPage.addInTeams')}</div>
            </div>}

          {searchTerm.length < 1 && fetcher.state === 'idle' &&
          <div className="teamsTabPage__buttons">
              <ActionGroup justify="split" stack="xsmall">
                <Anchor
                data-testid="teamsTabPage__link__createAccount"
                icon={<IcFluentWindowNew />}
                newTab
                size="44"
                to={`${env.CLIENT_AUTH_SERVICE_URL}/signup`}
                variant="button">

                  {t('teamsTabPage.signUp')}
                </Anchor>
                <Anchor
                data-testid="teamsTabPage__link__login"
                icon={<IcFluentWindowNew />}
                newTab
                size="44"
                to={env.CLIENT_AUTH_SERVICE_URL}
                variant="button">

                  {t('teamsTabPage.signIn')}
                </Anchor>
              </ActionGroup>
            </div>}

        </fetcher.Form>
      </div>
    </div>);

};

export default TeamsTabPage;