import { useEventListener } from '@flipgrid/flipkit';

import type { MouseEvent, RefObject } from 'react';

function useClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (e: MouseEvent) => void,
): void {
  const eventHandler = (e: MouseEvent) => {
    const el = ref?.current;

    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(e.target as Node)) {
      return;
    }

    handler(e);
  };

  // When another action is meant to trigger the handler, and is also outside the ref, use mousedown with stopPropagation()
  useEventListener('mousedown', eventHandler);
  useEventListener('touchstart', eventHandler);
}

export default useClickOutside;
