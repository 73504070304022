import { useMatches } from '@remix-run/react';
import { useEffect, useState } from 'react';

import { msTeamsInit, msTeamsAuthFailure, msTeamsAuthSuccess } from '~/helper/microsoftTeamsService.client';
import { authServiceRedirectUrl, googleSSOUrl, microsoftSSOUrl } from '~/helper/urlHelper.client';

const TeamsAuthPage = () => {
  const [routeData] = useMatches();
  const { env } = routeData.data;
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    console.log('[TeamsAuthPage] Attempting to initialize, hasInitialized: ', hasInitialized);
    if (!hasInitialized) {
      msTeamsInit()
        .then(() => {
          setHasInitialized(true);

          console.log('[TeamsAuthPage] Entered useEffect(), hasInitialized: ', hasInitialized);

          const qs = new URLSearchParams(window.location.search);
          console.log('[TeamsAuthPage] qs: ', qs);

          const authState = qs.get('state');
          console.log('[TeamsAuthPage] authState: ', authState);

          const decodedAuthState = authState ? JSON.parse(atob(authState)) : null;
          console.log('[TeamsAuthPage] decodedAuthState: ', decodedAuthState);

          // When opening it from the button, they hit this logic that redirects them to the SSO pages
          if (decodedAuthState && decodedAuthState.provider) {
            console.log('[TeamsAuthPage] Entered decoded auth + provider');
            decodedAuthState.isMicrosoftTeams = true; // Lets the server know it's a Microsoft Teams SSO login attempt
            const ssoUrl =
              decodedAuthState.provider === 'google'
                ? googleSSOUrl(
                    decodedAuthState,
                    authServiceRedirectUrl(env.CLIENT_AUTH_SERVICE_URL, 'google_oauth2'),
                    env.GOOGLE_CLIENT_ID,
                  )
                : microsoftSSOUrl(
                    decodedAuthState,
                    authServiceRedirectUrl(env.CLIENT_AUTH_SERVICE_URL, 'microsoft_graph'),
                    env.MSFT_CLIENT_ID,
                  );

            console.log('[TeamsAuthPage] ssoUrl: ', ssoUrl);
          }
          // They will hit this else section when coming back after SSO
          // and will either notify success or failure to the client
          else {
            const hasError = qs.get('error');
            if (hasError) {
              console.log('[TeamsAuthPage] hasError');
              msTeamsAuthFailure();
            } else {
              console.log('[TeamsAuthPage] success');
              msTeamsAuthSuccess();
            }
          }
        })
        .catch((e: TSFix) => {
          console.log('[TeamsAuthPage] Error thrown, Error:', e?.toString());
          // eslint-disable-next-line no-alert
          alert(`Error::${e?.toString()}`);
          setHasInitialized(false);
        });
    }
  }, [env.CLIENT_AUTH_SERVICE_URL, env.GOOGLE_CLIENT_ID, env.MSFT_CLIENT_ID, hasInitialized, setHasInitialized]);

  return null;
};

export default TeamsAuthPage;
