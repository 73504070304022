import {
  Add16Solid,
  Button,
  Dropdown,
  IcFluentPeople24Regular,
  IcFluentTextNumberFormat24Regular,
  ModalConsumer } from
'@flipgrid/flipkit';
import { useNavigate, useLocation, useMatches } from '@remix-run/react';
import { useActor } from '@xstate/react';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import JoinCodeModal from '../Modals/JoinCodeModal';
import MaxAccountModal from '../Modals/MaxAccountsModal';
import TeachingBubble from '~/components/TeachingBubble/TeachingBubble';
import HandleIds from '~/constants/handleIds';
import routes from '~/constants/routes';
import globalContext from '~/contexts/globalContext';
import { isFlagPresent, logEvent } from '~/helper/helper';
import { isUserEducator, isUserLeadAccountType } from '~/helper/userRoles';
import useGetUser from '~/hooks/useGetUser';
import { useGroupsStateMachine } from '~/statemachine/GroupsStateMachineContext';
import newGroupDropdownStyles from '~/styles/routes/index/newGroupDropdownIndex.css';

import type { ShowModalType } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: newGroupDropdownStyles }];

const NewGroupDropdown = ({ collapsedRail }: {collapsedRail: boolean;}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const groupStateMachine = useGroupsStateMachine();
  const [state] = useActor(groupStateMachine.groupsService);
  const { groups, error } = state.context;
  const user = useGetUser();
  const location = useLocation();
  const isCreatingGroup = location.pathname === routes.GROUPS_NEW;
  const isEducator = isUserEducator(user);
  const isLeadAccountType = isUserLeadAccountType(user);
  const isFRE = groups.length === 0 && isEducator && !isCreatingGroup && !error;
  const isLoading = state.context.loading;
  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  const {
    breakPoints,
    choseCreate,
    choseJoin,
    closedCreate,
    closedJoin,
    closedIntentModal,
    setClosedCreate,
    setClosedIntentModal,
    setClosedJoin
  } = useContext(globalContext);
  const [showZeroStateTeachingBubble, setShowZeroStateTeachingBubble] = useState(false);
  const [zeroStateTeachingBubbleSeen, setZeroStateTeachingBubbleSeen] = useState(false);
  const [zeroStateTeachingBubbleLogged, setZeroStateTeachingBubbleLogged] = useState(false);
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];
  const isHome = lastMatch.handle?.id === HandleIds.Home;
  const hasLeftRail = breakPoints.screenWidth >= 768;

  useEffect(() => {
    const triggeredFromClosedIntentModal = !choseJoin && !choseCreate && closedIntentModal;
    const triggeredFromClosedJoin = choseJoin && closedJoin;
    const triggeredFromClosedCreate = choseCreate && closedCreate;

    if (
    isHome &&
    hasLeftRail && (
    triggeredFromClosedIntentModal || triggeredFromClosedJoin || triggeredFromClosedCreate))
    {
      setShowZeroStateTeachingBubble(true);
      setZeroStateTeachingBubbleSeen(true);
    }
  }, [choseJoin, closedIntentModal, choseCreate, closedJoin, closedCreate, isHome, hasLeftRail]);

  useEffect(() => {
    if (zeroStateTeachingBubbleSeen) {
      logEvent({ name: 'ZeroStateTeachingBubble::Displayed' });
    }
  }, [zeroStateTeachingBubbleSeen]);

  const onZeroStateTeachingBubbleClose = () => {
    setShowZeroStateTeachingBubble(false);
    setClosedIntentModal(false);
    setClosedCreate(false);
    setClosedJoin(false);
  };

  const autoFocusFirstGroupItem = () => {
    // AutoFocus to first item of dropdown menu
    const groupDropdownButton = document.getElementsByClassName('newGroupDropdown__button')[0];
    if (groupDropdownButton) {
      // The keyboard event doesn't fire off immediately if user is navigating by keyboard... this 100ms timeout is to ensure the autoFocus is triggered.
      setTimeout(() => {
        if (groupDropdownButton.getAttribute('aria-expanded')) {
          const groupDropdownList = document.getElementsByClassName('fk-dropdown__list')[0];
          if (groupDropdownList && groupDropdownList.childNodes) {
            const kbEvent = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              code: 'ArrowDown',
              which: 40,
              bubbles: true,
              cancelable: true,
              keyCode: 40,
              altKey: false,
              ctrlKey: false,
              shiftKey: false
            });
            groupDropdownList.dispatchEvent(kbEvent);
          }
        }
      }, 100);
    }
  };

  const onNewGroupClick = (hasViewedZeroStateTeachingBubble: boolean) => {
    if (hasViewedZeroStateTeachingBubble && !zeroStateTeachingBubbleLogged) {
      logEvent({ name: 'ZeroStateTeachingBubble::TargetClickedAfterDisplayed' });
      setZeroStateTeachingBubbleLogged(true);
    }

    autoFocusFirstGroupItem();
  };

  const onGroupCreation = (showModal: ShowModalType) => {
    if (isDeprecated) {
      return;
    }
    if (!isEducator) {
      showModal(MaxAccountModal, { redirectRoute: routes.GROUPS_NEW });
      return;
    }
    navigate(routes.GROUPS_NEW);
  };

  if (isDeprecated) {
    return null;
  }

  return (
    <>
      <ModalConsumer>
        {({ showModal }) =>
        <>
            <div className={classNames('newGroupDropdown', { fre: isFRE && !isLoading })}>
              <Dropdown
              button={
              <Dropdown.Button
                disabled={isDeprecated}
                id="newGroupDropdownButton" // Used for keyboard navigation
                aria-controls=""
                actionInnerClassName="newGroupDropdown__iconWrapper"
                aria-label={t('shared.createAGroupDropdown')} // We need label here for collapsed state which hides the normal text
                className="newGroupDropdown__button"
                data-testid="newGroupDropdown__dropdownButton__createAGroupDropdown"
                icon={
                <span className="newGroupDropdown__icon">
                        <Add16Solid width="20" height="20" />
                      </span>}

                theme="nav"
                variant="text"
                onClick={() => onNewGroupClick(zeroStateTeachingBubbleSeen)}>

                    {!collapsedRail ? t('common.group') : null}
                  </Dropdown.Button>}>


                {(isEducator || isLeadAccountType || !user.age_confirmed_at) &&
              <Dropdown.Item
                data-testid="newGroupDropdown__dropdownItem__createAGroup"
                icon={<IcFluentPeople24Regular />}
                onSelect={() => onGroupCreation(showModal)}>

                    {t('shared.createAGroup')}
                  </Dropdown.Item>}

                <Dropdown.Item
                data-testid="newGroupDropdown__dropdownItem__joinAGroup"
                icon={<IcFluentTextNumberFormat24Regular />}
                onSelect={() =>
                isDeprecated ?
                () => {
                  return null;
                } :
                showModal(JoinCodeModal)}>


                  {t('shared.joinAGroup')}
                </Dropdown.Item>
              </Dropdown>
            </div>
          </>}

      </ModalConsumer>

      {showZeroStateTeachingBubble &&
      <TeachingBubble
        className="teachingBubble--zero-state"
        target={`[data-testid="newGroupDropdown__dropdownButton__createAGroupDropdown"]`}
        onTeachingBubbleClose={onZeroStateTeachingBubbleClose}
        focusTrapZoneProps={{
          forceFocusInsideTrap: false
        }} // Without this, + menu target requires two clicks to open
      >
          <div className="teachingBubble__content">
            <p className="m0 color__inherit">{t('teachingBubble.leadCreateOrJoinGroup')}</p>
          </div>
          <div className="teachingBubble__buttonWrapper">
            <Button
            aria-label={t('common.gotIt')}
            className="teachingBubble__ctaBtn"
            theme="secondary"
            data-testid="teachingBubble__button__next"
            onClick={onZeroStateTeachingBubbleClose}>

              {t('common.gotIt')}
            </Button>
          </div>
        </TeachingBubble>}

    </>);

};

export default NewGroupDropdown;