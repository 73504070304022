import { Announcer as FlipKitAnnouncer } from '@flipgrid/flipkit';
import { useCallback, useRef } from 'react';

import { useMutationObserver } from '../../hooks/useMutationObserver';

type Props = {
  liveMessage?: string;
};

const Announcer = ({ liveMessage }: Props) => {
  const announcerRef = useRef<HTMLDivElement | null>(null);

  const handleMutations = useCallback((mutations: TSFix) => {
    mutations.forEach(({ type, target }: { type: MutationRecordType; target: Element | null }) => {
      if (type === 'attributes' && target?.getAttribute('aria-hidden') === 'true') {
        target.setAttribute('aria-hidden', 'false');
      }
    });
  }, []);

  useMutationObserver({
    target: announcerRef,
    options: { attributes: true },
    callback: handleMutations,
  });

  return <FlipKitAnnouncer ref={announcerRef} message={liveMessage} />;
};

export default Announcer;
