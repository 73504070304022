import DraggerNext, { links as draggerNextStyles } from './DraggerNext';
import DraggerPrev, { links as draggerPrevStyles } from './DraggerPrev';

import type { LinksFunction } from '@remix-run/node';

type Props = {
  ariaLabelNext: string;
  ariaLabelPrev: string;
  formState: TSFix;
  nextClass?: string;
  offset: number;
  prevClass?: string;
};

export const links: LinksFunction = () => [...draggerNextStyles(), ...draggerPrevStyles()];

const DraggerButtons = ({
  ariaLabelNext,
  ariaLabelPrev,
  formState,
  nextClass = '',
  offset = 200,
  prevClass = '',
}: Props) => {
  return (
    <>
      <DraggerPrev ariaLabel={ariaLabelPrev} className={prevClass} formState={formState} offset={offset} />
      <DraggerNext ariaLabel={ariaLabelNext} className={nextClass} formState={formState} offset={offset} />
    </>
  );
};

export default DraggerButtons;
