import { logEvent } from './helper';

import type {
  AppLogicPayload,
  BackdropActivationTimePayload,
  BackdropSetupTimePayload,
  CameraErrorsPayload,
  CameraErrorsType,
  DegradedUxPayload,
  LensActivationTimePayload,
  LensAverageFPSPayload,
  LensClearPayload,
  LensesLoadTimePayload,
  LensResizePayload,
  LensSessionSetupTimePayload,
  LensSetupErrorPayload,
  LensSetupTimePayload,
  MusicAddedFailedPayload,
  RecordingFinishedPayload,
  RecordingSessionStartedPayload,
  ScreenRecorderVideoProcessingOrchestratorStageName,
  StartRecordingErrorPartialPayload,
  UserActionPayload,
  VideoDownloadedPayload,
  VideoProcessingOrchestratorErrors,
  QosEventType,
  EcsFlagsPayload,
} from '@onecamera/core';

export const telemetryCallbacks = (id: string) => {
  return {
    logEcsFlags: (payload: EcsFlagsPayload) => logEvent({ name: 'Camera::EcsFlags' }, { payload, cameraSessionId: id }),

    logStateChanged: (payload: TSFix) => logEvent({ name: 'Camera::StateChanged' }, { payload, cameraSessionId: id }),

    logProcessingStageEntered: () => logEvent({ name: 'Camera::ProcessingStageEntered' }, { cameraSessionId: id }),

    logProcessingStageFailed: (reason: VideoProcessingOrchestratorErrors) =>
      logEvent({ name: 'Camera::ProcessingStageFailed' }, { reason, cameraSessionId: id }),

    logVideoProcessingOrchestratorStage: (
      stage: ScreenRecorderVideoProcessingOrchestratorStageName,
      stageDetails?: AnyObj | string,
    ) => logEvent({ name: 'Camera::VideoProcessingOrchestratorStage' }, { stage, stageDetails, cameraSessionId: id }),

    logTrimWarning: (reason: VideoProcessingOrchestratorErrors) =>
      logEvent({ name: 'Camera::TrimWarning' }, { reason, cameraSessionId: id }),

    logScreenCaptureMounted: () => logEvent({ name: 'Camera::ScreenCaptureMounted' }, { cameraSessionId: id }),

    logRecorderStepMounted: () => logEvent({ name: 'Camera::RecorderStepMounted' }, { cameraSessionId: id }),

    logRecordingFinished: (partialPayload: RecordingFinishedPayload) =>
      logEvent({ name: 'Camera::RecordingFinished' }, { ...partialPayload, cameraSessionId: id }),

    logRecordingSessionStarted: (partialPayload: RecordingSessionStartedPayload) =>
      logEvent({ name: 'Camera::RecordingSessionStarted' }, { ...partialPayload, cameraSessionId: id }),

    logRecordingSessionResumed: (partialPayload: RecordingSessionStartedPayload) =>
      logEvent({ name: 'Camera::RecordingSessionResumed' }, { ...partialPayload, cameraSessionId: id }),

    logEditPageNextButtonClicked: () =>
      logEvent({ name: 'Camera::EditPageNextButtonClicked' }, { cameraSessionId: id }),

    logOpenShareScreen: () => logEvent({ name: 'Camera::OpenShareScreen' }, { cameraSessionId: id }),

    logStartRecordingFailureWarn: (payload: StartRecordingErrorPartialPayload) =>
      logEvent({ name: 'Camera::StartRecordingFailureWarn' }, { ...payload, cameraSessionId: id }),

    logStartRecordingFailureFatal: (payload: StartRecordingErrorPartialPayload) =>
      logEvent({ name: 'Camera::StartRecordingFailureFatal' }, { ...payload, cameraSessionId: id }),

    logPreviewVideoPlayError: (mediaError?: MediaError) =>
      logEvent({ name: 'Camera::PreviewVideoPlayError' }, { reason: mediaError?.message, cameraSessionId: id }),

    logCancelScreenShare: () => logEvent({ name: 'Camera::CancelScreenShare' }, { cameraSessionId: id }),

    logTrimCommitted: () => logEvent({ name: 'Camera::TrimCommitted' }, { cameraSessionId: id }),

    logWebCodecsNotSupported: (errorMessage: string) =>
      logEvent({ name: 'Camera::WebCodecsNotSupported' }, { reason: errorMessage, cameraSessionId: id }),

    logSessionRecoveryAlertRendered: () =>
      logEvent({ name: 'Camera::SessionRecoveryAlertRendered' }, { cameraSessionId: id }),

    logSessionRecovered: () => logEvent({ name: 'Camera::SessionRecovered' }, { cameraSessionId: id }),

    logSessionRecoveryFailed: (error: string) =>
      logEvent({ name: 'Camera::SessionRecoveryFailed' }, { error, cameraSessionId: id }),

    logSessionRecoverySegmentSaved: () =>
      logEvent({ name: 'Camera::SessionRecoverySegmentSaved' }, { cameraSessionId: id }),

    logSessionRecoverySegmentSaveFailed: () =>
      logEvent({ name: 'Camera::SessionRecoverySegmentSaveFailed' }, { cameraSessionId: id }),

    logGenerateThumbnailFailure: (error: Error) =>
      logEvent({ name: 'Camera::GenerateThumbnailFailure' }, { error, cameraSessionId: id }),

    logVideoDownloaded: (payload: VideoDownloadedPayload) =>
      logEvent({ name: 'Camera::VideoDownloaded' }, { ...payload, cameraSessionId: id }),

    logUserAction: (payload: UserActionPayload) =>
      logEvent({ name: 'Camera::UserAction' }, { ...payload, cameraSessionId: id }),

    logMusicAddedFailed: (payload: MusicAddedFailedPayload) =>
      logEvent({ name: 'Camera::MusicAddedFailed' }, { ...payload, cameraSessionId: id }),

    // Passing in empty function for now until we have resolution in the core
    // and have this log vetted by the privacy team
    logDeviceSet: () => () => {},

    logBackdropSetupTime: (payload: BackdropSetupTimePayload) =>
      logEvent({ name: 'Camera::BackdropSetupTime' }, { ...payload, cameraSessionId: id }),

    logBackdropActivationTime: (payload: BackdropActivationTimePayload) =>
      logEvent({ name: 'Camera::BackdropActivationTime' }, { ...payload, cameraSessionId: id }),

    logBackdropProcessTime: (payload: BackdropSetupTimePayload) =>
      logEvent({ name: 'Camera::BackdropProcessTime' }, { ...payload, cameraSessionId: id }),

    logDegradedUx: (payload: DegradedUxPayload) =>
      logEvent({ name: 'Camera::DegradedUx' }, { ...payload, cameraSessionId: id }),

    logLensSetupTime: (payload: LensSetupTimePayload) =>
      logEvent({ name: 'Camera::LensSetupTime' }, { ...payload, cameraSessionId: id }),

    logLensSetupError: (payload: LensSetupErrorPayload) =>
      logEvent({ name: 'Camera::LensSetupError' }, { ...payload, cameraSessionId: id }),

    logLensSessionSetupTime: (payload: LensSessionSetupTimePayload) =>
      logEvent({ name: 'Camera::LensSessionSetupTime' }, { ...payload, cameraSessionId: id }),

    logLensesLoadTime: (payload: LensesLoadTimePayload) =>
      logEvent({ name: 'Camera::LensesLoadTime' }, { ...payload, cameraSessionId: id }),

    logLensActivationTime: (payload: LensActivationTimePayload) =>
      logEvent({ name: 'Camera::LensActivationTime' }, { ...payload, cameraSessionId: id }),

    logLensAverageFPS: (payload: LensAverageFPSPayload) =>
      logEvent({ name: 'Camera::LensAverageFPS' }, { ...payload, cameraSessionId: id }),

    logLensResize: (payload: LensResizePayload) =>
      logEvent({ name: 'Camera::LensResizePayload' }, { ...payload, cameraSessionId: id }),

    logClearLens: (payload: LensClearPayload) =>
      logEvent({ name: 'Camera::ClearLens' }, { ...payload, cameraSessionId: id }),

    logAppLogic: (payload: AppLogicPayload) =>
      logEvent({ name: 'Camera::AppLogic' }, { ...payload, cameraSessionId: id }),

    logError: (type: CameraErrorsType, payload: CameraErrorsPayload) =>
      logEvent({ name: 'Camera::Error' }, { ...payload, type, cameraSessionId: id }),

    logQoSEvent: (payload: QosEventType) => logEvent({ name: 'Camera::QoSEvent' }, { payload, cameraSessionId: id }),
  };
};
