import { Dropdown, IcFluentChatHelp24Regular } from '@flipgrid/flipkit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { loadHelpCenter, toggleHelpCenter, setClose } from './HelpCenterControls';

type Props = {
  'aria-label'?: string;
  onHelpCenterLoaded: (loaded: boolean) => void;
};

const HelpCenter = ({ 'aria-label': ariaLabel, onHelpCenterLoaded }: Props) => {
  const { t } = useTranslation();

  // Need to load at the beginning of the lifecycle of this component.
  useEffect(() => {
    loadHelpCenter().then(() => {
      onHelpCenterLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropdownOnSelect = () => {
    toggleHelpCenter().then(() => {
      setTimeout(() => {
        const style = document.createElement('style');
        style.innerHTML =
          "svg[data-testid='Icon--search'] { color: #707070 !important; } \n" +
          "\ninput[data-testid='search-field']::placeholder { color: #707070; }";
        setClose();
      }, 100);
    });
  };

  return (
    <Dropdown.Item aria-label={ariaLabel} onSelect={dropdownOnSelect} icon={<IcFluentChatHelp24Regular />}>
      {t('common.helpCenter')}
    </Dropdown.Item>
  );
};

export default HelpCenter;
