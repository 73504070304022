import { Modal, Smubble, Anchor, Button, Loader } from '@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import externalLinks from '~/constants/externalLinks';
import resourceRoutes from '~/constants/resourceRoutes';
import Telemetry from '~/constants/telemetry';
import { telemetryAttributes } from '~/helper/helper';
import { mobileDeepLinkUrl } from '~/helper/urlHelper';
import useGetUser from '~/hooks/useGetUser';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Topic } from 'types';
import type { UaDetectServer } from 'types/helperTypes';

type Props = {
  onRequestClose: OnRequestCloseType;
} & (
  | {
      entity?: never;
      modalType: 'standalone' | 'topicMedia';
    }
  | {
      entity: {
        item: Topic;
        type: 'Topic';
      };
      modalType: 'addResponse';
    }
);

const MobileModal = ({ entity, modalType, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const [rootData] = useMatches();
  const [gridToken, setGridToken] = useState('');
  const ua: UaDetectServer = rootData.data.ua;
  const isAndroidDevice = ua.android;
  const linkToStore = isAndroidDevice ? externalLinks.GooglePlayStoreToFlip : externalLinks.ITunesStoreToFlip;
  const onlyLinkToAppStore = modalType !== 'addResponse';
  const user = useGetUser();
  const fetcher = useFetcher<string>();

  useEffect(() => {
    if (fetcher.type === 'done') {
      setGridToken(fetcher.data);
    }
  }, [fetcher]);

  useEffect(() => {
    fetcher.load(resourceRoutes.gridToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mobileDeepLink = mobileDeepLinkUrl(
    entity?.item.vanity_token || '',
    entity?.item.id?.toString() || '',
    gridToken,
    user.canvasStudentUser?.lti_token || '',
  );

  let bodyText = t('mobileModal.downloadAppLatestExperience');
  let headerText = t('mobileModal.flipBetterApp');

  if (modalType === 'addResponse') {
    bodyText = t('mobileModal.useAppToShare');
    headerText = t('mobileModal.addVideoInApp');
  }

  return (
    <Modal onClose={onRequestClose}>
      <div className="mobileModal__logoWrapper">
        <div className="mobileModal__logoBackdrop">
          <Smubble height={50} />
        </div>
      </div>
      <div className="mobileModal__contentWrapper">
        <h1 className="fk-modalHeader mobileModal__header">{headerText}</h1>
        <p className="fk-modalBody">{bodyText}</p>
        {!gridToken && fetcher.type !== 'done' ? (
          <Loader container />
        ) : isAndroidDevice ? (
          <div className="mobileModal__actionWrapper">
            <Anchor
              to={linkToStore}
              variant="button"
              data-testid="mobileModal__anchor__download"
              newTab
              {...telemetryAttributes(Telemetry.MobilePromptDownloadApp)}
            >
              {t('common.download')}
            </Anchor>
            {!onlyLinkToAppStore && (
              <Button
                theme="secondary"
                data-testid="mobileModal__button__openApp"
                onClick={() => window.open(mobileDeepLink)}
                {...telemetryAttributes(Telemetry.MobilePromptOpenApp)}
              >
                {t('common.open')}
              </Button>
            )}
          </div>
        ) : (
          <div className="mobileModal__actionWrapper">
            <Anchor
              to={linkToStore}
              variant="button"
              data-testid="mobileModal__anchor__iOSDownloadApp"
              newTab
              {...telemetryAttributes(Telemetry.MobilePromptDownloadApp)}
            >
              {t('common.download')}
            </Anchor>
            <Anchor
              to={mobileDeepLink}
              variant="button"
              data-testid="mobileModal__anchor__iOSOpenApp"
              newTab
              {...telemetryAttributes(Telemetry.MobilePromptOpenApp)}
            >
              {t('common.open')}
            </Anchor>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default MobileModal;
