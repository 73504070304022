import { Modal, Loader, TopicCard, uaDetect } from '@flipgrid/flipkit';
import { useFetcher, useLocation, useMatches, useParams } from '@remix-run/react';
import { Suspense, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHydrated } from 'remix-utils';

import MobileModal from './MobilePrompts/MobileModal';
import handleIds from '~/constants/handleIds';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import { reactionType } from '~/constants/videoReactionConstants';
import recorderContext from '~/contexts/recorderContext';
import { getCookie } from '~/cookies';
import { logEvent, lazyLoadWithRetries } from '~/helper/helper';
import { clientRefreshUser, getClientTokens } from '~/helper/helper.client';
import { telemetryCallbacks } from '~/helper/recorder';
import sanitize from '~/helper/sanitize';
import { isUserEducator } from '~/helper/userRoles';

import type { PropertyBag, RecorderError, RouteTyping, Topic, User } from '../../types';
import type { TopicType } from '@flipgrid/flipkit/dist/src/TopicCard/TopicCard';
import type { ThumbnailAspectRatio } from '@onecamera/core/build/types/types/CameraProps';
import type { RecorderStatusEnum } from '~/enums';

const FgRecorder = lazyLoadWithRetries(async () => {
  const markNameStart = 'FgRecorderImportStart';
  const markNameEnd = 'FgRecorderImportEnd';
  const measureName = 'FgRecorderImport';
  performance.mark(markNameStart);

  // @ts-ignore
  const recorder = await import('./Camera/src/index');

  performance.mark(markNameEnd);
  performance.measure(measureName, markNameStart, markNameEnd);

  // Time is in ms
  const measure = performance.getEntriesByName(measureName)[0];
  logEvent(
    { name: 'FgRecorderImport::Time' },
    {
      duration: measure.duration,
    },
  );

  performance.clearMarks(markNameStart);
  performance.clearMarks(markNameEnd);
  performance.clearMeasures(measureName);

  return recorder;
});

type RecorderPropsBase = {
  defaultCaptureStep?: 'camera' | 'screen_capture' | 'import_video' | undefined;
  form:
    | {
        allowDisplayNameEdits?: boolean;
        route: string;
        showEmailField?: boolean;
        type: 'topicResponse';
        [key: string]: TSFix;
      }
    | {
        route: string;
        type: 'standalone';
        [key: string]: TSFix;
      };
  onCloseRecorder?: (navUrl: string, propertyBag?: PropertyBag) => void;
  onMobileCloseRecorder?: (navUrl: string, page: string) => void;
  thumbnailAspectRatio?: ThumbnailAspectRatio;
  user: User;
};

type CommentProfileEntityProps = {
  entity: {
    parentId: string;
    topic: Topic;
    type: 'Comment';
  };
  authToken?: TSFix; // authToken isn't required here, because guests can leave video response comments (I think)
} & RecorderPropsBase;

type TopicEntityProps = {
  entity: {
    topic?: Topic;
    type: 'Topic' | 'TopicFocus' | 'Profile';
  };
  authToken?: TSFix; // not sure why authToken isn't required here
} & RecorderPropsBase;

type ProfileEntityProps = {
  entity: {
    topic?: never;
    type: 'Profile';
  };
  authToken?: TSFix;
} & RecorderPropsBase;

type Props = {
  callbacks?: {};
  entity:
    | {
        parentId: string;
        topic: Topic;
        type: 'Comment';
      }
    | {
        topic: Topic;
        type: 'Topic' | 'TopicFocus';
        topicResponseUrl?: string;
        reactionType?: string;
      }
    | {
        topic?: never;
        type: 'Profile';
      };
} & (CommentProfileEntityProps | TopicEntityProps | ProfileEntityProps);

const formatPropertyBagAsFormData = (
  propertyBag: PropertyBag,
  useSendBeacon: boolean,
  params: { groupid?: string; topicid?: string },
) => {
  const formData = new FormData();

  if (params.groupid) formData.append('grid_id', params.groupid);

  if (params.topicid) formData.append('topic_id', params.topicid);

  if (propertyBag.submittedResponse?.id) formData.append('response_id', propertyBag.submittedResponse.id.toString());

  const metadata = {
    camera_interactions: propertyBag.camera_interactions,
    camera_interactions_post: propertyBag.camera_interactions_post,
  };
  formData.append('metadata', JSON.stringify(metadata));
  formData.append('retake_count', propertyBag.retake_count.toString());
  formData.append('video_length', propertyBag.video_length.toString());
  formData.append('total_record_time', propertyBag.total_record_time.toString());
  formData.append('undo_count', propertyBag.undo_count.toString());
  formData.append('segment_count', propertyBag.segment_count.toString());
  formData.append('segment_time_count', propertyBag.segment_time_count.toString());
  formData.append(
    'complete',
    JSON.stringify(useSendBeacon || !!(propertyBag.submittedResponse && propertyBag.submittedResponse.id)),
  );
  formData.append('device', propertyBag.device);
  formData.append('upload_duration', propertyBag.time_on_upload_step.toString());
  return formData;
};

const Recorder = ({
  authToken,
  callbacks,
  defaultCaptureStep = 'camera',
  entity,
  form: { redirectOnRequestAsync = true, ...form },
  onCloseRecorder,
  onMobileCloseRecorder,
  thumbnailAspectRatio = '4x5',
  user,
}: Props) => {
  const { hideRecorder, isUploadingAsync, setIsUploadingAsync, setRecorderStatus } = useContext(recorderContext);
  const { topic, type } = entity;
  const [savedPropertyBag, savePropertyBag] = useState<PropertyBag | null>(null);
  const [hasSentRecordStats, setHasSentRecordStats] = useState(false);
  const { i18n, t } = useTranslation();
  const fetcher = useFetcher();
  const matches = useMatches();
  const params = useParams();
  const location = useLocation();
  const data = matches.find(m => m.handle?.id === handleIds.Root)?.data as RouteTyping<'Root'>;
  const { env: clientEnv } = data;
  const [token, setToken] = useState(authToken);
  const [hasRefreshedTokens, setHasRefreshedTokens] = useState(false);
  const [sessionId] = useState(Date.now().toString() + Math.random().toString().slice(2));
  const isHydrated = useHydrated();

  if (!token && type !== 'Comment' && typeof window !== 'undefined') {
    getClientTokens(location, clientEnv, user, data.ua.browserIsSameSiteNoneCookieCompatible).then(tokens => {
      if (tokens) setToken({ Authorization: 'Bearer ' + tokens.accessToken });
    });
  }

  const loadUploadSuccessNavigationURL = () => {
    if (type === 'Profile' && savedPropertyBag) {
      // User create a profile video
      if (!savedPropertyBag.topic && savedPropertyBag.submittedResponse) {
        // Handle pushing to profile
        return routes.MY_VIDEOS;
      }
      // Posted video to a topic, navigate them to that topic!
      if (savedPropertyBag.topic) {
        const { topic: newTopic } = savedPropertyBag;
        return routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(newTopic?.grid_id, newTopic?.id);
      }
    } else if (type === 'Topic') {
      return routes.GROUPS_ID_TOPICS_FUNC(topic?.grid_id);
    }

    return routes.HOME;
  };

  /**
   * Send the recorder stats to Kusto
   * @param propertyBag
   */
  const logRecorderStats = (propertyBag: PropertyBag) => {
    // If propertyBag is null, we don't have any stats to send
    if (!propertyBag) return;

    // Remove PII
    const recorderStats = {
      camera_interactions: propertyBag.camera_interactions,
      camera_interactions_post: propertyBag.camera_interactions_post,
      camera_rendering: propertyBag.camera_rendering,
      dropped_frame_count: propertyBag.dropped_frame_count,
      retake_count: propertyBag.retake_count,
      segment_count: propertyBag.segment_count,
      segment_time_count: propertyBag.segment_time_count,
      thumbnailAspectRatio: propertyBag.thumbnailAspectRatio,
      time_on_upload_step: propertyBag.time_on_upload_step,
      total_record_time: propertyBag.total_record_time,
      undo_count: propertyBag.undo_count,
      video_length: propertyBag.video_length,
      hasStitchedSegment: propertyBag.hasStitchedSegment,
    };

    logEvent({ name: 'Camera::RecorderStats' }, { recorderStats, cameraSessionId: sessionId });
  };

  const trackRecorderStats = (propertyBag: PropertyBag, useSendBeacon: boolean) => {
    if (hasSentRecordStats) return;

    savePropertyBag(propertyBag);
    setHasSentRecordStats(true);
    logRecorderStats(propertyBag);
    const formData = formatPropertyBagAsFormData(propertyBag, useSendBeacon, params);
    formData.append('_action', 'recorderStats');
    fetcher.submit(formData, {
      method: 'post',
      action: resourceRoutes.recorder,
    });
  };

  const handleError = (error: RecorderError, propertyBag: PropertyBag) => {
    // If error.level is not set, default to 'error'
    if (!error.level) error.level = 'error';

    // This is primarily for handling the case where the post to the /responses endpoint fails
    // Here we refresh their tokens so that when they retry the post
    if (
      error.extraData &&
      error.extraData.error &&
      (error.extraData.error.status === 403 || error.extraData.error.status === 401) &&
      !hasRefreshedTokens // No sense in refreshing tokens if we've already done it once
    ) {
      const refreshToken = getCookie('REFRESH_TOKEN', document.cookie);

      if (refreshToken) {
        clientRefreshUser(refreshToken, clientEnv, data.ua.browserIsSameSiteNoneCookieCompatible)
          .then(tokens => {
            if (tokens) {
              setToken({ Authorization: 'Bearer ' + tokens.accessToken });
              setHasRefreshedTokens(true);
            } else {
              logEvent(
                { name: 'Camera::Error' },
                { error: 'Reissuing access token failed', cameraSessionId: sessionId },
              );
            }
          })
          .catch(() => {
            logEvent(
              { name: 'Camera::Error' },
              { error: 'Catch: Reissuing access token failed', cameraSessionId: sessionId },
            );
          });
      } else logEvent({ name: 'Camera::Error' }, { error: "Couldn't refresh user", cameraSessionId: sessionId });
    }

    logEvent(
      { name: 'Camera::Error' },
      {
        error,
        propertyBag: {
          ...propertyBag,
          // Can be removed later, just testing a theory that expired tokens is the issue
          accessTokenExists: !!getCookie('ACCESS_TOKEN', document.cookie),
          refreshTokenExists: !!getCookie('REFRESH_TOKEN', document.cookie),
        },
        cameraSessionId: sessionId,
      },
    );
  };

  const onHandleRecorderClose = (propertyBag: PropertyBag, isAsyncUpload: boolean) => {
    if (propertyBag && !hasSentRecordStats && !isAsyncUpload) trackRecorderStats(propertyBag, isUploadingAsync);

    if (onCloseRecorder) {
      const url = loadUploadSuccessNavigationURL();
      onCloseRecorder(url, propertyBag);
    }
  };

  const onMobileModalClose = () => {
    if (onMobileCloseRecorder) {
      const url = loadUploadSuccessNavigationURL();
      onMobileCloseRecorder(url, type);
    }
  };

  const onResponseCreation = (propertyBag: PropertyBag) => {
    if (propertyBag) trackRecorderStats(propertyBag, false);

    // close recorder on upload completion for async flows
    if (onCloseRecorder && isUploadingAsync) onHandleRecorderClose(propertyBag, true);
  };

  const onRequestAsyncUpload = () => {
    const shouldRedirect = !!redirectOnRequestAsync;

    setIsUploadingAsync(true);
    hideRecorder(shouldRedirect);
  };

  const cameraCallbacks = {
    onPageUnload: (propertyBag: PropertyBag) => trackRecorderStats(propertyBag, true),
    onHide: onRequestAsyncUpload,
    onError: handleError,
    onRequestAsyncUpload,
    onVideoUploadStatusChange: (status: RecorderStatusEnum) => setRecorderStatus(status),
    onRequestClose: onHandleRecorderClose,
    onResponseCreation,
    ...callbacks,
  };

  const props = {
    allowDownloads: type === 'Topic' || type === 'Comment' ? topic.allow_downloads : true,
    topic: topic as TSFix,
    parentID: type === 'Comment' ? entity.parentId : undefined,
    backdropUrl:
      type === 'Topic' && entity.reactionType === reactionType.GREEN_SCREEN ? entity.topicResponseUrl : undefined,
    stitchVideoUrl:
      type === 'Topic' && entity.reactionType === reactionType.STITCH ? entity.topicResponseUrl : undefined,
    effects:
      type === 'Topic' || type === 'Comment'
        ? {
            essentials: topic.camera_essentials,
            expressions: topic.camera_expressions,
          }
        : {
            essentials: true,
            expressions: true,
          },
    responseLength:
      type === 'Profile' ? (isUserEducator(user) ? 600 : 90) : type === 'TopicFocus' ? 600 : topic.response_length,
    topicCard: type === 'Topic' && (
      <TopicCard
        // @ts-ignore
        topic={{ ...(topic as TopicType), topicResourceLabel: t('shared.goToTopicMedia') }}
        showImmersiveReader={false}
        sanitize={sanitize}
      >
        <></>
      </TopicCard>
    ),
  };

  useEffect(() => {
    type CameraQosEventType = 'timeToStartCameraQos';
    const cameraLoadStartTime: CameraQosEventType = 'timeToStartCameraQos';
    logEvent({ name: 'Camera::QoSEvent' }, { cameraLoadStartTime, cameraSessionId: sessionId });
  }, [sessionId]);

  return (
    <>
      {data.ua.mobile || (isHydrated && uaDetect.mobile()) ? (
        type === 'Profile' ? (
          <MobileModal modalType="standalone" onRequestClose={onMobileModalClose} />
        ) : type === 'TopicFocus' ? (
          <MobileModal modalType="topicMedia" onRequestClose={onMobileModalClose} />
        ) : (
          <MobileModal
            modalType="addResponse"
            onRequestClose={onMobileModalClose}
            entity={{ type: 'Topic', item: topic }}
          />
        )
      ) : (
        <Modal
          theme="recorder"
          className="responseRecorder -fullscreen fk-theme__dark"
          overlayClassName="responseRecorder__overlay"
          onClose={() => {}} // TODO: Check on this
          closeClassName="hidden"
          dangerouslyBypassFocusLock={isUploadingAsync}
          dangerouslyBypassScrollLock={isUploadingAsync}
        >
          <Suspense fallback={<Loader className="recorder__loader" container />}>
            <FgRecorder
              {...props}
              apiUrl={clientEnv.CLIENT_API_URL}
              authToken={token}
              backdropApiUrl={clientEnv.CAMERA_API_URL + '/backdrops'}
              backgroundApiUrl={clientEnv.CAMERA_API_URL + '/create_mode_backdrops'}
              cameraCallbacks={cameraCallbacks as TSFix} // todo: this should be split into camera callbacks and custom event callbacks
              defaultCaptureStep={defaultCaptureStep}
              form={form}
              giphyApiKey={clientEnv.GIPHY_API_KEY ?? ''}
              // @ts-expect-error
              language={i18n.language}
              lensApiKey={clientEnv?.SNAP_API_KEY ?? ''}
              lensGroupID={clientEnv.SNAP_GROUP_ID ?? ''}
              lensLowPerfGroupID={clientEnv.SNAP_LOW_PERF_GROUP_ID ?? ''}
              mediaApiUrl={clientEnv.CLIENT_MEDIA_URL}
              musicApiUrl={clientEnv.CAMERA_API_URL + '/moods_songs'}
              sessionRecoveryDBName="Flip_Camera_SessionData"
              sessionRecoveryID={user?.id ? user.id.toString() : undefined}
              shareBaseUrl={clientEnv.CLIENT_SHARE_URL + '/s/'}
              telemetryCallbacks={telemetryCallbacks(sessionId)}
              thumbnailConfiguration={{
                aspectRatio: thumbnailAspectRatio,
                isProcessingPreviewEnabled: true,
                width: undefined,
              }}
              user={user}
              videoEditing
            />
          </Suspense>
        </Modal>
      )}
    </>
  );
};

export default Recorder;
