import { Button, EmptyState, ModalConsumer, Breakpoints } from '@flipgrid/flipkit';
import { useMatches } from '@remix-run/react';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClientOnly } from 'remix-utils';

import AddTopicCard, { links as addTopicCardStyles } from './AddTopicCard';
import ExploreDiscoveryCard, { links as exploreDiscoveryCardStyles } from './ExploreDiscoveryCard';
import Dragger, { links as draggerStyles } from '../Dragger/Dragger';
import DraggerButtons, { links as draggerButtonsStyles } from '../Dragger/DraggerButtons';
import DraggerNext, { links as draggerNextStyles } from '../Dragger/DraggerNext';
import DraggerPrev, { links as draggerPrevStyles } from '../Dragger/DraggerPrev';
import TopicTemplateModal, { links as topicTemplateModalStyles } from '../Modals/Discovery/TopicTemplateModal';
import FollowGroupModal from '../Modals/GroupDetails/FollowGroupModal';
import adminExternalLinks from '~/constants/adminExternalLinks';
import externalLinks from '~/constants/externalLinks';
import HandleIds from '~/constants/handleIds';
import GlobalContext from '~/contexts/globalContext';
import topicListContext from '~/contexts/topicListContext';
import { isMemberGroupLead } from '~/helper/userRoles';
import topicListEmptyStateStyles from '~/styles/components/TopicList/TopicListEmptyState.css';

import type { DraggerFormState, DraggerRef } from '../Dragger/Dragger';
import type { ShowModalType } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';
import type { Group, CollectionItem, RouteTyping } from 'types';

type Props = {
  group: Group;
};

export const links: LinksFunction = () => {
  return [
  ...addTopicCardStyles(),
  ...exploreDiscoveryCardStyles(),
  ...draggerStyles(),
  ...draggerButtonsStyles(),
  ...draggerNextStyles(),
  ...draggerPrevStyles(),
  ...topicTemplateModalStyles(),
  { rel: 'stylesheet', href: topicListEmptyStateStyles }];

};

const TopicListEmptyState = ({ group }: Props) => {
  const { t } = useTranslation();
  const matches = useMatches();
  const ua = (matches.find((m) => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const { collectionItems, discoveryTotal } = useContext(topicListContext);
  const [draggerRef, setDraggerRef] = useState({});
  const [frame, setFrame] = useState({
    x: 0,
    progress: 0
  });

  const { studentView } = useContext(topicListContext);
  const isGroupLead = isMemberGroupLead(group, studentView);

  const [routeData] = useMatches();

  const { breakPoints } = useContext(GlobalContext);
  const mobileOffset = 235;
  const mediumOffset = breakPoints.isScreenMedium ? 350 : 240;
  const draggerPaddingOffset = 112;
  const draggerDisabled = breakPoints.screenWidth >= Breakpoints.xlarge + draggerPaddingOffset;
  const showButtons = !breakPoints.isScreenExtraLarge;

  const openModal = (showModal: ShowModalType, itemId: number) => {
    showModal(TopicTemplateModal, {
      topicTemplateId: itemId.toString(),
      traversable: false
    });
  };

  const exploreOnClick = () => {
    window.open(adminExternalLinks.DISCOVERY_URL(routeData.data.env.ADMIN_URL));
  };

  const collectionCards = () => {
    return collectionItems.slice(0, 3).map((collectionItem: CollectionItem, index: number) => {
      if (!collectionItem) return null;
      return (
        <AddTopicCard
          collectionItem={collectionItem}
          draggerDisabled={draggerDisabled}
          openModal={openModal}
          key={
          collectionItem && collectionItem.item ?
          `add-topic-card-${collectionItem.item.id}` :
          `add-topic-card-${index}`} />);



    });
  };

  const memberTopicListEmptyState = (showModal: ShowModalType) =>
  <EmptyState className="topicListEmptyState__memberView">
      <img alt="" src={externalLinks.Parachute} />
      <h1>{t('common.comingSoon')}</h1>
      <p>
        {group.subscribable ?
      <Trans i18nKey="groupDetails.setNotifications">
            Your group lead is setting up this space. Set up
            <Button
          data-testid="groupDetails__button__notifications"
          variant="text"
          onClick={() => showModal(FollowGroupModal, { isGroupLead, group })}>

              notifications
            </Button>
            to stay up to date.
          </Trans> :

      t('groupDetails.groupLeadSettingUp')}

      </p>
    </EmptyState>;


  const leadTopicListEmptyState = (showModal: ShowModalType) =>
  <div className="topicListEmptyState_container">
      <div className="topicListEmptyState_wrapper">
        <div className="topicListEmptyState__header">
          <h3>{t('topicListEmptyState.getStartedWithTopics')}</h3>
          {ua?.mobile &&
        <div className="topicListEmptyState__draggerButtons">
              <DraggerButtons
            formState={{
              disableNext: false,
              disablePrev: false,
              draggerRef,
              frame
            }}
            offset={mobileOffset}
            ariaLabelNext={t('shared.nextTopic')}
            ariaLabelPrev={t('shared.prevTopic')} />

            </div>}

        </div>
        <div className="topicListEmptyState__draggerContainer">
          {ua?.mobile && showButtons &&
        <DraggerPrev
          ariaLabel={t('shared.prevTopic')}
          className="topicListEmptyState__draggerPrev"
          formState={{
            disablePrev: false,
            draggerRef,
            frame
          }}
          offset={mediumOffset} />}


          <Dragger
          className="ov"
          disabled={draggerDisabled}
          dataSetIsLoaded={collectionItems && collectionItems.length > 0}
          setFormState={(values: DraggerFormState | DraggerRef) => {
            if ('frame' in values) setFrame(values.frame);
            if ('draggerRef' in values) setDraggerRef(values.draggerRef);
          }}
          onStaticClick={(value: HTMLElement) => {
            if (value.dataset && value.dataset.topic) {
              openModal(showModal, parseInt(value.dataset.topic, 10));
            } else if (value.dataset && value.dataset.explore) {
              exploreOnClick();
            }
          }}>

            <div className="topicListEmptyState__cards">
              {collectionItems?.length && collectionCards()}
              <ExploreDiscoveryCard
              discoveryTopicTotal={discoveryTotal}
              draggerDisabled={draggerDisabled}
              exploreOnClick={exploreOnClick} />

            </div>
          </Dragger>
          {ua && !ua.mobile && showButtons &&
        <DraggerNext
          ariaLabel={t('shared.nextTopic')}
          className="topicListEmptyState__draggerNext"
          formState={{
            disableNext: false,
            disablePrev: true,
            draggerRef,
            frame
          }}
          offset={mediumOffset} />}


        </div>
      </div>
    </div>;


  return (
    <ClientOnly>
      {() =>
      <ModalConsumer>
          {({ showModal }) => isGroupLead ? leadTopicListEmptyState(showModal) : memberTopicListEmptyState(showModal)}
        </ModalConsumer>}

    </ClientOnly>);

};

export default TopicListEmptyState;