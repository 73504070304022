export const toggleHelpCenter = () => {
  if (typeof window.zE === 'function') {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'toggle');
    return Promise.resolve();
  }
  return Promise.reject();
};

export const helpCenterUpdateLabels = labels => {
  if (typeof window.zE === 'function') window.zE('webWidget', 'helpCenter:setSuggestions', { labels });
};

export const setClose = () => {
  window.zE('webWidget:on', 'close', function hideZE() {
    window.zE.hide();
  });
};

export const loadHelpCenter = () => {
  return new Promise((resolve, reject) => {
    // If the script is already present, do not add another.
    const scriptPresent = document.getElementById('ze-snippet');
    if (!scriptPresent) {
      const script = document.createElement('script');
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=e42fd9a7-4b0b-4757-9a29-4c4391d03c62';
      script.id = 'ze-snippet';
      script.onerror = error => {
        reject(error);
      };
      script.onload = () => {
        resolve();
        window.zESettings = {
          webWidget: {
            position: { horizontal: 'right', vertical: 'bottom' },
            color: {
              theme: '#000000',
              launcher: '#000000', // This will also update the badge
              launcherText: '##FFFFFF',
              button: '#8A0648',
              resultLists: '#343434',
              header: '#000000',
              articleLinks: '#0878d4',
            },
            helpCenter: {
              searchPlaceholder: {
                '*': 'Search our Help Center',
              },
            },
          },
        };
      };
      document.body.appendChild(script);
    } else resolve();
  });
};
