import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TransparentTextArea, { links as transparentTextAreaStyles } from '../../Utility/TransparentTextArea';
import ModerationPopover, { links as moderationPopoverStyles } from '../../Utility/components/ModerationPopover';
import { isValidUrl } from '~/helper/helper';
import { handleError } from '~/helper/imgOnError';
import groupFormHeaderStyles from '~/styles/components/Groups/GroupForm/GroupFormHeader.css';

import type { LinksFunction } from '@remix-run/node';
import type { Dispatch, SetStateAction, SyntheticEvent, RefObject } from 'react';
import type { Banner } from 'types';

export const links: LinksFunction = () => [
...transparentTextAreaStyles(),
...moderationPopoverStyles(),
{ rel: 'stylesheet', href: groupFormHeaderStyles }];


type Props = {
  groupName: string;
  error?: boolean;
  isEditing?: boolean;
  isSubmitting?: boolean;
  selectedBanner: Banner;
  setGroupName: Dispatch<SetStateAction<string>>;
  setShowModerationPopover: Dispatch<SetStateAction<boolean>>;
  showModerationPopover: boolean;
  titleRef: RefObject<HTMLDivElement>;
};

const GroupFormHeader = ({
  groupName,
  error,
  isEditing,
  isSubmitting,
  selectedBanner,
  setGroupName,
  setShowModerationPopover,
  showModerationPopover,
  titleRef
}: Props) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={classNames('canvas-column__full groupFormHeader', { '--focused': isFocused })}>
      <img
        className="fullWidthBackgroundImage fullLayout"
        // Sometimes this is a data uri, so check before adding param
        src={isValidUrl(selectedBanner.url) ? `${selectedBanner.url}?size=medium` : selectedBanner.url}
        alt={t('shared.groupCover')}
        onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} />


      <div className="groupFormHeader__imageContent">
        <ModerationPopover
          id="group-title-popover-id"
          targetRef={titleRef}
          isVisible={showModerationPopover}
          onDismiss={() => setShowModerationPopover(false)}
          type="Title"
          placement="bottom-start"
          useArrow={false} />

        <TransparentTextArea
          className="createGroupHeader__groupName"
          ref={titleRef}
          name="groupFormHeader__title"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setGroupName(e.target.value)}
          labelRole="heading"
          label={isEditing ? t('shared.editGroup') : t('shared.createGroup')}
          aria-label={t('groupFormHeader.groupName')}
          maxLength={45}
          aria-required="true"
          aria-describedby="group-title-popover-id"
          placeholder={t('groupFormHeader.enterGroupName')}
          value={groupName}
          showError={error && !isSubmitting}
          error={
          error ?
          isEditing ?
          t('groupFormHeader.titleRequiredForEditing') :
          t('groupFormHeader.titleRequired') :
          undefined}

          type="group"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)} />

      </div>
    </div>);

};

export default GroupFormHeader;