import {
  Dropdown,
  IcFluentArrowSort24Filled,
  IcFluentArrowUp24Regular,
  IcFluentCheckmark24Regular,
  Tooltip,
} from '@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import HandleIds from '~/constants/handleIds';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import useFetcherWatcher from '~/hooks/useFetcherWatcher';

import type { FetcherWithComponents } from '@remix-run/react';
import type { ApiResponse, Group, RouteTyping, Topic, TopicFilter, TopicFilterValues } from 'types';

type Props = {
  fetcher: FetcherWithComponents<ApiResponse<Topic[]>>;
  group: Group;
};

const TopicFilterDropdown = ({ fetcher, group }: Props) => {
  const { t } = useTranslation();
  const matches = useMatches();
  const cookieFetcher = useFetcher();
  const topicsFilter = (
    matches.find(match => match.handle?.id === HandleIds.GroupsTopicsTab)?.data as RouteTyping<'GroupsTopicsTab'>
  )?.topics.filter;
  const [order, setOrder] = useState(topicsFilter?.direction || 'asc');
  const [filter, setFilter] = useState(topicsFilter?.order_by || 'updated_at');

  useFetcherWatcher({
    action: resourceRoutes.userPreferences,
    formDataAction: 'UpdateTopicFilterUserPreferences',
    update: () => {
      fetcher.load(routes.GROUPS_ID_TOPICS_FUNC(group.id) + `?_action=filterUpdate`);
    },
  });

  const filterTopics = (filterValue: TopicFilterValues) => {
    setFilter(filterValue);
    const obj: TopicFilter = {
      order_by: filterValue,
    };

    if (filterValue === 'created_at' || filterValue === 'title') {
      if (order === 'asc') {
        setOrder('desc');
        obj.direction = 'desc';
      } else {
        setOrder('asc');
        obj.direction = 'asc';
      }
    }

    cookieFetcher.submit(
      { topicFilter: JSON.stringify(obj), _action: 'UpdateTopicFilterUserPreferences' },
      { action: resourceRoutes.userPreferences, method: 'post' },
    );
  };

  return (
    <Dropdown
      button={
        <Tooltip label={t('common.sort')}>
          <Dropdown.Button
            aria-controls=""
            data-testid="topicFilterDropdown__button__sortTopic"
            aria-label={t('topicFilterDropdown.dropdown')}
            icon={<IcFluentArrowSort24Filled />}
            size="36"
            theme="tertiary"
          />
        </Tooltip>
      }
    >
      <Dropdown.Item
        onSelect={() => filterTopics('updated_at')}
        icon={
          <IcFluentCheckmark24Regular
            className={filter === 'updated_at' ? 'iconFill__brand' : 'iconFill__transparent'}
          />
        }
        role="menuitemcheckbox"
        aria-checked={filter === 'updated_at' ? 'true' : 'false'}
        data-testid="topicFilterDropdown__dropdownItem__recentActivityFilter"
      >
        {t('shared.recentActivity')}
      </Dropdown.Item>
      <Dropdown.Item
        onSelect={() => filterTopics('created_at')}
        icon={
          order === 'asc' ? (
            <IcFluentArrowUp24Regular
              className={filter === 'created_at' ? 'iconFill__brand' : 'iconFill__transparent'}
            />
          ) : (
            <IcFluentArrowUp24Regular
              className={filter === 'created_at' ? 'iconFill__brand rotate180' : 'iconFill__transparent'}
            />
          )
        }
        role="menuitemcheckbox"
        aria-checked={filter === 'created_at' ? 'true' : 'false'}
        data-testid="topicFilterDropdown__dropdownItem__dateFilter"
      >
        {t('common.date')}
      </Dropdown.Item>
      <Dropdown.Item
        onSelect={() => filterTopics('title')}
        icon={
          order === 'asc' ? (
            <IcFluentArrowUp24Regular className={filter === 'title' ? 'iconFill__brand' : 'iconFill__transparent'} />
          ) : (
            <IcFluentArrowUp24Regular
              className={filter === 'title' ? 'iconFill__brand rotate180' : 'iconFill__transparent'}
            />
          )
        }
        aria-pressed={filter === 'title' ? 'true' : 'false'}
        data-testid="topicFilterDropdown__dropdownItem__titleFilter"
      >
        {t('common.title')}
      </Dropdown.Item>
    </Dropdown>
  );
};

export default TopicFilterDropdown;
