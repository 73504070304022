import { Button, Collapse24Filled, Scrollable, Smubble, useEventListener } from '@flipgrid/flipkit';
import { useFetcher, useMatches, useNavigation } from '@remix-run/react';
import { useEffect, useRef, useState, useContext, useCallback } from 'react';
import FocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';

import MyGroups from './MyGroups';
import NewGroupDropdown from './NewGroupDropdown';
import RailNav from './RailNav';
import Link from '../FkWrappers/Link';
import { HorizontalLine } from '~/components';
import handleIds from '~/constants/handleIds';
import { RailState } from '~/constants/railStateEnum';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import GlobalContext from '~/contexts/globalContext';
import useClickOutside from '~/hooks/useClickOutside';
import railIndexStyles from '~/styles/routes/index/railIndex.css';

import type { RouteTyping } from 'types';

export const links = () => [{ rel: 'stylesheet', href: railIndexStyles }];

type RailProps = {
  collapsedRail: boolean;
  setCollapsedRail: (arg1: boolean) => void;
};

const Rail = ({ collapsedRail, setCollapsedRail }: RailProps) => {
  const { t } = useTranslation();
  const railRef = useRef(null);
  const [didSetRailState, setDidSetRailState] = useState(false);
  const { breakPoints } = useContext(GlobalContext);
  const fetcher = useFetcher();
  const navigation = useNavigation();
  const scrollBreakpoint = breakPoints.screenHeight < 375;
  const matches = useMatches();
  const ua = (matches.find((m) => m.handle?.id === handleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const inIframe = ua?.inIframe;

  const collapseRail = useCallback(
    (isCollapsed: boolean) => {
      setCollapsedRail(isCollapsed);
      fetcher.submit(
        { rail: isCollapsed ? RailState.collapsed : RailState.expanded, _action: 'ToggleRail' },
        { action: resourceRoutes.userPreferences, method: 'post' }
      );
    },
    [fetcher, setCollapsedRail]
  );

  // At mobile if we are changing routes and rail is open, close the rail
  useEffect(() => {
    if (navigation.state === 'loading' && !breakPoints.isScreenMedium && !collapsedRail) collapseRail(true);
  }, [collapseRail, collapsedRail, navigation.state, breakPoints]);

  useEffect(() => {
    if (didSetRailState) return;

    setDidSetRailState(true);
  }, [didSetRailState, setCollapsedRail]);

  useClickOutside(railRef, () => {
    if (!collapsedRail && !breakPoints.isScreenMedium) collapseRail(true);
  });

  const onToggleRail = () => {
    collapseRail(!collapsedRail);
  };

  // At mobile if the rail is open, close on escape
  useEventListener('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape' && !breakPoints.isScreenMedium && !collapsedRail) {
      collapseRail(true);
    }
  });

  const RailContent =
  <>
      <Link
      aria-label={t('shared.backToHome')}
      id="railLogo"
      className="rail__logo-link"
      data-testid="rail__link__home"
      to={routes.HOME}
      theme="clear">

        <Smubble height={24} className="rail__logo" />
      </Link>
      <RailNav collapsedRail={collapsedRail} />

      <div className="rail__ruleWrapper">
        <HorizontalLine className="rail__rule" />
      </div>

      <NewGroupDropdown collapsedRail={collapsedRail} />
      <MyGroups collapsedRail={collapsedRail} shouldScroll={!scrollBreakpoint} />
      <Button
      aria-label={t('shared.toggleRail')}
      className="rail__toggle"
      id="railToggle"
      data-testid="rail__button__toggleRailCollapse"
      icon={<Collapse24Filled fill="var(--fk-color__foreground-3)" width="20" height="20" />}
      onClick={onToggleRail}
      theme="nav"
      size="36" />

    </>;


  return inIframe ? null :
  <div className={`rail ${didSetRailState ? 'is-measured' : ''}`}>
      <FocusLock
      className="rail__inner"
      ref={railRef}
      disabled={breakPoints.isScreenMedium || collapsedRail}
      returnFocus>

        {scrollBreakpoint ? <Scrollable>{RailContent}</Scrollable> : RailContent}
      </FocusLock>
    </div>;

};

export default Rail;