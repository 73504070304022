import { Button, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { has } from 'lodash-es';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import routes from '~/constants/routes';
import declineRequestModalStyles from '~/styles/components/Modals/MemberActions/DeclineRequestModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';
import type { Member } from 'types';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: declineRequestModalStyles }];

type Props = {
  member: Member;
  onRequestClose: OnRequestCloseType;
  groupID: number;
};

const DeclineRequestModal = ({ onRequestClose, member, groupID }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();

  useEffect(() => {
    if (fetcher.type === 'done') {
      const isError = has(fetcher.data, 'data.error');
      let closeAnnouncement = t('shared.errorOccurred');

      if (!isError) {
        closeAnnouncement = t('declineRequestToJoin.successfullyDeclinedMember');
      }
      onRequestClose(closeAnnouncement);
    }
  }, [fetcher.data, fetcher.type, onRequestClose, t]);

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader">
        {t('declineRequestToJoin.declineMemberRequest', { name: member.display_name })}
      </h1>
      <p>
        {t('declineRequestToJoin.declineConfirmationPrompt', {
          name: member.display_name,
          email: member.email
        })}
      </p>
      <Modal.Actions justify="first" className="declineRequestModal__buttons">
        <fetcher.Form method="post" action={routes.GROUPS_ID_MEMBERS_FUNC(groupID)}>
          <Button
            type="submit"
            name="_action"
            value="blockMember"
            variant="text"
            theme="danger"
            data-testid="declineRequestModal__button__block">

            {t('declineRequestToJoin.blockMember')}
          </Button>
          <input type="hidden" name="blocked" value="true" />
          <input type="hidden" name="blockedMemberID" value={member.id} />
          <input type="hidden" name="gridID" value={groupID} />
          <input type="hidden" name="role" value="member" />
        </fetcher.Form>
        <Button onClick={onRequestClose} theme="tertiary" data-testid="declineRequestModal__button__cancel">
          {t('common.cancel')}
        </Button>
        <fetcher.Form method="post" action={routes.GROUPS_ID_MEMBERS_FUNC(groupID)}>
          <Button
            type="submit"
            name="_action"
            value="removeMember"
            theme="danger"
            data-testid="declineRequestModal__button__decline"
            loading={fetcher.state === 'submitting' || fetcher.state === 'loading'}>

            {t('declineRequestToJoin.decline')}
          </Button>
          <input type="hidden" name="removedMemberID" value={member.id} />
          <input type="hidden" name="destroy_responses" value="true" />
          <input type="hidden" name="gridID" value={groupID} />
        </fetcher.Form>
      </Modal.Actions>
    </Modal>);

};

export default DeclineRequestModal;