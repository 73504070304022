import { useEffect, useMemo } from 'react';

// Modified from: https://tobbelindstrom.com/blog/useMutationObserver/

type Props = {
  target?: React.RefObject<Element>;
  options?: MutationObserverInit;
  callback?: MutationCallback;
};

export const useMutationObserver = ({ target, options = {}, callback }: Props): void => {
  const observer = useMemo(
    () =>
      typeof document !== 'undefined'
        ? new MutationObserver((mutationRecord, mutationObserver) => {
            callback?.(mutationRecord, mutationObserver);
          })
        : null,
    [callback],
  );

  useEffect(() => {
    if (observer && target && target.current) {
      observer.observe(target.current, options);
      return () => observer.disconnect();
    }
  }, [target, observer, options]);
};
