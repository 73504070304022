import { Button, ButtonSubmit, IcFluentDismiss24Regular, ModalConsumer } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import DeclineRequestModal, { links as declineRequestModalStyles } from '../Modals/MemberActions/DeclineRequestModal';
import memberListPanelProfileActionsStyles from '~/styles/components/Members/MemberListPanelProfileActions.css';

import type { Group, Member } from 'types';

export const links = () => [
...declineRequestModalStyles(),
{ rel: 'stylesheet', href: memberListPanelProfileActionsStyles }];


type Props = {
  memberStatus: 'member' | 'blocked' | 'requested';
  group: Group;
  member: Member;
  userActionFetcherState: string;
};

const MemberListPanelProfileActions = ({ memberStatus, group, member, userActionFetcherState }: Props) => {
  const { t } = useTranslation();

  return memberStatus === 'requested' ?
  <>
      <ButtonSubmit
      size="36"
      name="_action"
      value="addMember"
      data-testid="memberListPanelProfileActions__button__accept"
      loading={userActionFetcherState !== 'idle'}>

        {t('common.add')}
      </ButtonSubmit>
      <input type="hidden" name="email" value={member.email} />
      <input type="hidden" name="gridID" value={group.id} />
      <input type="hidden" name="role" value="member" />
      <input type="hidden" name="memberId" value={member.id} />
      <ModalConsumer>
        {({ showModal }) =>
      <Button
        data-testid="memberListPanelProfileActions__button__removeFromRTJ"
        size="36"
        icon={<IcFluentDismiss24Regular width="16" height="16" />}
        theme="transparent"
        className="memberListPanelProfileActions__removeRtj"
        onClick={() => showModal(DeclineRequestModal, { member, groupID: group.id })}
        aria-label={t('memberListPanelProfileActions.openDeclineModal')} />}


      </ModalConsumer>
    </> :

  <>
      <ButtonSubmit
      name="_action"
      value="blockMember"
      theme="secondary"
      data-testid="memberListPanelProfileActions__button__unblock"
      loading={userActionFetcherState !== 'idle'}>

        {t('blockedMembers.unblock')}
      </ButtonSubmit>
      <input type="hidden" name="blocked" value="false" />
      <input type="hidden" name="blockedMemberID" value={member.id} />
      <input type="hidden" name="gridID" value={group.id} />
    </>;

};

export default MemberListPanelProfileActions;