import { Accordion } from '@flipgrid/flipkit';
import React from 'react';

import memberListPanelWrapperStyles from '~/styles/components/Members/MemberListPanelWrapper.css';

export const links = () => [{ rel: 'stylesheet', href: memberListPanelWrapperStyles }];

type Props = {
  isLead?: boolean;
  title: string;
  children: React.ReactNode;
  memberPanel?: boolean;
};

const MemberListPanelWrapper = ({ isLead, title, children, memberPanel }: Props) => {
  if (isLead)
  return (
    <Accordion
      title={title}
      open={memberPanel}
      summaryClassName={!memberPanel ? 'memberListPanelWrapper__summary' : undefined}>

        {children}
      </Accordion>);


  return <>{children}</>;
};

export default React.memo(MemberListPanelWrapper);