import { ActivityFeed, Tooltip } from '@flipgrid/flipkit';
import { useFetcher, useLocation, useMatches } from '@remix-run/react';
import { has } from 'lodash-es';
import { Fragment, useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientOnly } from 'remix-utils';

import ActivityFeedItem from './ActivityFeedItem';
import ActivityFeedUploadProgress from './ActivityFeedUploadProgress';
import { filterActivities } from '../../helper/component/filterActivities';
import {
  isToday,
  isYesterday,
  isAfterThisWeek,
  isThisWeekNotTodayYesterday,
  filterByDate,
} from '../../helper/dateHelpers';
import { usePrevious } from '../../hooks/usePrevious';
import Observer from '../Utility/Observer';
import externalLinks from '~/constants/externalLinks';
import resourceRoutes from '~/constants/resourceRoutes';
import GlobalContext from '~/contexts/globalContext';
import recorderContext from '~/contexts/recorderContext';
import { RecorderStatusEnum } from '~/enums';
import { isFlagPresent } from '~/helper/helper';
import { clientFetch, getClientTokens } from '~/helper/helper.client';
import useGetUser from '~/hooks/useGetUser';

import type { ActivityFeedItemType } from '../../../types/ActivityFeedTypes';
import type { ApiResponse, Pagination } from 'types';

const ActivityFeedDropdown = () => {
  const globalContext = useContext(GlobalContext);
  const { recorderStatus } = useContext(recorderContext);
  const location = useLocation();
  const [routeData] = useMatches();
  const { env } = routeData.data;
  const listRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const user = useGetUser();
  const [activities, setActivities] = useState<ActivityFeedItemType[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showNoResultStatus, setShowNoResultStatus] = useState(false);
  const [iconActive, setIconActive] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [hideUploadProgress, setHideUploadProgress] = useState(false);
  const isVideoUploading = recorderStatus === RecorderStatusEnum.Uploading;

  const isDeprecated = isFlagPresent(globalContext.featureFlags, 'web-deprecation');
  const markAsViewedFetcher = useFetcher();

  const loadActivities = useCallback(
    async (page = 1) => {
      if (!hasInitialized) setHasInitialized(true);
      setIsLoading(true);

      try {
        const tokens = await getClientTokens(
          location,
          env,
          user,
          routeData.data.ua.browserIsSameSiteNoneCookieCompatible,
        );
        if (tokens?.accessToken) {
          const { accessToken } = tokens;

          const qs = new URLSearchParams([
            ['page', page.toString()],
            ['per_page', '12'],
          ]);
          const rawResponse = await clientFetch(`${env.CLIENT_ACTIVITY_URL}/activities?${qs}`, 8000, {
            headers: { authorization: `Bearer ${accessToken}` },
            method: 'GET',
          });
          const activityResponse: ApiResponse<ActivityFeedItemType[]> = await rawResponse.json();

          // handle errors
          if (has(activityResponse, 'error') || has(activityResponse, 'data.error')) {
            setIsError(true);
            return;
          }

          // handle null responses that don't have an error, API sends back null responses sometimes
          if (!has(activityResponse, 'data[0]') || !has(activityResponse, 'meta.current_page')) return;

          // We need to filter the activities from api by type and created_at (See helper notes)
          const filteredActivities = filterActivities(activityResponse.data);

          if (activityResponse.meta?.current_page === 1) {
            setActivities(filteredActivities);
          } else {
            setActivities(prevActivities => [...prevActivities, ...filteredActivities]);
          }

          setPagination(activityResponse.meta);
        } else {
          setIsError(true);
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [env, hasInitialized, location, user],
  );

  // Initial client-side loading
  useEffect(() => {
    if (!hasInitialized) loadActivities();
  }, [hasInitialized, loadActivities]);

  // Recheck for new activities every 60 seconds if the browser is open and it's the currently focused tab
  useEffect(() => {
    const interval = setInterval(
      () => {
        if (document.visibilityState === 'visible' && !document.hidden) {
          loadActivities();
        }
      },
      60 * 10 * 1000,
    );

    return () => clearInterval(interval);
  }, [loadActivities]);

  useEffect(() => {
    switch (recorderStatus) {
      case RecorderStatusEnum.Uploading:
        setHideUploadProgress(false);
        break;
      case RecorderStatusEnum.Idle:
        setHideUploadProgress(true);
        break;
      default:
        break;
    }
  }, [recorderStatus]);

  // Load more activities via pagination
  const loadMoreActivities = () => {
    if (pagination) {
      loadActivities(pagination.current_page + 1);
    }
  };

  const isFirstActivityViewed = activities ? activities[0]?.attributes.viewed : null;
  const firstActivityId = activities ? activities[0]?.id : null;
  const isEmpty = activities && activities.length === 0;
  const prevFirstId = usePrevious(firstActivityId);

  // Check if first item is marked as viewed and check id, if not set icon active and announce
  useEffect(() => {
    if (!isFirstActivityViewed && prevFirstId !== firstActivityId) {
      setIconActive(true);
      // On initial load prevFirstId will be undefined, only announce when new items appear in feed
      if (prevFirstId) {
        const count = activities.findIndex(el => el.attributes.viewed === true);
        globalContext.announceLiveMessage(t('activityFeed.youHaveNewActivities', { count }));
      }
    }
  }, [firstActivityId, setIconActive, prevFirstId, isFirstActivityViewed, t, activities, globalContext]);

  // When dropdown opened with new items, mark first item as viewed and set icon to inactive
  const handleClick = () => {
    if (!isFirstActivityViewed && firstActivityId && iconActive) {
      const qs = new URLSearchParams([['activityId', firstActivityId.toString()]]);
      setIconActive(false);
      if (!isDeprecated) markAsViewedFetcher.submit(qs, { method: 'put', action: resourceRoutes.activities });
    }

    // Workaround for announcing no results, the timer should only run once per click and the no result will appear after 300ms.
    const noResultTimer = setTimeout(() => {
      const noResultElement = document.getElementById('no-result-element');
      if (noResultElement) {
        setShowNoResultStatus(false); // This forces the activity feed no results element to hard refresh so the screen reader can detect the DOM changes.
        setShowNoResultStatus(true);
      }
    }, 300);

    return () => clearTimeout(noResultTimer);
  };

  // Group each activity using date filters, and get the title
  // Because of how the time buckets are defined, there should be no overlapping or skipped items
  const activityTimeBuckets = [
    {
      items: filterByDate(activities, isToday),
      title: t('activityFeed.today'),
    },
    {
      items: filterByDate(activities, isYesterday),
      title: t('activityFeed.yesterday'),
    },
    {
      items: filterByDate(activities, isThisWeekNotTodayYesterday),
      title: t('common.recent'),
    },
    {
      items: filterByDate(activities, isAfterThisWeek),
      title: t('activityFeed.earlier'),
    },
  ];

  // Activity Feed
  return (
    <ClientOnly
      fallback={
        <ActivityFeed
          listRef={listRef}
          button={<ActivityFeed.Button aria-label={t('activityFeed.activityFeed')} disabled />}
        >
          <div />
        </ActivityFeed>
      }
    >
      {() => (
        <ActivityFeed
          listRef={listRef}
          button={
            <Tooltip label={t('activityFeed.activityFeed')}>
              {isVideoUploading ? (
                <ActivityFeed.Button
                  aria-controls=""
                  aria-label={t('activityFeed.activityFeed')}
                  data-testid="activityFeed__button__activityFeedUploading"
                  icon={<div className="activityFeed__uploadingBtn" />}
                  onMouseDown={handleClick}
                  size="36"
                  theme="secondary"
                />
              ) : (
                <ActivityFeed.Button
                  aria-controls=""
                  aria-label={t('activityFeed.activityFeed')}
                  data-testid="activityFeed__button__activityFeed"
                  isIconActive={iconActive}
                  onMouseDown={handleClick}
                />
              )}
            </Tooltip>
          }
        >
          {!hideUploadProgress && <ActivityFeedUploadProgress onClose={() => setHideUploadProgress(true)} />}
          {
            // Map time buckets with titles
            activityTimeBuckets.length > 0 && activities.length > 0 ? (
              activityTimeBuckets.map((bucket, i) => {
                const { items, title: bucketTitle } = bucket;
                return (
                  <Fragment key={bucketTitle}>
                    {items.length > 0 && (
                      <ActivityFeed.Heading>
                        <h1
                          className={`activityFeed__heading ${
                            isVideoUploading && i === activityTimeBuckets.length - 1 ? '--uploadActive' : ''
                          }`}
                        >
                          {bucketTitle}
                        </h1>
                      </ActivityFeed.Heading>
                    )}
                    {
                      // Map activity items from each time bucket and destructure
                      items.map((item, index) => {
                        const key = item?.id ?? index;
                        return <ActivityFeedItem key={key} itemData={item} />;
                      })
                    }
                  </Fragment>
                );
              })
            ) : (
              <div id="no-result-element" role="status">
                {showNoResultStatus ? (
                  <ActivityFeed.NoResults>
                    {isError && (
                      <>
                        <img src={externalLinks.SadButRelievedEmoji} height="90" width="90" alt="" />
                        <p>{t('activityFeed.couldNotLoad')}</p>
                      </>
                    )}
                    {isEmpty && !isLoading && !isError && (
                      <>
                        <img src={externalLinks.BellEmoji} height="90" width="90" alt="" />
                        <h1 className="fk-h2 fk-mb0">{t('activityFeed.nothing')}</h1>
                        <p>{t('activityFeed.checkBack')}</p>
                      </>
                    )}
                  </ActivityFeed.NoResults>
                ) : null}
              </div>
            )
          }
          {!isLoading && !isError && pagination && !pagination.last_page && (
            <Observer callbackOnAppearance={loadMoreActivities} root={listRef} rootMargin="0px 0px 10px 0px" />
          )}
        </ActivityFeed>
      )}
    </ClientOnly>
  );
};

export default ActivityFeedDropdown;
