import { Button, ModalConsumer } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import externalLinks from '~/constants/externalLinks';
import Telemetry from '~/constants/telemetry';
import { telemetryAttributes } from '~/helper/helper';
import sanitize from '~/helper/sanitize';
import appTopicCardStyles from '~/styles/components/TopicList/AddTopicCard.css';

import type { ShowModalType } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';
import type { CollectionItem } from 'types';

type Props = {
  collectionItem: CollectionItem;
  draggerDisabled: boolean;
  openModal: (arg0: ShowModalType, arg1: number) => void;
};

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: appTopicCardStyles }];
};

const AddTopicCard = ({ collectionItem, draggerDisabled, openModal }: Props) => {
  const { item } = collectionItem;
  const { t } = useTranslation();
  const imageUrl =
  item && item.focus && item.focus.image_url ? item.focus.image_url : externalLinks.TopicAssetPlaceholder;

  return (
    <ModalConsumer>
      {({ showModal }) =>
      <div className="addTopicCard__cardContainer">
          <Button
          aria-label={item.title}
          className="addTopicCard__cardButton"
          data-testid="addTopicCard__button__topic"
          data-topic={item.id}
          onClick={() => {
            if (draggerDisabled) {
              openModal(showModal, item.id);
            }
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (!draggerDisabled && (e.key === 'Enter' || e.key === ' ')) {
              openModal(showModal, item.id);
            }
          }}
          theme="clear"
          {...telemetryAttributes(Telemetry.TopicEmptyStateAddTopicCard(item.id))}>

            <img src={imageUrl} loading="lazy" alt="" className="addTopicCard__card" />
            <div className="addTopicCard__textContainer">
              <h4>{item.title}</h4>
              <div
              className="fk-editorInner addTopicCard__text"
              dangerouslySetInnerHTML={{ __html: sanitize.strict(item.text) }} />

            </div>
          </Button>
          <Button
          theme="secondary"
          variant="block"
          data-testid="addTopicCard__button__addTopic"
          data-topic={item.id}
          onClick={() => {
            if (draggerDisabled) {
              openModal(showModal, item.id);
            }
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (!draggerDisabled && (e.key === 'Enter' || e.key === ' ')) {
              openModal(showModal, item.id);
            }
          }}
          {...telemetryAttributes(Telemetry.TopicEmptyStateAddTopicButton(item.id))}>

            {t('shared.addTopic')}
          </Button>
        </div>}

    </ModalConsumer>);

};

export default AddTopicCard;