import {
  ActionGroup,
  Button,
  ButtonSubmit,
  Dropdown,
  IcFluentChatHelp24Regular,
  IcFluentDocumentOnePage24Regular,
  IcFluentEmojiSparkle24Regular,
  IcFluentNavigation24Regular,
  IcFluentQuestion24Filled,
  IcFluentWarning24Regular,
  ModalConsumer,
  SmubbleWordmark,
  Tooltip,
} from '@flipgrid/flipkit';
import { useFetcher, useLoaderData, useLocation, useMatches, useParams, useSearchParams } from '@remix-run/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainMenuDropdown from './MainMenuDropdown';
import ActivityFeedDropdown from '../ActivityFeed/ActivityFeed';
import FeatureFlag from '../FkWrappers/FeatureFlag';
import Link from '../FkWrappers/Link';
import { helpCenterUpdateLabels } from '../HelpCenter/HelpCenterControls';
import FeedbackModal from '../Modals/Feedback/FeedbackModal';
import NotificationsModal from '../Modals/Notifications/NotificationsModal';
import HelpCenter from '~/components/HelpCenter/HelpCenter';
import FeedbackAckModal from '~/components/Modals/Feedback/FeedbackAckModal';
import externalLinks from '~/constants/externalLinks';
import handleIds from '~/constants/handleIds';
import { RailState } from '~/constants/railStateEnum';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import globalContext from '~/contexts/globalContext';
import { focusableSelector } from '~/helper/focusable';
import { isFlagPresent } from '~/helper/helper';
import { isMemberGroupLead, isUserEducator } from '~/helper/userRoles';
import useFetcherWatcher from '~/hooks/useFetcherWatcher';
import useIsLtiGroupOrFrame from '~/hooks/useIsLtiGroupOrFrame';

import type { ShowModalType } from '@flipgrid/flipkit';
import type { RegularUser, RouteTyping, StudentViewStates, UserPrefResponse } from 'types';

const Navbar = ({
  collapsedRail,
  setCollapsedRail,
}: {
  collapsedRail: boolean;
  setCollapsedRail: (arg1?: boolean) => void;
}) => {
  const loaderData = useLoaderData() as RouteTyping<'App'>;
  const user = loaderData?.user?.data;
  const { t } = useTranslation();
  const isLead = isUserEducator(user);
  const accountUser = user?.accountType === 'account';
  const fetcher = useFetcher();
  const location = useLocation();
  const [helpCenterLoaded, setHelpCenterLoaded] = useState(false);
  const feedbackFetcher = useFetcher();
  const params = useParams();

  const { featureFlags } = useContext(globalContext);
  const isFlagged = isFlagPresent(featureFlags, 'web-ISTE');

  const matches = useMatches();
  const groupData = (matches.find(m => m.handle?.id === handleIds.Group)?.data as RouteTyping<'Group'>)?.group;
  const group = groupData?.data;
  const isLtiGroupOrFrame = useIsLtiGroupOrFrame(group);
  const [studentView, setStudentView] = useState(groupData?.studentView);
  const isGroupLead = isMemberGroupLead(group, studentView);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  useFetcherWatcher({
    action: resourceRoutes.userPreferences,
    formDataAction: 'UpdateStudentViewUserPreferences',
    update: (data?: UserPrefResponse<StudentViewStates>) => {
      if (data?.value) {
        setStudentView(data.value === 'false' || data.value === 'true' ? data.value : 'false');
      }
    },
  });

  useEffect(() => {
    if (helpCenterLoaded && location) {
      const getString = (item: string) => {
        return item
          .split(/[._?&|#/|\d+]/)
          .filter(label => label)
          .pop();
      };
      setTimeout(() => {
        const contextualLabel = getString(location.pathname);
        const allLabels = ['top1', contextualLabel];

        helpCenterUpdateLabels(allLabels);
      }, 400);
    }
  }, [location, helpCenterLoaded]);

  const toggleCollapse = () => {
    setCollapsedRail();

    fetcher.submit(
      { rail: !collapsedRail ? RailState.collapsed : RailState.expanded, _action: 'ToggleRail' },
      { action: resourceRoutes.userPreferences, method: 'post' },
    );
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const shouldDisplayFeedbackModal = searchParams.get('provideFeedback') === 'true';

  const provideFeedback = (form: FormData, showModal: ShowModalType) => {
    feedbackFetcher.submit(form, { method: 'post', action: resourceRoutes.feedbacks });
    showModal(FeedbackAckModal);
  };

  const onCloseLoadOnInitFeedbackModal = () => {
    searchParams.delete('provideFeedback');
    setSearchParams(searchParams);
  };

  const userPrefFetcher = useFetcher();

  const setToStudentView = () => {
    userPrefFetcher.submit(
      { studentView: 'true', _action: 'UpdateStudentViewUserPreferences' },
      { action: resourceRoutes.userPreferences, method: 'post' },
    );
  };

  return user?.canvasStudentUser ? null : (
    <nav className="navbar" aria-label={t('common.primary')}>
      <ActionGroup stack="auto" justify="right">
        <Button
          variant="text"
          onClick={() => {
            // Find first focusable element within the canvas and focus
            const contentArea = document.querySelector('.app__canvas');
            const firstFocusable = contentArea?.querySelector(focusableSelector) as HTMLElement;
            if (firstFocusable) firstFocusable.focus();
          }}
          className="navbar__skipLink"
          data-testid="navbar__button__skipLink"
          role="link"
        >
          {t('shared.skipToMainContent')}
        </Button>

        {!isLtiGroupOrFrame && (
          <Button
            aria-label={t('shared.toggleRail')}
            theme="tertiary"
            icon={<IcFluentNavigation24Regular />}
            className="navbar__railToggle"
            data-testid="navbar__button__railToggle"
            onMouseDown={(e: React.MouseEvent) => {
              // We use mousedown and stopPropagation because we are using the useClickOutside hook
              e.stopPropagation();
              toggleCollapse();
            }}
            onKeyDown={(e: KeyboardEvent) => {
              if (e.key === 'Enter') {
                toggleCollapse();
              }
            }}
            size="36"
          />
        )}

        {!params.groupid && user.accountType === 'ephemeral' ? (
          <div className="navbar__linkHome">
            <SmubbleWordmark className="navbar__logo" height={24} />
          </div>
        ) : (
          <Link
            aria-label={t('shared.backToHome')}
            data-testid="navbar__link__home"
            className="navbar__linkHome"
            to={
              params.groupid && user.accountType === 'ephemeral'
                ? routes.GROUPS_ID_TOPICS_FUNC(params.groupid)
                : routes.HOME
            }
            theme="clear"
          >
            <SmubbleWordmark className="navbar__logo" height={24} />
          </Link>
        )}
      </ActionGroup>
      {isLead ? <NotificationsModal /> : null}

      <ModalConsumer>
        {({ showModal }) => (
          <>
            <ActionGroup>
              {isGroupLead && isFlagged && (
                <ButtonSubmit
                  data-testid="navbar__button__viewAsStudent"
                  onClick={setToStudentView}
                  size="36"
                  loading={userPrefFetcher.state !== 'idle'}
                >
                  {t('shared.viewAsStudent')}
                </ButtonSubmit>
              )}
              <Dropdown
                fullWidthMobile
                right
                button={
                  <Tooltip label={t('navbar.help')}>
                    <Dropdown.Button
                      theme="nav"
                      data-testid="navbar__dropdownButton__help"
                      aria-controls=""
                      aria-label={t('navbar.help')}
                      icon={<IcFluentQuestion24Filled className="iconFill__fg-3" />}
                      size="36"
                    />
                  </Tooltip>
                }
              >
                <FeatureFlag match="web-deprecation" showWhenOff>
                  <HelpCenter onHelpCenterLoaded={isLoaded => setHelpCenterLoaded(isLoaded)} />
                </FeatureFlag>

                <FeatureFlag match="web-deprecation">
                  <Dropdown.Item
                    href={externalLinks.Help}
                    icon={<IcFluentChatHelp24Regular />}
                    data-testid="navbar__dropdownItem__helpCenter"
                    newTab
                  >
                    {t('common.helpCenter')}
                  </Dropdown.Item>
                </FeatureFlag>

                <Dropdown.Item
                    href={externalLinks.DeleteEmail}
                    icon={<IcFluentWarning24Regular />}
                    data-testid="navbar__dropdownItem__delete"
                    newTab
                  >
                    {t('common.deleteAccount')}
                  </Dropdown.Item>

                {!isDeprecated && isLead && (
                  <Dropdown.Item
                    onSelect={() =>
                      showModal(FeedbackModal, {
                        onSubmit: (form: FormData) => {
                          provideFeedback(form, showModal);
                        },
                        user: user as RegularUser, // Guest users can't be leads and canvas users don't have a navbar
                        feedbackFetcher,
                      })
                    }
                    icon={<IcFluentEmojiSparkle24Regular />}
                    data-testid="navbar__dropdownItem__giveFeedback"
                  >
                    {t('navbar.improveFlip')}
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  href={externalLinks.TrustAndSafety}
                  icon={<IcFluentDocumentOnePage24Regular />}
                  data-testid="navbar__dropdownItem__legal"
                  newTab
                >
                  {t('navbar.legal')}
                </Dropdown.Item>
              </Dropdown>

              {accountUser ? <ActivityFeedDropdown /> : null}

              <MainMenuDropdown />
            </ActionGroup>
            {!isDeprecated && isLead && shouldDisplayFeedbackModal && (
              <FeedbackModal
                onSubmit={(form: FormData) => {
                  provideFeedback(form, showModal);
                }}
                user={user as RegularUser} // Guest users can't be leads and canvas users don't have a navbar
                feedbackFetcher={feedbackFetcher}
                onRequestClose={onCloseLoadOnInitFeedbackModal}
              />
            )}
          </>
        )}
      </ModalConsumer>
    </nav>
  );
};

export default Navbar;
