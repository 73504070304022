import {
  Button,
  CameraAdd24Solid,
  IcFluentBlock24Regular,
  IcFluentEyeHide24Filled,
  IcFluentPin24Filled,
  IcFluentPin24Regular,
  IcFluentShiftsActivity24Filled,
  ModalConsumer } from
'@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import TopicActionsDropdown, { links as topicActionsDropdownStyles } from './TopicActionsDropdown';
import RecordResponseBtn from '../Share/RecordResponseBtn';
import CardLink from '../Utility/Buttons/CardLink';
import ShareButton, { links as shareButtonStyles } from '../Utility/Buttons/ShareButton';
import Tile, { links as tileStyles } from '../Utility/Layout/Tile';
import CustomTag, { links as customTagStyles } from '../Utility/components/CustomTag';
import HydratedTimezone from '../Utility/components/HydratedTimezone';
import HandleIds from '~/constants/handleIds';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import GlobalContext from '~/contexts/globalContext';
import { delayVariableStyle } from '~/helper/animationHelper';
import checkIsLtiGroup from '~/helper/checkIsLtiGroup';
import { getShortDateWithYear, getReadableTimeSinceCurrentDate } from '~/helper/dates';
import { isFlagPresent } from '~/helper/helper';
import { adjustDateByTimezone, hasTopicLaunched } from '~/helper/topicDatesHelpers';
import useGetUser from '~/hooks/useGetUser';
import topicListCardStyles from '~/styles/components/TopicList/TopicListCard.css';

import type { Group, RouteTyping, Topic } from 'types';

export const links = () => [
...topicActionsDropdownStyles(),
...tileStyles(),
...customTagStyles(),
...shareButtonStyles(),
{ rel: 'stylesheet', href: topicListCardStyles }];


type TopicListCardProps = {
  cardIndex: number;
  group: Group;
  isGroupLead: boolean;
  topic: Topic;
  variant: 'search' | 'groupDetails';
  className?: string;
  [key: string]: unknown;
};

const TopicListCard = ({ cardIndex, group, isGroupLead, topic, variant, className, ...rest }: TopicListCardProps) => {
  const { t } = useTranslation();
  const user = useGetUser();
  const matches = useMatches();
  const ua = (matches.find((m) => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const isMobile = ua?.mobile;
  const { breakPoints } = useContext(GlobalContext);
  const isLtiGroup = checkIsLtiGroup(group);
  const { name, image_url } = group;
  const fetcher = useFetcher();
  const landedFromSearch = variant === 'search';
  const responseCount = landedFromSearch ? topic.response_count : topic.all_response_count;
  const isRailCollapsed =
  typeof window !== 'undefined' && document.querySelector('main')?.classList.contains('app--rail__collapsed');
  const isTagVisuallyOverflowing =
  isRailCollapsed && !breakPoints.isScreenMedium || !isRailCollapsed && breakPoints.screenWidth <= 905;

  const viewOnlyTopic = topic.locked && topic.active;
  const hiddenTopic = !topic.locked && !topic.active;
  const scheduledTopic = topic.start_at ? !hasTopicLaunched(topic.start_at, user?.timezone) : false;

  const shouldShowViewOnlyTag = viewOnlyTopic && !landedFromSearch && !scheduledTopic;
  const shouldShowHiddenTag = hiddenTopic && !landedFromSearch;
  const shouldShowScheduledTag = scheduledTopic && !landedFromSearch;

  const { featureFlags } = useContext(GlobalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  const getRelativeDateString = () => {
    const nowDate = new Date();
    const tzAdjustedNowDate = adjustDateByTimezone(nowDate, user?.timezone);

    const creationDate = new Date(topic.created_at);
    const tzAdjustedCreationDate = adjustDateByTimezone(creationDate, user?.timezone);

    const isOlderThanYear =
    (tzAdjustedNowDate.valueOf() - tzAdjustedCreationDate.valueOf()) / (1000 * 3600 * 24 * 365) > 1;
    if (isOlderThanYear) {
      return getShortDateWithYear(topic.created_at, user?.timezone);
    }
    return getReadableTimeSinceCurrentDate(topic.created_at, user?.timezone);
  };

  const pinTopic = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('featured', (!topic.featured).toString());
    formData.append('_action', 'updateTopic');
    formData.append('grid_id', topic.grid_id?.toString());
    formData.append('id', topic.id?.toString());
    fetcher.submit(formData, { method: 'post', action: `${resourceRoutes.updateState}?manage=true` });
  };

  const formattedVideoCountTotal = () => {
    try {
      const responseFormatter = Intl.NumberFormat('en', { notation: 'compact' }); // TODO: Localize
      return responseFormatter.format(responseCount - 5).toLowerCase();
    } catch {
      console.warn('Intl.NumberFormat is not supported, falling back to basic formatting');
      return (responseCount - 5).toString();
    }
  };

  return (
    <ModalConsumer>
      {({ showModal }) =>
      <CardLink
        as="article"
        className={classNames('topicListCard', className)}
        data-testid="topicListCard__link__toTopic"
        aria-posinset={cardIndex + 1}
        tabIndex={0}
        to={routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(topic.grid_id, topic.id)}
        role="article"
        key={topic.id}
        {...rest}>

          <div className="topicListCard__contentWrapper">
            {isGroupLead &&
          <Button
            aria-label={topic.featured ? t('topicListCard.unpin') : t('topicListCard.pin')}
            className={`topicListCard__pin ${
            topic.featured || fetcher.state !== 'idle' || isMobile ? '-stationary' : ''
            }`}
            data-testid="topicListCard__button__pin"
            icon={
            topic.featured ?
            <IcFluentPin24Filled width="18px" height="18px" /> :

            <IcFluentPin24Regular width="18px" height="18px" />}


            disabled={fetcher.state !== 'idle'}
            onClick={pinTopic}
            size="36"
            theme="tertiary" />}


            {!isGroupLead && topic.featured &&
          <Button
            aria-label={t('topicListCard.topicPinned')}
            className="topicListCard__pin -stationary -disabledMemberButton"
            data-testid="topicListCard__button__pinFake"
            icon={<IcFluentPin24Filled width="18px" height="18px" />}
            disabled
            size="36"
            theme="transparent" />}


            <div className="topicListCard__text">
              <span className="topicListCard__topicTileAdditionalDetails">
                <time className="topicListCard__date">
                  <HydratedTimezone time={getRelativeDateString()} />
                </time>
                {landedFromSearch && <span className="topicListCard__topicTileContent">{topic.grid_name}</span>}
              </span>
              <h2 className="topicListCard__topicTitle m0">{topic.title}</h2>
            </div>
          </div>
          <div className="topicListCard__details">
            {shouldShowViewOnlyTag &&
          <CustomTag
            iconOnly={isTagVisuallyOverflowing}
            icon={<IcFluentBlock24Regular width={20} height={20} />}
            label={t('topicListCard.topicViewOnlyNoNewVideos')}>

                {t('shared.viewOnly')}
              </CustomTag>}

            {shouldShowHiddenTag &&
          <CustomTag
            iconOnly={isTagVisuallyOverflowing}
            icon={<IcFluentEyeHide24Filled width={20} height={20} />}
            label={t('topicListCard.topicHidden')}>

                {t('common.hidden')}
              </CustomTag>}


            {shouldShowScheduledTag &&
          <CustomTag
            iconOnly={isTagVisuallyOverflowing}
            icon={<IcFluentShiftsActivity24Filled width={20} height={20} />}
            label={t('topicListCard.topicHasLaunchDate')}>

                {t('common.scheduled')}
              </CustomTag>}


            {topic.recent_responses?.length > 0 &&
          topic.recent_responses.slice(0, 5).map((response, index) => {
            const style = delayVariableStyle('shimmer-animation-delay', 0.05, index);
            return <Tile className="tile__small" src={response.image_url} key={response.id} style={style} />;
          })}
            {responseCount > 5 &&
          <span className="topicListCard__additionalResponseCount" aria-hidden>
                {t('topicListCard.plusVideoCount', { numVideos: formattedVideoCountTotal() })}
              </span>}

            {!landedFromSearch &&
          <div className="topicListCard__actionsWrapper">
                <div className="vertical-spacer" />
                <div className="topicListCard__actions">
                  <ShareButton
                accessControl={group.access_control}
                disabled={isLtiGroup}
                data-testid="topicListCard__button__share"
                showModal={showModal}
                aria-label={t('shared.shareTopicTitle', { title: topic.title })}
                entity={{
                  item: topic,
                  type: 'Topic'
                }}
                displayType="icon"
                name={name}
                image_url={image_url} />

                  {isDeprecated ? null : topic && topic.locked && !isGroupLead ?
              <Button
                icon={<CameraAdd24Solid />}
                size="36"
                theme="secondary"
                data-testid="topicListCard__button__disabledCamera"
                aria-label={t('common.camera')}
                disabled={topic && topic.locked && !isGroupLead} /> :


              <RecordResponseBtn topic={topic} isGroupLead={isGroupLead} />}

                  <TopicActionsDropdown isGroupLead={isGroupLead} topic={topic} variant="table" />
                </div>
              </div>}

          </div>
        </CardLink>}

    </ModalConsumer>);

};

export default TopicListCard;