import { ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { has } from 'lodash-es';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import externalLinks from '~/constants/externalLinks';
import promoteToCoLeadStyles from '~/styles/components/Modals/MemberActions/PromoteToCoLeadModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Member } from 'types';

export const links = () => [{ rel: 'stylesheet', href: promoteToCoLeadStyles }];

type Props = {
  groupID: number;
  member: Member;
  onRequestClose: OnRequestCloseType;
};

const PromoteToCoLeadModal = ({ groupID, member, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();

  useEffect(() => {
    if (fetcher.type === 'done') {
      const isError = has(fetcher.data, 'data.error');
      let closeAnnouncement = t('shared.errorOccurred');

      if (!isError) {
        closeAnnouncement = t('promoteToCoLeadModal.successfullyPromotedMember');
      }

      onRequestClose(closeAnnouncement);
    }
  }, [fetcher.data, fetcher.state, fetcher.type, onRequestClose, t]);

  return (
    <Modal show onClose={onRequestClose}>
      <fetcher.Form method="post" action={`groups/${groupID}/members`}>
        <img className="promoteToCoLeadModal__crown" src={externalLinks.CrownEmoji} alt="" />
        <h1 className="fk-modalHeader">{t('promoteToCoLeadModal.makeCoLead', { name: member.display_name })}</h1>
        <p className="fk-modalBody">{t('promoteToCoLeadModal.leadPermissionsExplanation')}</p>
        <Modal.Actions className="mt2">
          <ButtonSubmit
            name="_action"
            value="manageCoLead"
            loading={fetcher.state === 'submitting' || fetcher.state === 'loading'}
            data-testid="promoteToCoLeadModal__button__promote">

            {t('promoteToCoLeadModal.promote')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="grid_id" value={groupID} />
        <input type="hidden" name="email" value={member.email} />
        <input type="hidden" name="role" value="pending" />
      </fetcher.Form>
    </Modal>);

};
export default PromoteToCoLeadModal;