import { Button, EmptyState, Scrollable } from '@flipgrid/flipkit';
import { useParams } from '@remix-run/react';
import { useActor } from '@xstate/react';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MyGroupsLink, { links as myGroupsLinkStyles } from './MyGroupsLink';
import { MyGroupsList as MyGroupsListSkeleton, links as skeletonStyles } from '~/components/Skeleton/Skeleton';
import InfiniteScroll from '~/components/Utility/InfiniteScroll';
import externalLinks from '~/constants/externalLinks';
import { delayVariableStyle } from '~/helper/animationHelper';
import { useGroupsStateMachine } from '~/statemachine/GroupsStateMachineContext';
import myGroupsStyles from '~/styles/components/Rail/MyGroups.css';

import type { SlimGroup } from 'types';

export const links = () => [...skeletonStyles(), ...myGroupsLinkStyles(), { rel: 'stylesheet', href: myGroupsStyles }];

type Props = {
  collapsedRail: boolean;
  shouldScroll: boolean;
};

const MyGroups = ({ collapsedRail, shouldScroll }: Props) => {
  const { t } = useTranslation();
  const { groupid } = useParams();

  const groupStateMachine = useGroupsStateMachine();
  const { send } = groupStateMachine.groupsService;
  const [state] = useActor(groupStateMachine.groupsService);

  const { groups, pagination, loading, error } = state.context;

  useEffect(() => {
    if (state.value === 'removeGroupOnNextNavigation') send('REMOVE');
    // only want this to fire when groupid changes
    // eslint-disable-next-line
  }, [groupid]);

  const currentIndex = groups.findIndex((g) => g.id === Number(groupid));

  const MyGroupsNav =
  <nav className={classNames('myGroups', { 'is-collapsed': collapsedRail })} aria-live="polite" aria-busy={loading}>
      {groups && groups.length > 0 ?
    <ul className="myGroups__groups list-unstyled">
          {groups.map((group: SlimGroup, index) => {
        const isCurrent = Boolean(groupid && Number(groupid) === group.id);
        const style = delayVariableStyle('shimmer-animation-delay', 0.05, index);

        return (
          <li key={group.id} style={style}>
                <MyGroupsLink
              collapsedRail={collapsedRail}
              group={group}
              currentIndex={currentIndex}
              index={index}
              isCurrent={isCurrent} />

              </li>);

      })}
          <li style={{ height: '2px' }}>
            <InfiniteScroll
          pagination={pagination}
          fetcherState={loading ? 'loading' : 'idle'}
          load={() => send('LOAD_MORE')} />

          </li>
        </ul> :

    <EmptyState hide />}

      {loading && <MyGroupsListSkeleton />}
    </nav>;


  if (error)
  return (
    <div className={classNames('myGroups__error', { 'is-collapsed': collapsedRail })}>
        <img className="myGroups__error__image" src={externalLinks.SadButRelievedEmoji} alt="" width={60} />
        <div className={classNames({ 'fk-visuallyHidden': collapsedRail })}>
          <p>{t('myGroups.errorMessage')}</p>
        </div>
        <div className={classNames({ 'fk-visuallyHidden': collapsedRail })}>
          <Button data-testid="myGroups__error__button" size="36" type="button" onClick={() => send('LOAD_MORE')}>
            {t('common.retry')}
          </Button>
        </div>
      </div>);


  if (shouldScroll) return <Scrollable>{MyGroupsNav}</Scrollable>;
  return <>{MyGroupsNav}</>;
};

export default MyGroups;