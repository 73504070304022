import { ActivityType } from '../../../types/ActivityFeedTypes';

import type { ActivityFeedItemType } from '../../../types/ActivityFeedTypes';

// Since activities are not deleted, if user loses access to a group or topic after the activity has been created
// the target, object, or actor is updated with a "created_at: null", we can use that to skip showing the activity
// Also filter by supported activities (helps where api has activities in prod, but front end is not ready to launch)
export const filterActivities = (arr: ActivityFeedItemType[]) => {
  const activities = arr.filter(el => {
    const {
      attributes: { type, actor, object, target },
    } = el;

    const skipActivity = !actor?.created_at || !object?.created_at || !target?.created_at;

    const supportedActivities = [
      ActivityType.videoResponse,
      ActivityType.videoComment,
      ActivityType.groupTopicAdded,
      ActivityType.textComment,
      ActivityType.invitationAccepted,
      ActivityType.invitationCreated,
      ActivityType.responseLike,
      ActivityType.requestToJoinApproved,
      ActivityType.requestToJoinCreated,
      ActivityType.textReply,
      ActivityType.videoReply,
    ];

    return supportedActivities.includes(type) && !skipActivity;
  });

  return activities;
};
