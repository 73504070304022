export type Banner = {
  asset_id: string;
  category?: BannerEnum;
  descriptor?: string;
  position?: number;
  url: string;
};

export enum BannerEnum {
  Featured = 'featured',
  Art = 'art',
  Nature = 'nature',
  People = 'people',
  Textures = 'textures',
}
