import { EmptyState } from '@flipgrid/flipkit';
import { useSearchParams, useNavigation } from '@remix-run/react';
import React, { useEffect, useMemo, useState } from 'react';

import MemberListPanel, { links as memberListPanelStyles } from './MemberListPanel';

import type { LinksFunction } from '@remix-run/node';
import type { Group, RouteTyping } from 'types';

export const links: LinksFunction = () => [...memberListPanelStyles()];

type Props = {
  group: Group;
  memberResponse: RouteTyping<'GroupsMembersTab'>;
};

const MemberList = ({ group, memberResponse }: Props) => {
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q') ?? '';
  const { membership, access_control } = group;
  const [members, setMembers] = useState(memberResponse?.members?.data);
  const [blockedMembers, setBlockedMembers] = useState(memberResponse?.blocked?.data);
  const [requestedMembers, setRequestedMembers] = useState(memberResponse?.requested?.data);
  const fullMemberList = useMemo(
    () => [...(members ?? []), ...(blockedMembers ?? []), ...(requestedMembers ?? [])],
    [members, blockedMembers, requestedMembers],
  );

  const [memberPagination, setMemberPagination] = useState(memberResponse?.members?.metadata?.pagination);
  const [blockedPagination, setBlockedPagination] = useState(memberResponse.blocked?.metadata.pagination);
  const [requestedPagination, setRequestedPagination] = useState(memberResponse.requested?.metadata.pagination);

  const isCurrentUserGroupOwner = membership?.role === 'owner' || membership?.role === 'co_owner';

  // When shouldReload reloads route, apply changes to respective lists
  // Set pagination to loader value (which is always 1) so that we can load page and paginate to end if need be
  useEffect(() => {
    setMembers(memberResponse?.members?.data);
    setMemberPagination(memberResponse?.members?.metadata?.pagination);
  }, [memberResponse?.members?.data, memberResponse?.members?.metadata?.pagination]);

  useEffect(() => {
    if (memberResponse.blocked?.data) {
      setBlockedMembers(memberResponse.blocked.data);
      setBlockedPagination(memberResponse.blocked.metadata?.pagination);
    }
  }, [memberResponse.blocked?.data, memberResponse.blocked?.metadata?.pagination]);

  useEffect(() => {
    if (memberResponse.requested?.data) {
      setRequestedMembers(memberResponse.requested.data);
      setRequestedPagination(memberResponse.requested.metadata?.pagination);
    }
  }, [memberResponse.requested?.data, memberResponse.requested?.metadata?.pagination]);

  // End shouldReload functionality

  return (
    <>
      {fullMemberList.length > 0 ? (
        <>
          {isCurrentUserGroupOwner &&
            access_control !== 'student' &&
            memberResponse.requested &&
            requestedMembers &&
            requestedMembers.length > 0 && (
              <MemberListPanel
                setMember={setRequestedMembers}
                members={requestedMembers}
                pagination={requestedPagination}
                setPagination={setRequestedPagination}
                memberList={memberResponse.requested}
                memberStatus="requested"
                group={group}
                isCurrentUserGroupOwner={isCurrentUserGroupOwner}
              />
            )}
          <MemberListPanel
            setMember={setMembers}
            members={members}
            pagination={memberPagination}
            setPagination={setMemberPagination}
            memberList={memberResponse?.members}
            memberStatus="members"
            group={group}
            isCurrentUserGroupOwner={isCurrentUserGroupOwner}
          />
          {isCurrentUserGroupOwner && memberResponse.blocked && blockedMembers && blockedMembers.length > 0 && (
            <MemberListPanel
              setMember={setBlockedMembers}
              members={blockedMembers}
              pagination={blockedPagination}
              setPagination={setBlockedPagination}
              memberList={memberResponse.blocked}
              memberStatus="blocked"
              group={group}
              isCurrentUserGroupOwner={isCurrentUserGroupOwner}
            />
          )}
        </>
      ) : (
        <EmptyState noResults={searchQuery.length > 0} loading={navigation.state === 'loading'} />
      )}
    </>
  );
};

export default React.memo(MemberList);
