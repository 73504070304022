import { ButtonBase, IcFluentCheckmark24Regular, IcFluentImageAdd24Regular, ModalConsumer } from '@flipgrid/flipkit';
import { useContext, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import UploadCustomBannerModal, { links as uploadCustomBannerModalStyles } from './UploadCustomBannerModal';
import GlobalContext from '~/contexts/globalContext';
import { handleError } from '~/helper/imgOnError';
import bannerPanelStyles from '~/styles/components/Groups/GroupForm/BannerPanel.css';

import type { LinksFunction } from '@remix-run/node';
import type { Banner, ImageUpload } from 'types';

export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: bannerPanelStyles },
...uploadCustomBannerModalStyles()];


type Props = {
  onImageUploaded: (imageData: ImageUpload) => void;
  panelAssets: Banner[];
  selectedBanner: Banner;
  setSelectedBanner: (banner: Banner) => void;
};

const BannerPanel = ({ onImageUploaded, panelAssets, selectedBanner, setSelectedBanner }: Props) => {
  const { t } = useTranslation();
  const { announceLiveMessage } = useContext(GlobalContext);

  const isSelected = (asset: Banner) => {
    return selectedBanner.asset_id === asset.asset_id;
  };

  return (
    <ModalConsumer>
      {({ showModal }) =>
      <div className="bannerPanel">
          <ButtonBase
          className="bannerPanel__uploadCustom bannerPanel__selection"
          type="button"
          data-testid="bannerPanel__button__upload"
          aria-label={t('groupForm.imageUpload')}
          onClick={() => showModal(UploadCustomBannerModal, { onImageUploaded })}>

            <IcFluentImageAdd24Regular width={40} height={40} className="iconFill__disabled" />
          </ButtonBase>
          {panelAssets.map((asset) =>
        <ButtonBase
          className={`bannerPanel__selection${isSelected(asset) ? ' --active' : ''}`}
          type="button"
          data-testid="bannerPanel__button__asset"
          aria-label={asset.descriptor}
          onClick={() => {
            setSelectedBanner(asset);
            announceLiveMessage(t('groupForm.imageSelected', { descriptor: asset.descriptor }));
          }}
          key={asset.asset_id}>

              <img
            className="bannerPanel__background"
            loading="lazy"
            src={`${asset.url}?size=xsmall`}
            alt=""
            onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} />

              {isSelected(asset) &&
          <IcFluentCheckmark24Regular
            className="bannerPanel__selectedIndicator"
            width={34}
            height={34}
            fill="var(--fk-color__white)" />}


            </ButtonBase>
        )}
        </div>}

    </ModalConsumer>);

};

export default BannerPanel;