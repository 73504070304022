import { Button, IcFluentChevronLeft24Regular } from '@flipgrid/flipkit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import GlobalContext from '~/contexts/globalContext';
import draggerPrevStyles from '~/styles/components/Dragger/DraggerPrev.css';

import type { LinksFunction } from '@remix-run/node';

type Props = {
  ariaLabel: string;
  className: string;
  formState: TSFix;
  offset: number;
};

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: draggerPrevStyles }];

const DraggerPrev = ({ ariaLabel, className, formState, offset }: Props) => {
  const { t } = useTranslation();
  const { announceLiveMessage } = useContext(GlobalContext);

  const updateDraggerPosition = () => {
    if (formState && formState.draggerRef && formState.frame) {
      // Using a large negative number to fake end of list
      if (formState.frame.progress <= 0) {
        formState.draggerRef.setPosition(-10000000000);
        if (announceLiveMessage) announceLiveMessage(t('draggerPrev.beginningHit'));
      } else {
        formState.draggerRef.setPosition(formState.frame.x + offset);
        if (announceLiveMessage) announceLiveMessage(t('draggerPrev.scrolledBackward'));
      }
    }
  };

  return (
    <Button
      className={className + (formState.disablePrev ? ' draggerPrev__disabled' : '')}
      disabled={formState.disablePrev}
      onClick={updateDraggerPosition}
      data-testid="draggerPrev__button__previous"
      aria-label={ariaLabel}
      icon={<IcFluentChevronLeft24Regular className="draggerPrev__icon" />}
      variant="circle"
      size="26" />);


};

export default DraggerPrev;