import { Button, Camera24Filled, IcFluentMixtape24Filled } from '@flipgrid/flipkit';
import { useLocation } from '@remix-run/react';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import MyVideosIcon from './MyVideosIcon';
import Link from '../FkWrappers/Link';
import routes from '~/constants/routes';
import globalContext from '~/contexts/globalContext';
import recorderContext from '~/contexts/recorderContext';
import { isFlagPresent } from '~/helper/helper';
import { isUserEducator } from '~/helper/userRoles';
import useGetUser from '~/hooks/useGetUser';
import railNavIndexStyles from '~/styles/routes/index/railNavIndex.css';

export const links = () => [{ rel: 'stylesheet', href: railNavIndexStyles }];

const RailNav = ({ collapsedRail }: {collapsedRail: boolean;}) => {
  const { loadRecorder } = useContext(recorderContext);
  const { t } = useTranslation();
  const location = useLocation();
  const user = useGetUser();
  const isLead = isUserEducator(user);

  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  return (
    <ul className={classNames('list-unstyled railNav', { 'is-collapsed': collapsedRail })}>
      <li>
        <Button
          className="railNav__button"
          actionInnerClassName="railNav__iconWrapper"
          as="nav"
          theme="nav"
          data-testid="railNav__button__camera"
          disabled={isDeprecated}
          onClick={() =>
          loadRecorder({
            form: {
              allowDisplayNameEdits: false,
              redirectOnRequestAsync: false,
              route: location.pathname,
              showEmailField: false,
              showTitleField: true,
              type: 'topicResponse'
            },
            user,
            entity: { type: 'Profile' }
          })}

          icon={
          <span className="railNav__icon">
              <Camera24Filled width="20" height="20" />
            </span>}

          variant="text">

          <span className={collapsedRail ? 'fk-visuallyHidden' : ''}>{t('common.camera')}</span>
        </Button>
      </li>
      {isLead &&
      <li>
          <Link
          actionInnerClassName="railNav__iconWrapper"
          as="nav"
          className="railNav__link"
          data-testid="railNav__link__mixtapes"
          icon={
          <span className="railNav__icon">
                <IcFluentMixtape24Filled />
              </span>}

          theme="nav"
          to={routes.MIXTAPES}>

            <span className={collapsedRail ? 'fk-visuallyHidden' : ''}>{t('shared.mixtapes')}</span>
          </Link>
        </li>}

      <li>
        <Link
          actionInnerClassName="railNav__iconWrapper"
          as="nav"
          className="railNav__link"
          data-testid="railNav__link__myVideos"
          icon={
          <span className="railNav__icon">
              <MyVideosIcon />
            </span>}

          theme="nav"
          to={routes.MY_VIDEOS}>

          <span className={collapsedRail ? 'fk-visuallyHidden' : ''}>{t('shared.myVideos')}</span>
        </Link>
      </li>
    </ul>);

};

export default RailNav;