import { useTranslation } from 'react-i18next';

export const useGetFeedbackTypes = () => {
  const { t } = useTranslation();

  return {
    Camera: {
      value: 'Camera',
      type: t('feedbackTypes.camera'),
    },
    SignIn: {
      value: 'Sign up and sign in',
      type: t('feedbackTypes.signUpAndSignIn'),
    },
    Groups: {
      value: 'Groups',
      type: t('feedbackTypes.groups'),
    },
    Topics: {
      value: 'Topics',
      type: t('common.topics'),
    },
    MyVideos: {
      value: 'My videos',
      type: t('shared.myVideos'),
    },
    Educators: {
      value: 'Educators',
      type: t('common.educators'),
    },
    Account: {
      value: 'Account settings',
      type: t('feedbackTypes.accountAndSettings'),
    },
    Other: {
      value: 'Other',
      type: t('feedbackTypes.other'),
    },
  };
};
