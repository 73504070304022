import { Dropdown, IcFluentEdit24Regular, IcFluentQrCode24Regular } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import DropdownLink from '../FkWrappers/DropdownLink';
import EditUsernameModal from '../Modals/MemberActions/EditUsernameModal';
import routes from '~/constants/routes';

import type { ShowModalType } from '@flipgrid/flipkit';
import type { Group, Member } from 'types';

type Props = {
  group: Group;
  member: Member;
  showModal: ShowModalType;
};

const MemberUsernameActions = ({ group, member, showModal }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Dropdown.Item
        data-testid="memberUsernameActions__dropdownItem__editUsernameDetails"
        icon={<IcFluentEdit24Regular />}
        onSelect={() => showModal(EditUsernameModal, { member })}
      >
        {t('shared.editDetails')}
      </Dropdown.Item>
      <DropdownLink
        icon={<IcFluentQrCode24Regular />}
        data-testid="memberUsernameActions__dropdownLink__printQRCodes"
        to={routes.GROUPS_ID_GROUPS_NAME_VANITY_TOKEN_SINGLE_BADGE_FUNC(
          group.id,
          group.name,
          group.vanity_token,
          member.uid,
        )}
      >
        {t('memberUsernameActions.printQRCode')}
      </DropdownLink>
    </>
  );
};

export default MemberUsernameActions;
