import { Button, ButtonSubmit, Tabs } from '@flipgrid/flipkit';
import { useFetcher, useNavigate, useParams, useSearchParams } from '@remix-run/react';
import { useActor } from '@xstate/react';
import { has } from 'lodash-es';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BannerPanel, { links as bannerPanelStyles } from './BannerPanel';
import GroupFormHeader, { links as groupFormHeaderStyles } from './GroupFormHeader';
import { BannerEnum } from 'types/Banner';
import StickyBar, { links as stickyBarStyles } from '~/components/FkWrappers/StickyBar';
import ClassroomTypeModal, {
  links as classroomTypeModalStyles,
} from '~/components/Modals/GroupCreation/ClassroomTypeModal';
import routes from '~/constants/routes';
import globalContext from '~/contexts/globalContext';
import { useGroupsStateMachine } from '~/statemachine/GroupsStateMachineContext';

import type { LinksFunction } from '@remix-run/node';
import type { Banner, GridDefaults, GridType, ImageUpload } from 'types';

export const links: LinksFunction = () => [
  ...bannerPanelStyles(),
  ...groupFormHeaderStyles(),
  ...classroomTypeModalStyles(),
  ...stickyBarStyles(),
];

type Props = {
  bannerOptions: Banner[];
  defaultBanner: Banner;
  fromIntentModal?: boolean;
  gridCategories: GridType[];
  gridDefaults: GridDefaults;
  isEditing?: boolean;
};

const GroupForm = ({
  bannerOptions,
  defaultBanner,
  fromIntentModal,
  gridCategories,
  gridDefaults,
  isEditing,
}: Props) => {
  const { t } = useTranslation();
  const { setClosedCreate, setClosedIntentModal } = useContext(globalContext);
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const groupStateMachine = useGroupsStateMachine();
  const [state] = useActor(groupStateMachine.groupsService);
  const { groups: groupData } = state.context;
  const params = useParams() as { groupid?: string };
  const [searchParams] = useSearchParams();
  const paramCategory = searchParams.get('category');
  const preSelectedCategory = paramCategory
    ? gridCategories.find(category => category.id.toString() === paramCategory)
    : undefined;
  const preSelectedCategoryHasSubCategories = preSelectedCategory?.categories
    ? preSelectedCategory.categories.length > 0
    : false;

  const [selectedBanner, setSelectedBanner] = useState(defaultBanner);
  const [groupName, setGroupName] = useState(gridDefaults.name || '');
  const [showModerationPopover, setShowModerationPopover] = useState(false);

  // only show group creation modal when it's not been preselected, or if a category that has sub-categories was preselected
  const [showGroupCreationModal, setShowGroupCreationModal] = useState(
    preSelectedCategory ? preSelectedCategoryHasSubCategories : true,
  );
  const [groupTypeData, setGroupTypeData] = useState<{
    classroomId?: number;
    gridTemplateId: number;
    groupTypeId?: number;
  }>({
    groupTypeId: preSelectedCategory?.id,
    classroomId: undefined,
    gridTemplateId: 1,
  });
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (fetcher.type === 'done' && fetcher.data && Object.keys(fetcher.data?.error ?? {}).length > 0) {
      const hasGroupName = fetcher.data?.moderatedGroupName;
      const isInappropriateName = fetcher.data?.error.status === 400;
      if (hasGroupName) setGroupName(fetcher.data.moderatedGroupName);
      if (isInappropriateName) setShowModerationPopover(true);
    }
  }, [fetcher]);

  const loadTabPanels = useCallback(() => {
    const gridCoverCategories = Object.values(BannerEnum);

    return gridCoverCategories.map(category => {
      const assets = bannerOptions.filter(bannerOption => bannerOption.category?.toLowerCase() === category);
      return {
        panel: (
          <BannerPanel
            panelAssets={assets}
            selectedBanner={selectedBanner}
            setSelectedBanner={setSelectedBanner}
            onImageUploaded={(imageData: ImageUpload) =>
              setSelectedBanner({ asset_id: imageData.object_id, url: imageData.dataURI, descriptor: 'upload' })
            }
          />
        ),
        // Translation Key Evaluations
        // groupForm.featured
        // groupForm.art
        // groupForm.nature
        // groupForm.people
        // groupForm.textures
        title: t(`groupForm.${category}`),
      };
    });
  }, [bannerOptions, selectedBanner, t]);

  const handleCancelGroupCreation = () => {
    if (isEditing && params?.groupid) {
      navigate(routes.GROUPS_ID_TOPICS_FUNC(params.groupid));
      return;
    }
    // navigate users to their first available group on cancel
    const hasGroups = has(groupData, '[0].id');

    if (hasGroups) {
      const groupId = groupData[0].id;
      navigate(routes.GROUPS_ID_TOPICS_FUNC(groupId));
      return;
    }

    if (fromIntentModal) {
      setClosedIntentModal(false);
      setClosedCreate(true);
    }

    // otherwise take them home
    navigate(routes.HOME);
  };

  return (
    <>
      <GroupFormHeader
        groupName={groupName}
        error={!!fetcher.data?.errors?.name}
        isSubmitting={fetcher.state !== 'idle'}
        isEditing={isEditing}
        selectedBanner={selectedBanner}
        setGroupName={setGroupName}
        setShowModerationPopover={setShowModerationPopover}
        showModerationPopover={showModerationPopover}
        titleRef={titleRef}
      />
      <fetcher.Form
        method="post"
        action={isEditing && params.groupid ? routes.GROUPS_ID_UPDATE_FUNC(params.groupid) : routes.GROUPS_NEW}
        className="canvas-column__container"
      >
        <Tabs
          data={loadTabPanels()}
          data-testid="groupForm__tabs__selectBanner"
          hideTabRule
          stack="small"
          tabListPosition="left"
        />
        <input type="hidden" name="access_control" value={gridDefaults.access_control} />
        <input type="hidden" name="active" value="true" />
        <input type="hidden" name="allow_downloads" value="true" />
        <input type="hidden" name="createStudents" value={[]} />
        <input type="hidden" name="custom_vanity_token" value="" />
        <input type="hidden" name="digest_frequency" value="daily" />
        <input type="hidden" name="email_domains" value={[]} />
        <input type="hidden" name="email_owner" value="false" />
        <input type="hidden" name="emailsAndDomains" value={[]} />
        <input type="hidden" name="errors" value="{}" />
        <input type="hidden" name="grid_template_id" value={groupTypeData.gridTemplateId} />
        <input type="hidden" name="name" value={groupName} />
        {selectedBanner.asset_id !== '' && <input type="hidden" name="image" value={selectedBanner.asset_id} />}
        <input
          type="hidden"
          name="image_descriptor"
          value={selectedBanner?.descriptor || gridDefaults.image_descriptor}
        />
        {!selectedBanner && gridDefaults.image_url && (
          <input type="hidden" name="image_url" value={gridDefaults.image_url} />
        )}
        <input type="hidden" name="immersive_reader" value="true" />
        <input type="hidden" name="lang" value="en-US" />
        <input type="hidden" name="passwordProtected" value="false" />
        <input type="hidden" name="protected" value="true" />
        <input type="hidden" name="purpose" value="" />
        <input type="hidden" name="shareable" value="false" />
        <input type="hidden" name="step" value={1} />
        <input type="hidden" name="students" value={[]} />
        <input type="hidden" name="subscribable" value="true" />
        <input type="hidden" name="synchronous_moderation" value="true" />
        <input type="hidden" name="transcripts_enabled" value="true" />
        <input type="hidden" name="validationErrors" value="{}" />
        <input type="hidden" name="vanity_token" value="" />
        <input
          type="hidden"
          name="type"
          value={JSON.stringify({
            id: groupTypeData.groupTypeId,
            category_id: groupTypeData.classroomId,
          })}
        />

        <StickyBar justify="right">
          <Button theme="secondary" onClick={handleCancelGroupCreation} data-testid="groupForm__button__cancel">
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            loading={fetcher.state !== 'idle'}
            name="_action"
            value={isEditing ? 'editGroup' : 'createGroup'}
            data-testid="groupForm__button__create"
          >
            {isEditing ? t('common.update') : t('common.create')}
          </ButtonSubmit>
          {showGroupCreationModal && !isEditing && !fetcher.data && (
            <ClassroomTypeModal
              gridCategories={gridCategories}
              onRequestClose={handleCancelGroupCreation}
              onComplete={() => {
                setShowGroupCreationModal(false);
                // timeout needed for modal to completely close before focusing
                setTimeout(() => {
                  if (titleRef.current) titleRef.current.focus();
                }, 100);
              }}
              setGroupTypeData={setGroupTypeData}
            />
          )}
        </StickyBar>
      </fetcher.Form>
    </>
  );
};

export default GroupForm;
