import { ThemesEnum } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, createContext, useContext, useState, useCallback, useMemo, useRef } from 'react';

import resourceRoutes from '~/constants/resourceRoutes';

type ThemeContextTypes = {
  currentTheme: ThemesEnum | null;
  changeTheme: (arg1: ThemesEnum) => void;
  isForcedColors: boolean;
};

type ThemeProps = {
  children: React.ReactNode;
  specifiedTheme: ThemesEnum | null;
};

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)';
const FORCED_COLORS_QUERY = '(forced-colors: active)';

const getPreferredTheme = () => (window.matchMedia(COLOR_SCHEME_QUERY).matches ? ThemesEnum.dark : ThemesEnum.light);

const ThemeContext = createContext({} as ThemeContextTypes);

// This provides the context for keeping theme state
export const Theme = ({ children, specifiedTheme }: ThemeProps) => {
  const [currentTheme, setCurrentTheme] = useState<ThemesEnum | null>(() => {
    // from remix dark mode example: https://github.com/remix-run/remix/blob/main/examples/dark-mode/app/utils/theme-provider.tsx
    // On the server, if we don't have a specified theme then we should
    // return null and the clientThemeCode will set the theme for us
    // before hydration. Then (during hydration), this code will get the same
    // value that clientThemeCode got so hydration is happy.
    if (specifiedTheme) {
      return specifiedTheme;
    }
    // there's no way for us to know what the theme should be in this context
    // the client will have to figure it out before hydration.
    if (typeof document === 'undefined') {
      return null;
    }

    return getPreferredTheme();
  });
  const [isForcedColors, setIsForcedColors] = useState(false);

  const persistTheme = useFetcher();
  // TODO: remove this when persistTheme is memoized properly
  const persistThemeRef = useRef(persistTheme);

  useEffect(() => {
    persistThemeRef.current = persistTheme;
  }, [persistTheme]);

  const mountRun = useRef(false);

  const changeTheme = useCallback(
    (theme: ThemesEnum) => {
      const bodyClasses = document.body.classList;
      if (theme === ThemesEnum.dark) {
        setCurrentTheme(ThemesEnum.dark);
        if (bodyClasses.contains(ThemesEnum.light)) {
          bodyClasses.remove(ThemesEnum.light);
        }
        bodyClasses.add(ThemesEnum.dark);
      } else {
        setCurrentTheme(ThemesEnum.light);
        if (bodyClasses.contains(ThemesEnum.dark)) {
          bodyClasses.remove(ThemesEnum.dark);
        }
        bodyClasses.add(ThemesEnum.light);
      }
    },
    [setCurrentTheme],
  );

  useEffect(() => {
    if (!mountRun.current) {
      mountRun.current = true;
      return;
    }
    if (!currentTheme) {
      return;
    }

    persistThemeRef.current.submit(
      { theme: currentTheme, _action: 'UpdateTheme' },
      { action: resourceRoutes.userPreferences, method: 'post' },
    );
  }, [currentTheme]);

  // Listen for changes to windows contrast modes
  useEffect(() => {
    const onForcedColorsChange = () => {
      setIsForcedColors(!isForcedColors);
    };

    const mediaQuery = window.matchMedia(FORCED_COLORS_QUERY);
    try {
      // addEventListener is the preferred and more modern method when interacting with MediaQueryList objects.
      mediaQuery.addEventListener('change', onForcedColorsChange);
    } catch {
      // addListener as a fallback for backwards compatibility (Safari before version 14)
      mediaQuery.addListener(onForcedColorsChange);
    }
    return () => {
      try {
        mediaQuery.removeEventListener('change', onForcedColorsChange);
      } catch {
        mediaQuery.removeListener(onForcedColorsChange);
      }
    };
  }, [isForcedColors, setIsForcedColors]);

  // Listen for changes to prefers color scheme
  useEffect(() => {
    const onColorSchemeChange = (e: MediaQueryListEvent) => {
      setCurrentTheme(e.matches ? ThemesEnum.dark : ThemesEnum.light);
    };

    const mediaQuery = window.matchMedia(COLOR_SCHEME_QUERY);
    try {
      mediaQuery.addEventListener('change', onColorSchemeChange);
    } catch {
      // addListener as a fallback for backwards compatibility (Safari before version 14)
      mediaQuery.addListener(onColorSchemeChange);
    }

    return () => {
      try {
        mediaQuery.removeEventListener('change', onColorSchemeChange);
      } catch {
        // addListener as a fallback for backwards compatibility (Safari before version 14)
        mediaQuery.removeListener(onColorSchemeChange);
      }
    };
  }, [changeTheme]);

  const providerValue = useMemo(
    () => ({
      currentTheme,
      changeTheme,
      isForcedColors,
    }),
    [currentTheme, changeTheme, isForcedColors],
  );

  return <ThemeContext.Provider value={providerValue}>{children}</ThemeContext.Provider>;
};

// This hook can be used within the Theme component to get and set theme state
export const useTheme = () => useContext(ThemeContext);

const generateClientThemeCode = (ssrTheme: boolean) => `
// The idea for this is based off the remix/dark-mode example combined with our existing implementation
;(() => {
  const prefersDark = window.matchMedia(${JSON.stringify(COLOR_SCHEME_QUERY)}).matches;
  
  const forcedColors = window.matchMedia(${JSON.stringify(FORCED_COLORS_QUERY)}).matches;

  const darkClass = ${JSON.stringify(ThemesEnum.dark)};
  const lightClass = ${JSON.stringify(ThemesEnum.light)};

  const changeTheme = (theme) => {
    const cl = document.documentElement.classList;
    if (theme === 'dark') {
      if (cl.contains(lightClass)) {
        cl.remove(lightClass);
      }
      cl.add(darkClass);
      } else {
        if (cl.contains(darkClass)) {
          cl.remove(darkClass);
        }
        cl.add(lightClass);
      }
  }

  if (forcedColors) {
    changeTheme(prefersDark ? 'dark': 'light')
  } else if (${ssrTheme}) {
    return;
  } else {
    changeTheme(prefersDark ? 'dark' : 'light')
  }
})();
`;

export const ThemeHead = ({ ssrTheme }: { ssrTheme: boolean }) => {
  return <script dangerouslySetInnerHTML={{ __html: generateClientThemeCode(ssrTheme) }} />;
};
