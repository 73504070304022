import { Modal } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import externalLinks from '~/constants/externalLinks';
import feedbackAckModalStyles from '~/styles/components/Modals/Feedback/FeedbackAckModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

export const links = () => [{ rel: 'stylesheet', href: feedbackAckModalStyles }];

type Props = {
  onRequestClose: OnRequestCloseType;
};

const FeedbackAckModal = ({ onRequestClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onRequestClose}>
      <div className="feedbackAckModal__body">
        <img
          className="feedbackAckModal__raiseHands"
          alt=""
          src={externalLinks.RaisingHandsEmoji}
          height="120px"
          width="120px" />

        <h1 className="feedbackAckModal__heading">{t('feedbackAckModal.thankYou')}</h1>
        <span>{t('feedbackAckModal.thankYouDesc')}</span>
      </div>
    </Modal>);

};

export default FeedbackAckModal;