import { Button, IcFluentChevronRight24Regular } from '@flipgrid/flipkit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import GlobalContext from '~/contexts/globalContext';
import draggerNextStyles from '~/styles/components/Dragger/DraggerNext.css';

import type { LinksFunction } from '@remix-run/node';

type Props = {
  ariaLabel: string;
  className: string;
  formState: TSFix;
  offset: number;
};

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: draggerNextStyles }];

const DraggerNext = ({ ariaLabel, className, formState, offset }: Props) => {
  const { t } = useTranslation();
  const { announceLiveMessage } = useContext(GlobalContext);

  const updateDraggerPosition = () => {
    if (formState && formState.draggerRef && formState.frame) {
      if (formState.frame.progress >= 1) {
        formState.draggerRef.setPosition(0);
        if (announceLiveMessage) announceLiveMessage(t('draggerNext.endHit'));
      } else {
        formState.draggerRef.setPosition(formState.frame.x - offset);
        if (announceLiveMessage) announceLiveMessage(t('draggerNext.scrolledForward'));
      }
    }
  };

  return (
    <Button
      className={'draggerNext__button ' + className + (formState.disableNext ? ' draggerNext__disabled' : '')}
      disabled={formState.disableNext}
      onClick={updateDraggerPosition}
      data-testid="draggerNext__button__next"
      aria-label={ariaLabel}
      icon={<IcFluentChevronRight24Regular className="draggerNext__icon" />}
      variant="circle"
      size="26" />);


};

export default DraggerNext;