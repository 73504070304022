import { EmptyState } from '@flipgrid/flipkit';

import skeletonStyles from '~/styles/components/Skeleton/Skeleton.css';

export const links = () => [{ rel: 'stylesheet', href: skeletonStyles }];

export const MyGroupsList = () => {
  return (
    <div className="skeleton-myGroupsList">
      <div className="skeleton-myGroupsList__item" />
      <div className="skeleton-myGroupsList__item" />
      <div className="skeleton-myGroupsList__item" />
      <div className="skeleton-myGroupsList__item" />
    </div>);

};

export const MyVideosPage = () => {
  return (
    <div className="standardLayout">
      <div className="skeleton-myVideosPage">
        <div className="skeleton-myVideosPage__head">
          <div className="skeleton-myVideosPage__profileImage" />
          <div className="skeleton-myVideosPage__name" />
          <div className="skeleton-myVideosPage__email" />
        </div>
        <div className="skeleton-myVideosPage__videos tile__layout">
          {[...Array(10).keys()].map((value, index) =>
          <div key={`filler-${index}`} />
          )}
        </div>
      </div>
    </div>);

};

export const GroupPage = () => {
  return (
    <>
      <div className="canvas-column__full skeleton-groupPage__header">
        <div className="skeleton-groupPage__header__inner">
          <div className="skeleton-groupPage__title" />
          <div className="skeleton-groupPage__meta" />
        </div>
      </div>
      <div className="canvas-column__container groupDetails skeleton-groupPage__details">
        {[...Array(4).keys()].map((value, index) =>
        <div className="skeleton-groupPage__item" key={`skeleton-group-${index}`}>
            <div className="skeleton-groupPage__contentWrapper">
              <div className="skeleton-groupPage__date" />
              <div className="skeleton-groupPage__itemTitle" />
            </div>
            <div className="skeleton-groupPage__responses">
              {[...Array(4).keys()].map((value2, index2) =>
            <div key={'2nd-skeleton-group-' + index + '-' + index2} />
            )}
            </div>
          </div>
        )}
      </div>
    </>);

};

export const TopicPage = () => {
  return (
    <div className="standardLayout">
      <div className="skeleton-topicPage">
        <div className="skeleton-topicPage__header ">
          <div className="skeleton-topicPage__header__content">
            <div className="skeleton-topicPage__header__title">
              {[...Array(3).keys()].map((value, index) =>
              <div key={'filler-' + index} />
              )}
            </div>
            <div className="skeleton-topicPage__header__description">
              {[...Array(4).keys()].map((value, index) =>
              <div key={'2ndFiller-' + index} />
              )}
            </div>
          </div>
          <div className="skeleton-topicPage__header__image" />
        </div>
        <div className="horizontalLine mt32 mb32" />
        <div className="skeleton-topicPage__body ">
          <div className="skeleton-topicPage__responses tile__layout">
            {[...Array(8).keys()].map((value, index) =>
            <div key={'3rdFiller-' + index} />
            )}
          </div>
        </div>
      </div>
    </div>);

};

export const Playback = () => {
  return (
    <div className="standardLayout">
      <div className="skeleton-playback">
        <div className="skeleton-playback__video" />
      </div>
    </div>);

};

export const Generic = () => {
  return (
    <div className="standardLayout">
      <div className="skeleton-generic">
        <EmptyState loading />
      </div>
    </div>);

};