import {
  ActionGroup,
  Add16Solid,
  Button,
  IcFluentMegaphone24Filled,
  IcFluentMegaphone24Regular,
  IcFluentPeopleMembers24Filled,
  IcFluentPeopleMembers24Regular,
  Link,
  ModalConsumer,
  Search,
  Tabs,
  Tooltip,
  useLocalStorage } from
'@flipgrid/flipkit';
import {
  Outlet,
  PrefetchPageLinks,
  useFetcher,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams } from
'@remix-run/react';
import { debounce } from 'lodash-es';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GroupHeader from './GroupHeader';
import ShareGroupModalBtn from '../Share/ShareGroupModalBtn';
import TopicFilterDropdown from '../TopicList/TopicFilterDropdown';
import ScrollToTopButton from '../Utility/Buttons/ScrollToTopButton';
import SyncGoogleRosterButton from '../Utility/Buttons/SyncGoogleRosterButton';
import TopicCopilotBtn from '~/components/AI/TopicCopilotBtn';
import TeachingBubble from '~/components/TeachingBubble/TeachingBubble';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import globalContext from '~/contexts/globalContext';
import topicListContext from '~/contexts/topicListContext';
import checkIsLtiGroup from '~/helper/checkIsLtiGroup';
import { isFlagPresent, logEvent } from '~/helper/helper';
import { isMemberGroupLead, isMemberGroupOwner, userBelongsToGroup } from '~/helper/userRoles';
import useFetcherWatcher from '~/hooks/useFetcherWatcher';
import useGetUser from '~/hooks/useGetUser';
import useIsLtiGroupOrFrame from '~/hooks/useIsLtiGroupOrFrame';
import { useGroupsStateMachine } from '~/statemachine/GroupsStateMachineContext';
import groupDetailsIndex from '~/styles/routes/index/groupDetailsIndex.css';

import type { LinksFunction } from '@remix-run/node';
import type { ChangeEvent } from 'react';
import type { ApiResponse, Group, Member, Topic } from 'types';

export const links: LinksFunction = () => [
{ rel: 'preload', href: groupDetailsIndex, as: 'style' },
{ rel: 'stylesheet', href: groupDetailsIndex }];


type Props = {
  group: Group;
  studentView: 'true' | 'false';
};

const GroupDetails = ({ group, studentView }: Props) => {
  const { t } = useTranslation();
  const { breakPoints } = useContext(globalContext);
  const { setTopics, setTopicsMetadata } = useContext(topicListContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(location.pathname?.includes('topics') ? 0 : 1);
  const [unCachedUsernameMembers, setUnCachedUsernameMembers] = useState<Member[]>([]);
  const filterFetcher = useFetcher<ApiResponse<Topic[]>>();
  const user = useGetUser();
  const isGroupLead = isMemberGroupLead(group, studentView);
  const isGroupOwner = isMemberGroupOwner(group, studentView);
  const isUsernameGroup = group.access_control === 'student';
  const isPassportGroup = user && user.identities?.includes('passport');
  const syncButtonIsVisible = !!group.provider_grid_connection && isGroupOwner && tabIndex === 1;
  const groupStateMachine = useGroupsStateMachine();
  const { send } = groupStateMachine.groupsService;
  const { groupid } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialSearch] = useState(searchParams.get('q') ?? '');
  const isLtiGroup = checkIsLtiGroup(group);
  const isLtiGroupOrFrame = useIsLtiGroupOrFrame(group);
  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  useEffect(() => {
    if (filterFetcher.type === 'done') {
      setTopics(filterFetcher.data.data);
      setTopicsMetadata(filterFetcher.data.metadata);
    }
  }, [filterFetcher, setTopics, setTopicsMetadata]);

  useEffect(() => {
    const newIndex = location.pathname?.includes('topics') ? 0 : 1;
    if (newIndex !== tabIndex) setTabIndex(newIndex);
    // If tabIndex is added here the tab highlight will flicker
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.id, location.pathname]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setMemberSearchQueryParams = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      setSearchParams(query.length > 2 ? { q: query } : '');
    }, 200),
    [setSearchParams]
  );

  // this watches for username members being added in the share group modal. If the topics page is loaded in the background it will have a cached copy
  // of the members tab available, without the most recently added members. So we use this watcher to get the new members to pass to the members tab if they navigate there
  useFetcherWatcher<ApiResponse<Member[]>>({
    action: resourceRoutes.username,
    formDataAction: 'add',
    update: (addMembersResponse) => {
      const usernameMembers = addMembersResponse?.data;
      if (usernameMembers && usernameMembers.length > 0) setUnCachedUsernameMembers(usernameMembers);
    }
  });

  useEffect(() => {
    if (group.active && userBelongsToGroup(group)) {
      // if a group is active and a user belongs to the group send the ADD action to the state machine
      // if the group is already on the left rail list nothing will happen otherwise it will add it
      send('ADD', { group });
    }
  }, [groupid, group, send]);

  const getTabPath = (nextTab: 'members' | 'topics') => {
    if (nextTab === 'members') return routes.GROUPS_ID_MEMBERS_FUNC(group.id);
    return routes.GROUPS_ID_TOPICS_FUNC(group.id);
  };

  const changeTabs = (key: number) => {
    const nextTab = key === 0 ? 'topics' : 'members';
    const navigationState =
    nextTab === 'members' && unCachedUsernameMembers.length > 0 ?
    { state: { hasUncachedMembers: true, members: unCachedUsernameMembers } } :
    {};
    navigate(getTabPath(nextTab), navigationState);
    setTabIndex(key);
  };

  const { queuedTourNewGroup, setQueuedTourNewGroup } = useContext(globalContext);
  const [hasViewedTourNewGroup, setHasViewedTourNewGroup] = useLocalStorage('hasViewedTourNewGroup', false);
  const [tourStep, setTourStep] = useState(0);
  const shouldDisplayTourNewGroup = isGroupLead && !hasViewedTourNewGroup;

  useEffect(() => {
    const queuedReadyToDisplay = queuedTourNewGroup && shouldDisplayTourNewGroup;
    const queuedAlreadyDisplayed = queuedTourNewGroup && !shouldDisplayTourNewGroup;

    if (queuedReadyToDisplay) {
      setTourStep(1);
      logEvent({ name: 'TeachingBubble::Displayed' });
      setQueuedTourNewGroup(false);
    } else if (queuedAlreadyDisplayed) {
      setQueuedTourNewGroup(false);
    }
  }, [setQueuedTourNewGroup, queuedTourNewGroup, shouldDisplayTourNewGroup]);

  const onTourNewGroupClose = (activeTeachingBubble?: string) => {
    logEvent({ name: 'TeachingBubble::Closed' }, { type: 'tour', closedOn: activeTeachingBubble });
    setHasViewedTourNewGroup(true);
  };

  const onTourNewGroupPrev = (activeTeachingBubble?: string) => {
    setTourStep((prevCount) => prevCount - 1);
    logEvent({ name: 'TeachingBubble::Prev' }, { prevOn: activeTeachingBubble });
  };

  const onTourNewGroupNext = (activeTeachingBubble?: string) => {
    setTourStep((prevCount) => prevCount + 1);
    logEvent({ name: 'TeachingBubble::Next' }, { nextOn: activeTeachingBubble });
  };

  return (
    <ModalConsumer>
      {({ showModal }) =>
      <>
          <GroupHeader entity={group} />
          <div className="canvas-column__container groupDetails">
            {user.accountType === 'account' ?
          <Tabs
            stack={syncButtonIsVisible ? 'medium' : false}
            data-testid="groupDetails__tabs"
            data={[
            {
              panel:
              <>
                        {!location.search && <PrefetchPageLinks page={getTabPath('members')} />}
                        {tabIndex === 0 && <Outlet />}
                      </>,

              title:
              <Tabs.Title
                icon={<IcFluentMegaphone24Regular />}
                activeIcon={<IcFluentMegaphone24Filled />}
                title={t('shared.numTopics', {
                  count: (isGroupLead ? group.all_topic_count : group.topic_count) || 0
                })}
                mobileTitle={(group.topic_count || 0).toString()} />


            },
            {
              panel:
              <>
                        {!location.search && <PrefetchPageLinks page={getTabPath('topics')} />}
                        {tabIndex === 1 && <Outlet />}
                      </>,

              title:
              <Tabs.Title
                icon={<IcFluentPeopleMembers24Regular />}
                activeIcon={<IcFluentPeopleMembers24Filled />}
                title={t('shared.numStudents', { count: group.member_count || 0 })}
                mobileTitle={(group.member_count || 0).toString()} />


            }]}

            onChange={(key: number) => changeTabs(key)}
            tabIndex={tabIndex}
            hideTabRule
            tabListMeta={
            <ActionGroup>
                    {tabIndex === 0 &&
              <>
                        <TopicFilterDropdown fetcher={filterFetcher} group={group} />

                        {isDeprecated ?
                null :
                isGroupLead &&
                !isLtiGroupOrFrame &&
                <TopicCopilotBtn groupId={group.id} showText={breakPoints.isScreenSmall} />}


                        {isDeprecated ?
                null :
                (isGroupLead && !isLtiGroupOrFrame ||
                group?.member_topic_creation &&
                !isUsernameGroup &&
                !isLtiGroup &&
                !isPassportGroup) &&
                <Tooltip label={t('shared.addTopic')} className="groupDetails__addTopic">
                                <Link
                    size="36"
                    variant="button"
                    icon={<Add16Solid height={20} />}
                    aria-label={t('shared.addTopic')}
                    to={routes.GROUPS_ID_TOPICS_NEW_FUNC(group.id)}
                    data-testid="groupDetails__button__addTopicBtn">

                                  {t('common.topic')}
                                </Link>
                              </Tooltip>}

                      </>}

                    {tabIndex === 1 &&
              <>
                        <SyncGoogleRosterButton group={group} type="primary" />
                        {isGroupLead &&
                <>
                            {/* <Search
                     wrapperClassName="groupDetails__memberSearch"
                     onChange={setMemberSearchQueryParams}
                     value={initialSearch}
                    /> */}
                            {!isDeprecated &&
                  <ShareGroupModalBtn
                    btnSize="36"
                    btnTheme="primary"
                    disabled={isLtiGroupOrFrame}
                    group={group}
                    icon={<Add16Solid height={20} />}
                    showModal={showModal} />}


                          </>}

                      </>}

                  </ActionGroup>}

            tabListPosition="left" /> :


          <Outlet />}

            <ScrollToTopButton />
          </div>

          {isDeprecated ? null :
        <>
              {shouldDisplayTourNewGroup && tourStep === 1 &&
          <TeachingBubble
            target={`[data-testid="groupDetails__button__addTopicBtn"]`}
            onTeachingBubbleClose={() => onTourNewGroupClose('Add Topic')}
            currentBubble={1}
            totalBubbles={4}>

                  <div className="teachingBubble__content">
                    <p className="m0 color__inherit">{t('teachingBubble.leadAddTopic')}</p>
                  </div>
                  <div className="teachingBubble__buttonWrapper">
                    <Button
                aria-label={t('common.next')}
                className="teachingBubble__ctaBtn"
                theme="secondary"
                data-testid="teachingBubble__button__next"
                onClick={() => onTourNewGroupNext('Add Topic')}>

                      {t('common.next')}
                    </Button>
                  </div>
                </TeachingBubble>}


              {isDeprecated ?
          null :
          shouldDisplayTourNewGroup &&
          tourStep === 2 &&
          <TeachingBubble
            target={`[data-testid="topicCopilotBtn__button__openAI"]`}
            onTeachingBubbleClose={() => onTourNewGroupClose('Topic Copilot')}
            currentBubble={2}
            totalBubbles={4}>

                      <div className="teachingBubble__content">
                        <p className="m0 color__inherit">{t('teachingBubble.leadTopicCopilot')}</p>
                      </div>
                      <div className="teachingBubble__buttonWrapper">
                        <Button
                aria-label={t('common.back')}
                className="teachingBubble__ctaBtn"
                theme="tertiary"
                data-testid="teachingBubble__button__back"
                onClick={() => onTourNewGroupPrev('Topic Copilot')}>

                          {t('common.back')}
                        </Button>
                        <Button
                aria-label={t('common.next')}
                className="teachingBubble__ctaBtn"
                theme="secondary"
                data-testid="teachingBubble__button__next"
                onClick={() => onTourNewGroupNext('Topic Copilot')}>

                          {t('common.next')}
                        </Button>
                      </div>
                    </TeachingBubble>}


              {isDeprecated ?
          null :
          shouldDisplayTourNewGroup &&
          tourStep === 3 &&
          <TeachingBubble
            target=".topicList__container"
            onTeachingBubbleClose={() => onTourNewGroupClose('Explore Topics')}
            currentBubble={3}
            totalBubbles={4}>

                      <div className="teachingBubble__content">
                        <p className="m0 color__inherit">{t('teachingBubble.leadExploreTopics')}</p>
                      </div>
                      <div className="teachingBubble__buttonWrapper">
                        <Button
                aria-label={t('common.back')}
                className="teachingBubble__ctaBtn"
                theme="tertiary"
                data-testid="teachingBubble__button__back"
                onClick={() => onTourNewGroupPrev('Explore Topics')}>

                          {t('common.back')}
                        </Button>
                        <Button
                aria-label={t('common.next')}
                className="teachingBubble__ctaBtn"
                theme="secondary"
                data-testid="teachingBubble__button__next"
                onClick={() => onTourNewGroupNext('Explore Topics')}>

                          {t('common.next')}
                        </Button>
                      </div>
                    </TeachingBubble>}


              {isDeprecated ?
          null :
          shouldDisplayTourNewGroup &&
          tourStep === 4 &&
          <TeachingBubble
            target={`[data-testid="shareGroupModalBtn__button__share"]`}
            onTeachingBubbleClose={() => onTourNewGroupClose('Share Group')}
            currentBubble={4}
            totalBubbles={4}>

                      <div className="teachingBubble__content">
                        <p className="m0 color__inherit">{t('teachingBubble.leadShareGroup')}</p>
                      </div>
                      <div className="teachingBubble__buttonWrapper">
                        <Button
                aria-label={t('common.back')}
                className="teachingBubble__ctaBtn"
                theme="tertiary"
                data-testid="teachingBubble__button__back"
                onClick={() => onTourNewGroupPrev('Share Group')}>

                          {t('common.back')}
                        </Button>
                        <Button
                aria-label={t('common.gotIt')}
                className="teachingBubble__ctaBtn"
                theme="secondary"
                data-testid="teachingBubble__button__next"
                onClick={() => onTourNewGroupClose('Share Group')}>

                          {t('common.gotIt')}
                        </Button>
                      </div>
                    </TeachingBubble>}

            </>}

        </>}

    </ModalConsumer>);

};

export default GroupDetails;