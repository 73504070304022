import { ActivityFeed, AvatarTypeEnum } from '@flipgrid/flipkit';
import { Trans } from 'react-i18next';

import { ActivityType, ActivitySubType, ActivityFeedType } from '../../../types/ActivityFeedTypes';
import { timeAgo } from '../../helper/dateHelpers';
import routes from '~/constants/routes';

import type { ActivityFeedItemType } from '../../../types/ActivityFeedTypes';

type ActivityFeedItemProps = {
  itemData: ActivityFeedItemType;
};

// Get types of Activity to specific AvatarTypes and localized strings
const getActivityProps = (
  activityType: ActivityType,
  activitySubType: ActivitySubType | null,
  actorId: number,
  actorImage: string,
  displayName: string,
  title: string,
  objectTitle: string,
  objectGroupId: number,
  objectId: number,
  targetId: number,
  objectParentId: number,
  topicId: number,
  responseCreator: boolean | null,
  feedType: ActivityFeedType | null,
  vanityToken: string,
) => {
  const avatarTypeImageOrDefault = actorImage ? AvatarTypeEnum.image : AvatarTypeEnum.default;
  const avatarImageId = !actorImage ? actorId : undefined;
  const AvatarImage = () => <ActivityFeed.Avatar id={avatarImageId} type={avatarTypeImageOrDefault} src={actorImage} />;

  switch (activityType) {
    case ActivityType.videoResponse: {
      const responseRoute = routes.GROUPS_ID_TOPICS_ID_RESPONSES_ID_FUNC(objectGroupId, targetId, objectId);

      if (activitySubType === ActivitySubType.moderationAwaitingApproval) {
        return {
          avatar: <AvatarImage />,
          description: (
            <Trans i18nKey="activityFeed.videoResponseAwaitingApproval">
              <b>Pending approval</b> on response by {{ userName: displayName }} in {{ topicTitle: title }}.
            </Trans>
          ),
          url: responseRoute,
        };
      }

      return {
        avatar: <AvatarImage />,
        description: (
          <Trans i18nKey="activityFeed.videoResponse">
            <b>{{ userName: displayName }}</b> added to {{ topicTitle: title }}.
          </Trans>
        ),
        url: responseRoute,
      };
    }

    case ActivityType.textReply:
    case ActivityType.videoReply: {
      const queryParams = activityType === ActivityType.videoReply && objectId ? `?commentID=${objectId}` : '';
      const commentRoute = `${routes.GROUPS_ID_TOPICS_ID_RESPONSES_ID_COMMENTS_FUNC(
        objectGroupId,
        targetId,
        objectParentId,
      )}${queryParams}`;

      return {
        avatar: <AvatarImage />,
        description: (
          <Trans i18nKey="activityFeed.gotCommentReply">
            <b>{{ userName: displayName }}</b> has replied to your comment on {{ owner: displayName }}'s video!
          </Trans>
        ),
        url: commentRoute,
      };
    }

    case ActivityType.videoComment:
    case ActivityType.textComment: {
      const queryParams = activityType === ActivityType.videoComment && objectId ? `?commentID=${objectId}` : '';
      const commentRoute = `${routes.GROUPS_ID_TOPICS_ID_RESPONSES_ID_COMMENTS_FUNC(
        objectGroupId,
        targetId,
        objectParentId,
      )}${queryParams}`;

      if (activitySubType === ActivitySubType.moderationAwaitingApproval) {
        return {
          avatar: <AvatarImage />,
          description: (
            <Trans i18nKey="activityFeed.commentAwaitingApproval">
              <b>Pending approval</b> on comment by {{ userName: displayName }} in {{ topicTitle: title }}.
            </Trans>
          ),
          url: commentRoute,
        };
      }
      if (!responseCreator) {
        return {
          avatar: <AvatarImage />,
          description: (
            <Trans i18nKey="activityFeed.userHasAlsoCommented">
              <b>{{ userName: displayName }}</b> has also commented on the response on {{ topicTitle: title }}.
            </Trans>
          ),
          url: commentRoute,
        };
      }
      return {
        avatar: <AvatarImage />,
        description: (
          <Trans i18nKey="activityFeed.videoComment">
            <b>{{ userName: displayName }}</b> commented on your video in {{ topicTitle: title }}.
          </Trans>
        ),
        url: commentRoute,
      };
    }
    case ActivityType.requestToJoinCreated: {
      const rtjCreatedRoute = routes.GROUPS_ID_MEMBERS_FUNC(objectGroupId);

      if (feedType === ActivityFeedType.reminderUserActivity) {
        return {
          avatar: <AvatarImage />,
          description: (
            <Trans i18nKey="activityFeed.joinRequestReminder" values={{ userName: displayName }}>
              Pending approval on <b>{displayName}'s</b> request to join your Group.
            </Trans>
          ),
          url: rtjCreatedRoute,
        };
      }
      return {
        avatar: <AvatarImage />,
        description: (
          <Trans i18nKey="activityFeed.joinRequest">
            <b>{{ userName: displayName }}</b> wants to join {{ groupTitle: title }}.
          </Trans>
        ),
        url: rtjCreatedRoute,
      };
    }
    case ActivityType.requestToJoinApproved:
    case ActivityType.invitationCreated:
      return {
        avatar: <ActivityFeed.Avatar type={AvatarTypeEnum.groupJoin} />,
        description: <Trans i18nKey="activityFeed.joinGroup">You have been added to {{ groupTitle: title }}.</Trans>,
        url: routes.VANITY_RTJ_FUNC(vanityToken),
      };
    case ActivityType.invitationAccepted:
      return {
        avatar: <AvatarImage />,
        description: (
          <Trans i18nKey="activityFeed.joinAdded">
            <b>{{ userName: displayName }}</b> has joined {{ groupTitle: title }}.
          </Trans>
        ),
        url: routes.GROUPS_ID_MEMBERS_FUNC(objectGroupId),
      };
    case ActivityType.groupTopicAdded:
      if (feedType === ActivityFeedType.reminderUserActivity) {
        return {
          avatar: <AvatarImage />,
          description: (
            <Trans i18nKey="activityFeed.topicAddedReminder">
              Awaiting your response on <b>{{ topicTitle: objectTitle }}</b>.
            </Trans>
          ),
          url: routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(objectGroupId, objectId),
        };
      }
      return {
        avatar: <AvatarImage />,
        description: (
          <Trans i18nKey="activityFeed.topicAdded">
            <b>New topic</b> {{ topicTitle: objectTitle }} has been added to {{ groupTitle: title }}.
          </Trans>
        ),
        url: routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(objectGroupId, objectId),
      };
    case ActivityType.responseLike:
      return {
        avatar: <AvatarImage />,
        description: (
          <Trans i18nKey="activityFeed.responseLiked">
            <b>{{ userName: displayName }}</b> liked your response in {{ topicTitle: title }}.
          </Trans>
        ),
        url: routes.GROUPS_ID_TOPICS_ID_RESPONSES_ID_FUNC(objectGroupId, targetId, objectId),
      };
  }
};

const ActivityFeedItem = ({ itemData }: ActivityFeedItemProps) => {
  const {
    attributes: {
      actor: { display_name, image_url: actorImage, id: actorId },
      created_at,
      object: {
        title: objectTitle,
        image_url: objectImage,
        grid_id: objectGroupId,
        id: objectId,
        parent_id: objectParentId,
      },
      target: { title, id: targetId, topic_id: topicId, vanity_token: vanityToken },
      type,
      sub_type: subType,
      response_creator: responseCreator,
      feed_type: feedType,
    },
  } = itemData;

  const { avatar, description, url } = getActivityProps(
    type,
    subType,
    actorId,
    actorImage,
    display_name,
    title,
    objectTitle,
    objectGroupId,
    objectId,
    targetId,
    objectParentId,
    topicId,
    responseCreator,
    feedType,
    vanityToken,
  );

  return (
    <ActivityFeed.Item avatar={avatar} text={description} thumb={objectImage} time={timeAgo(created_at)} to={url} />
  );
};

export default ActivityFeedItem;
