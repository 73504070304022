import { Anchor, Banner, ButtonSubmit, DefaultProfilePic, Modal, TextArea } from '@flipgrid/flipkit';
import { useLocation, useMatches, useSearchParams } from '@remix-run/react';
import { forIn } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Combobox from '~/components/FkWrappers/Combobox';
import DiscardFeedbackModal from '~/components/Modals/Feedback/DiscardFeedbackModal';
import countriesStates from '~/constants/countriesStates';
import externalLinks from '~/constants/externalLinks';
import { useGetFeedbackTypes } from '~/constants/feedbackTypes';
import HandleIds from '~/constants/handleIds';
import Telemetry from '~/constants/telemetry';
import { formatName } from '~/helper/formatting';
import { telemetryAttributes } from '~/helper/helper';
import { handleError } from '~/helper/imgOnError';
import feedbackModalStyles from '~/styles/components/Modals/Feedback/FeedbackModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { FetcherWithComponents } from '@remix-run/react';
import type { ChangeEvent, SyntheticEvent, FormEvent } from 'react';
import type { RegularUser, RouteTyping } from 'types';

export const links = () => [{ rel: 'stylesheet', href: feedbackModalStyles }];

type Props = {
  feedbackFetcher: FetcherWithComponents<TSFix>;
  onRequestClose: OnRequestCloseType;
  onSubmit: (event: FormData) => void;
  user: RegularUser;
};

const FeedbackModal = ({ feedbackFetcher, onRequestClose, onSubmit, user }: Props) => {
  const { t } = useTranslation();
  const { countries } = countriesStates;
  const feedbackTypes = useGetFeedbackTypes();
  const location = useLocation();
  const matches = useMatches();
  const ua = (matches.find((m) => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const [selectedFeedback, setSelectedFeedback] = useState<string>();
  const [responseDescription, setResponseDescription] = useState('');
  const [showDiscardChangesWarning, setShowDiscardChangesWarning] = useState(false);
  const [error, setError] = useState('');
  const maxLength = 1000;

  const appendAdditionalFormFields = (form: FormData) => {
    if (user.country_code) {
      const country = countries.find((c) => c.code === user.country_code);
      if (country) form.append('geography', country.name);
    }

    const communities: string[] = [];
    forIn(user.groups, (Value) => {
      communities.push(((Value as unknown) as string));
    });
    form.append('community', JSON.stringify(communities));
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const hasProvideFeedbackParam = searchParams.get('provideFeedback') === 'true';

  const provideFeedback = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    const feedback = (form.get('desc') as string | null);
    const hasFeedback = !!feedback && feedback.trim().length > 0;
    if (hasFeedback) {
      appendAdditionalFormFields(form);
      onSubmit(form);
      if (hasProvideFeedbackParam) {
        searchParams.delete('provideFeedback');
        setSearchParams(searchParams);
      }
    } else {
      setError(t('feedbackModal.descriptionErrorMessage'));
    }
  };

  const feedbackCategory = useMemo<string>(() => {
    if (selectedFeedback) {
      return selectedFeedback;
    }
    switch (true) {
      case location.pathname.includes('/topics/new') ||
      location.pathname.includes('/topics') && location.pathname.includes('/update'):
        return feedbackTypes.Topics.type;
      case location.pathname.includes('/groups'):
        return feedbackTypes.Groups.type;
      case location.pathname.includes('/my-videos'):
        return feedbackTypes.MyVideos.type;
      default:
        return feedbackTypes.Camera.type;
    }
  }, [feedbackTypes, location.pathname, selectedFeedback]);

  const onClose = () => {
    onRequestClose();
    setError('');
  };

  return (
    <Modal
      onClose={() => setShowDiscardChangesWarning(true)}
      topComponent={
      <div aria-live="polite">
          {error &&
        <Banner theme="redSecondary" className="feedbackModal__bannerText">
              {t('feedbackModal.bannerMessage')}
            </Banner>}

        </div>}>


      <h1 className="fk-modalHeader">{t('feedbackModal.heading')}</h1>
      <div className="feedbackModal__privacyPolicy fk-helpText feedbackHelp feedback_sectionEnd">
        <Trans i18nKey="feedbackModal.needHelp">
          Need help instead? Visit&nbsp;
          <Anchor to={externalLinks.Help} data-testid="feedbackModal__button__needHelpLink" newTab>
            help center.
          </Anchor>
        </Trans>
      </div>
      <div className="feedbackModal__owner">
        <div className="feedbackModal__ownerImages">
          {user && user.image_url ?
          <img
            key={user.id}
            src={`${user.image_url}?size=xsmall`}
            alt=""
            className="feedbackModal__avatar"
            height="40"
            width="40"
            onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} /> :


          <DefaultProfilePic key={user.id} size="40" className="feedbackModal__avatar" id={user.id || 1} />}

        </div>
        <p className="feedbackModal__ownerName">{formatName(user)}</p>
      </div>
      <feedbackFetcher.Form onSubmit={provideFeedback}>
        <TextArea
          floatingLabel
          label={t('feedbackModal.descriptionPlaceHolder')}
          placeholder={t('feedbackModal.descriptionPlaceHolder')}
          maxLength={maxLength}
          name="desc"
          data-testid="feedbackModal__textarea__description"
          rows="2"
          error={error}
          counter={responseDescription ? responseDescription.length : '0'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setResponseDescription(e.target.value);
            setError('');
          }}
          textAreaClassName="feedbackModal__textArea" />

        <div className="feedbackModal__privacyPolicy fk-helpText">
          <Trans i18nKey="feedbackModal.privacyPolicy">
            Anything you share is protected by our&nbsp;
            <Anchor to={externalLinks.PrivacyPolicy} data-testid="feedbackModal__button__privacyLink" newTab>
              privacy policy.
            </Anchor>
          </Trans>
        </div>
        <Combobox
          labelClassName="feedbackModal__comboboxLabel"
          label={t('feedbackModal.feedbackTypeLabel')}
          name="feedback_topic"
          onSelect={(selection: string) => setSelectedFeedback(selection)}
          selectedValue={feedbackCategory}
          {...telemetryAttributes(Telemetry.FeedbackModalFeedbackTypeSelection)}>

          {Object.values(feedbackTypes).map((feedback) =>
          <Combobox.Option key={feedback.type} value={feedback.type} />
          )}
        </Combobox>
        <Modal.Actions className="feedbackModal__actions">
          <ButtonSubmit
            type="submit"
            data-testid="feedbackModal__button__submit"
            loading={feedbackFetcher.state !== 'idle'}
            {...telemetryAttributes(Telemetry.SubmitFeedbackModalDone)}
            variant="block">

            {t('common.submit')}
          </ButtonSubmit>
        </Modal.Actions>
        {showDiscardChangesWarning &&
        <DiscardFeedbackModal onRequestClose={() => setShowDiscardChangesWarning(false)} onDiscard={onClose} />}

        <input type="hidden" name="browser" value={ua?.browser} />
        <input type="hidden" name="feedback_type" value="feedback" />
        <input type="hidden" name="role" value="educator" />
        <input type="hidden" name="user_name" value={user.username} />
      </feedbackFetcher.Form>
    </Modal>);

};

export default FeedbackModal;