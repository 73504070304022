/* eslint-disable max-len */
import React, { useId } from 'react';

import myVideosIconStyles from '~/styles/components/Rail/MyVideosIcon.css';

export const links = () => [{ rel: 'stylesheet', href: myVideosIconStyles }];

type Props = {
  className?: string;
  size?: number;
};

const MyVideosIcon = ({ className, size = 22 }: Props) => {
  const maskId1 = useId();
  const maskId2 = useId();

  return (
    <>
      <svg
        viewBox="0 0 22 16"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        className={className}
        width={size}>

        <g mask={`url(#mask-${maskId1})`}>
          <g className="myVideosIcon__el myVideosIcon__el--1" transform="translate(10,6.9)">
            <path
              className="myVideosIcon__el myVideosIcon__el--2"
              d="M14.5,0.9h-9C4.1,0.9,3,2.1,3,3.4v7c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5v-7c0-1.4-1.1-2.5-2.5-2.5ZM12.8,7.4L9.2,9.8c-.5.4-1.2,0-1.2-.6v-4.5c0-.6.7-1,1.2-.6l3.6,2.5c.3.2.3.6,0,.8Z"
              transform="translate(-10,-6.9)"
              fill="var(--fk-color__iconFill)" />

          </g>
          <mask id={`mask-${maskId1}`} className="myVideosIcon__mask">
            <g className="myVideosIcon__el myVideosIcon__el--3" transform="translate(-4.125482,-3.668716)">
              <path
                className="myVideosIcon__el myVideosIcon__el--4"
                d="M-0.817924,19.093693c.92974,5.028,30.51599,7.566938,29.869922,1.38722l.051053-21.100045c-1.054359.000005-16.801212-.62666-16.801212.477909v7.894362c0,1.104569-.895431,2-2,2h-8.301839c-1.104569,0-2.338384-.333367-2.338384.84496l-.47954,8.495594Z"
                transform="scale(1.328314,1.328314) translate(-6.15092,-4.876569)"
                fill="#d2dbed"
                strokeWidth="0" />

            </g>
          </mask>
        </g>

        <g className="myVideosIcon__el myVideosIcon__el--5" transform="translate(12.25,9.15)">
          <path
            className="myVideosIcon__el myVideosIcon__el--6"
            d="M7.5,14.9c-.8,0-1.5-.4-2-1h9.5c1.7,0,3-1.3,3-3v-7.5c.6.5,1,1.2,1,2v5.5c0,2.2-1.8,4-4,4h-7.5Z"
            transform="translate(-12.25,-9.15)"
            fill="var(--fk-color__iconFill)" />

        </g>
        <g className="myVideosIcon__el myVideosIcon__el--7" transform="translate(-2.16,-3.14)">
          <path
            className="myVideosIcon__el myVideosIcon__el--8"
            d="M7.5,14.9c-.8,0-1.5-.4-2-1h9.5c1.7,0,3-1.3,3-3v-7.5c.6.5,1,1.2,1,2v5.5c0,2.2-1.8,4-4,4h-7.5Z"
            transform="translate(-12.25,-9.15)"
            opacity="0"
            fill="var(--fk-color__iconFill)" />

        </g>
        <g mask={`url(#mask-${maskId2})`}>
          <g className="myVideosIcon__el myVideosIcon__el--9" transform="translate(-7,-6)">
            <path
              d="M14.5,0.9h-9C4.1,0.9,3,2.1,3,3.4v7c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5v-7c0-1.4-1.1-2.5-2.5-2.5ZM12.8,7.4L9.2,9.8c-.5.4-1.2,0-1.2-.6v-4.5c0-.6.7-1,1.2-.6l3.6,2.5c.3.2.3.6,0,.8Z"
              transform="translate(-10,-6.9)"
              fill="var(--fk-color__iconFill)" />

          </g>
          <mask id={`mask-${maskId2}`} className="myVideosIcon__mask">
            <rect
              width="14.018363"
              height="12"
              rx="2.5"
              ry="2.5"
              transform="matrix(1.056392 0 0 1.056391 2.586376 0.561654)"
              fill="#d2dbed"
              strokeWidth="0" />

          </mask>
        </g>
      </svg>
    </>);

};

export default React.memo(MyVideosIcon);