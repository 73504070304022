import { Popover } from '@flipgrid/flipkit';
import classNames from 'classnames';
import { useRef, useState, useId } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '../FkWrappers/Link';
import routes from '~/constants/routes';
import myGroupsLinkStyles from '~/styles/components/Rail/MyGroupsLink.css';

import type { SlimGroup } from 'types';

export const links = () => [{ rel: 'stylesheet', href: myGroupsLinkStyles }];

const MyGroupsLink = ({
  index,
  currentIndex,
  isCurrent,
  group,
  collapsedRail






}: {index: number;currentIndex: number;isCurrent: boolean;group: SlimGroup;collapsedRail: boolean;}) => {
  const { t } = useTranslation();
  const [showTooltipText, setShowTooltipText] = useState(false);
  const myGroupsLinkRef = useRef(null);
  const id = useId();
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  // When inside my groups, use page up/down, and tab to get to toggle
  const handleKeyDown = (e: KeyboardEvent) => {
    // This goes up a level and then back down to get to the next focusable group, if we change the html structure, this might have to change
    const next = ((e.currentTarget as HTMLElement)?.parentElement?.nextElementSibling?.firstChild as HTMLElement);
    const previous = ((e.currentTarget as HTMLElement)?.parentElement?.previousElementSibling?.firstChild as HTMLElement);
    const railToggle = (document.querySelector('#railToggle') as HTMLElement);
    const railToggleHidden = railToggle.offsetParent === null; // Check if toggle is visible
    const logoEl = (document.querySelector('#railLogo') as HTMLElement);
    const groupDropdownEl = (document.querySelector('#newGroupDropdownButton') as HTMLElement);

    switch (e.key) {
      case 'Tab':{
          e.preventDefault();
          // If railToggle is hidden, either focus back to logo or groups dropdown depending on tab or shift+tab
          if (railToggleHidden) {
            if (e.shiftKey) {
              groupDropdownEl.focus();
            } else {
              logoEl.focus();
            }
          } else if (e.shiftKey) {
            groupDropdownEl.focus();
          } else {
            railToggle.focus();
          }
          break;
        }
      case 'ArrowDown':{
          e.preventDefault();
          if (next) next.focus();
          break;
        }
      case 'ArrowUp':{
          e.preventDefault();
          if (previous) previous.focus();
        }
    }
  };

  return (
    <Link
      as="nav"
      theme="nav"
      className={classNames('myGroupsLink', {
        'is-active': isCurrent,
        'is-above': index < currentIndex,
        'is-below': index > currentIndex
      })}
      data-testid="myGroupsLink__group"
      to={routes.GROUPS_ID_TOPICS_FUNC(group.id)}
      onKeyDown={handleKeyDown}
      onFocus={() => {
        if (collapsedRail) setShowTooltipText(true);
      }}
      onBlur={() => {
        if (collapsedRail) setShowTooltipText(false);
      }}
      onMouseEnter={() => {
        if (collapsedRail) setShowTooltipText(true);
      }}
      onMouseLeave={() => {
        if (collapsedRail) setShowTooltipText(false);
      }}
      ref={myGroupsLinkRef}
      aria-describedby={showTooltipText ? id : ''}
      aria-label={showTooltipText ? '' : t('myGroupsLink.groupName', { groupName: group.name })}>

      <div className={classNames('myGroupsLink__image', { 'is-loaded': imageIsLoaded })}>
        <img
          src={`${group.image_url}?size=xsmall`}
          alt=""
          loading="lazy"
          onError={() => setImageIsLoaded(true)}
          onLoad={() => setImageIsLoaded(true)} />

      </div>

      {!collapsedRail && <span className="myGroupsLink__name">{group.name}</span>}

      <Popover
        id={id}
        role="tooltip"
        className="myGroupsLink__popover"
        isVisible={showTooltipText}
        onDismiss={() => setShowTooltipText(!showTooltipText)}
        placement="right"
        targetRef={myGroupsLinkRef}
        size="small"
        usePortal>

        <div className="myGroupsLink__text">{group.name}</div>
      </Popover>
    </Link>);

};

export default MyGroupsLink;