import { Button, IcFluentDismiss24Regular, Link } from '@flipgrid/flipkit';
import { useContext, type SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import HorizontalLine from '../Utility/Layout/HorizontalLine';
import routes from '~/constants/routes';
import recorderContext from '~/contexts/recorderContext';
import { RecorderStatusEnum } from '~/enums';
import { downloadVideoFromRecorderSegments, shouldDisplayVideoResponseDetailsLink } from '~/helper/helper';
import { handleError } from '~/helper/imgOnError';

type Props = {
  onClose: () => void;
};

const ActivityFeedUploadProgress = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { recorderStatus, recorderProgress, recorderProps } = useContext(recorderContext);
  const { submittedResponse: response, progress, recorderSegments, thumbnailBlobUrl } = recorderProgress;
  const shouldDisplayMoreDetails = recorderProps && shouldDisplayVideoResponseDetailsLink(recorderProps?.entity?.type);

  const isVideoUploading = recorderStatus === RecorderStatusEnum.Uploading;
  const isVideoUploadError = recorderStatus === RecorderStatusEnum.Error;
  const isVideoUploadSuccess = recorderStatus === RecorderStatusEnum.Uploaded;

  const generateResponsePageUrl = () => {
    const isTopicResponse = recorderProps?.entity?.type?.toLowerCase() === 'topic';

    const searchParams = new URLSearchParams();
    if (response?.id) {
      searchParams.append('loadShareResponse', response.id);
    }

    const navigationRoute = `${
      isTopicResponse
        ? routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(
            recorderProps?.entity?.topic?.grid_id ?? '',
            recorderProps?.entity?.topic?.id ?? '',
          )
        : routes.MY_VIDEOS
    }?${searchParams.toString()}`;

    return navigationRoute;
  };

  return (
    <div className="activityFeedUploadProgress___uploadProgressWrapper">
      <div className="activityFeedUploadProgress___uploadProgress">
        <img
          aria-hidden="true"
          className="activityFeedUploadProgress___uploadCover"
          src={thumbnailBlobUrl}
          alt=""
          onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)}
        />
        <div className="activityFeedUploadProgress___uploadProgressDetailsWrapper">
          {isVideoUploading ? (
            <>
              <div className="activityFeedUploadProgress___uploadProgressDetails">
                <div>{t('activityFeedUploadProgress.uploadingYourVideo')}</div>
                <div>{progress}%</div>
              </div>
              <progress
                className="activityFeedUploadProgress___progressBar"
                value={progress}
                aria-valuemin={0}
                aria-valuenow={progress}
                aria-valuemax={100}
                max={100}
              />
            </>
          ) : isVideoUploadSuccess ? (
            <div className="activityFeedUploadProgress___uploadCompleteWrapper">
              <div className="activityFeedUploadProgress___uploadCompleteDetails">
                <div className="activityFeedUploadProgress___uploadComplete">
                  {t('activityFeedUploadProgress.uploadComplete')}
                </div>
                {shouldDisplayMoreDetails && (
                  <Link
                    to={generateResponsePageUrl()}
                    data-testid="activityFeedUploadProgress__link__moreDetails"
                    variant="text"
                  >
                    {t('shared.moreDetails')}
                  </Link>
                )}
              </div>
              <Button
                aria-label={t('activityFeedUploadProgress.closeUploadProgressDetails')}
                data-testid="activityFeedUploadProgress__button__closeProgress"
                icon={<IcFluentDismiss24Regular />}
                onClick={onClose}
                theme="transparent"
              />
            </div>
          ) : isVideoUploadError ? (
            <div>
              <Trans i18nKey="shared.uploadFailed">
                Your video failed to upload. You can&nbsp;
                <Button
                  onClick={() => downloadVideoFromRecorderSegments(recorderSegments)}
                  data-testid="backgroundUploadBanner__link__downloadVideo"
                  variant="text"
                >
                  download
                </Button>
                &nbsp;your video.
              </Trans>
            </div>
          ) : null}
        </div>
      </div>
      <HorizontalLine className="activityFeedUploadProgress___uploadProgressDivider" />
    </div>
  );
};

export default ActivityFeedUploadProgress;
