import { Button, Modal } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

type Props = {
  onRequestClose: OnRequestCloseType;
  onDiscard: () => void;
};

const DiscardFeedbackModal = ({ onDiscard, onRequestClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('discardFeedbackModal.discardNameChange')}</h1>
      <p className="fk-modalBody fk-m0">{t('discardFeedbackModal.ifYouLeaveNow')}</p>
      <Modal.Actions className="mt32">
        <Button theme="secondary" data-testid="DiscardFeedbackModal__button__keepEditing" onClick={onRequestClose}>
          {t('discardFeedbackModal.keepEditing')}
        </Button>
        <Button data-testid="DiscardFeedbackModal__button__discardChanges" onClick={onDiscard}>
          {t('discardFeedbackModal.discardNameChange')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DiscardFeedbackModal;
