import { Modal } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import ImageCropper, { links as imageCropperLinks } from '../../Cropping/ImageCropper';
import uploadCustomBannerModalStyles from '~/styles/components/Groups/GroupForm/UploadCustomBannerModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ImageUpload } from 'types';

export const links = () => [...imageCropperLinks(), { rel: 'stylesheet', href: uploadCustomBannerModalStyles }];

type Props = {
  onImageUploaded: (imageData: ImageUpload) => void;
  onRequestClose: OnRequestCloseType;
};

const UploadCustomBannerModal = ({ onImageUploaded, onRequestClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="uploadCustomBannerModal__header fk-modalHeader">{t('shared.uploadAnImage')}</h1>
      <div className="uploadCustomBannerModal__imageCropperWrapper">
        <ImageCropper
          onComplete={(upload: ImageUpload) => {
            onImageUploaded(upload);
            onRequestClose(t('uploadCustomBannerModal.groupCoverUpdated'));
          }}
          onRequestClose={onRequestClose}
          cropOptions={{
            x: 0,
            y: 0
          }}
          maxAspect={4}
          minAspect={3}
          dropzoneWidth="500"
          type="group"
          successMessage={t('uploadCustomBannerModal.groupCoverUpdated')}
          shortText />

      </div>
    </Modal>);

};

export default UploadCustomBannerModal;