import {
  DefaultProfilePic,
  Dropdown,
  IcFluentDarkTheme24Filled,
  IcFluentLightbulb24Regular,
  IcFluentPeople24Regular,
  IcFluentPlugDisconnected24Regular,
  IcFluentSettings24Regular,
  IcFluentSignOut24Regular,
  IcFluentTrophy24Regular,
  ThemesEnum,
  Tooltip } from
'@flipgrid/flipkit';
import { useFetcher, useLoaderData, useMatches } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

import DropdownLink from '../FkWrappers/DropdownLink';
import { FeatureFlag, useTheme } from '~/components';
import adminExternalLinks from '~/constants/adminExternalLinks';
import routes from '~/constants/routes';
import { addUrlParams } from '~/helper/addUrlParams';
import { signOut } from '~/helper/helper';
import { handleError } from '~/helper/imgOnError';
import { isUserEducator } from '~/helper/userRoles';
import mainMenuDropdownStyles from '~/styles/components/Navbar/MainMenuDropdown.css';

import type { SyntheticEvent } from 'react';
import type { RouteTyping } from 'types';
import type { loader } from '~/routes/__app';

export const links = () => [{ rel: 'stylesheet', href: mainMenuDropdownStyles }];

const MainMenuDropdown = () => {
  const { t } = useTranslation();
  const data = (useLoaderData<typeof loader>() as RouteTyping<'App'>);
  const user = data?.user?.data;
  const isLead = isUserEducator(user);
  const [routeData] = useMatches();
  const signOutFetcher = useFetcher();
  const { currentTheme, changeTheme, isForcedColors } = useTheme();
  const darkMode = currentTheme === ThemesEnum.dark;
  const handleThemeChange = () => {
    if (darkMode) changeTheme(ThemesEnum.light);else
    changeTheme(ThemesEnum.dark);
  };

  return (
    <Dropdown
      fullWidthMobile
      right
      button={
      <Tooltip label={t('mainMenuDropdown.actionsMenu')}>
          <Dropdown.Button
          theme="nav"
          data-testid="mainMenuDropdown__dropdownButton__profilePicture"
          aria-controls=""
          aria-label={t('mainMenuDropdown.actionsMenu')}
          icon={
          user.image_url ?
          <img
            className="mainMenuDropdown__avatar"
            src={addUrlParams({ size: 'small' }, user.image_url)}
            alt=""
            onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} /> :


          <DefaultProfilePic size="24" id={user.id || 1} />}


          size="36" />

        </Tooltip>}>


      {isLead ?
      <>
          <DropdownLink
          to={routes.GROUPS}
          icon={<IcFluentPeople24Regular />}
          data-testid="mainMenuDropdown__dropdownItem__manageGroups">

            {t('mainMenuDropdown.manageGroups')}
          </DropdownLink>
          <FeatureFlag match="web-settings" showWhenOff>
            <Dropdown.Item
            to={routes.SETTINGS_INTEGRATIONS}
            icon={<IcFluentPlugDisconnected24Regular />}
            data-testid="mainMenuDropdown__dropdownItem__integrations">

              {t('common.integrations')}
            </Dropdown.Item>
            {/* <Dropdown.Item
             href={adminExternalLinks.SETTINGS_URL(routeData.data.env.ADMIN_URL)}
             icon={<IcFluentSettings24Regular />}
             data-testid="mainMenuDropdown__dropdownItem__settings"
            >
             {t('common.settings')}
            </Dropdown.Item> */}
          </FeatureFlag>
          {/* <FeatureFlag match="web-settings">
           <Dropdown.Item
             to={routes.SETTINGS}
             icon={<IcFluentSettings24Regular />}
             data-testid="mainMenuDropdown__dropdownItem__settings"
           >
             {t('common.settings')}
           </Dropdown.Item>
          </FeatureFlag> */}
        </> :
      null}
      {!isForcedColors ?
      <Dropdown.Item
        onSelect={handleThemeChange}
        aria-label={darkMode ? t('shared.changeToLight') : t('shared.changeToDark')}
        icon={<IcFluentDarkTheme24Filled />}>

          {darkMode ? t('shared.lightMode') : t('shared.darkMode')}
        </Dropdown.Item> :
      null}
      <Dropdown.Divider />
      {isLead ?
      <>
          {/* <FeatureFlag match="web-discovery" showWhenOff>
           <Dropdown.Item
             href={adminExternalLinks.DISCOVERY_URL(routeData.data.env.ADMIN_URL)}
             icon={<IcFluentLightbulb24Regular />}
             data-testid="mainMenuDropdown__dropdownItem__discovery"
           >
             {t('mainMenuDropdown.discovery')}
           </Dropdown.Item>
          </FeatureFlag> */}

          <FeatureFlag match="web-discovery">
            <Dropdown.Item
            to={routes.DISCOVERY}
            icon={<IcFluentLightbulb24Regular />}
            data-testid="mainMenuDropdown__dropdownItem__discovery">

              {t('mainMenuDropdown.discovery')}
            </Dropdown.Item>
          </FeatureFlag>

          <Dropdown.Item
          href={routes.ACHIEVEMENTS}
          icon={<IcFluentTrophy24Regular />}
          data-testid="mainMenuDropdown__dropdownItem__achievements">

            {t('mainMenuDropdown.achievements')}
          </Dropdown.Item>
          <Dropdown.Divider />
        </> :
      null}

      <Dropdown.Item
        onSelect={() => signOut(signOutFetcher)}
        icon={<IcFluentSignOut24Regular />}
        data-testid="mainMenuDropdown__dropdownItem__signOut">

        {t('shared.signOut')}
      </Dropdown.Item>
    </Dropdown>);

};

export default MainMenuDropdown;