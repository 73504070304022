import { Button, Modal } from '@flipgrid/flipkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Radio from '~/components/FkWrappers/Radio';
import HorizontalLine, { links as horizontalLineStyles } from '~/components/Utility/Layout/HorizontalLine';
import classroomTypeModalStyles from '~/styles/components/Modals/GroupCreation/ClassroomTypeModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Dispatch, SetStateAction } from 'react';
import type { GridCategory, GridType } from 'types';

export const links = () => [...horizontalLineStyles(), { rel: 'stylesheet', href: classroomTypeModalStyles }];

type Props = {
  gridCategories: GridType[];
  onComplete: () => void;
  onRequestClose: OnRequestCloseType;
  setGroupTypeData: Dispatch<
    SetStateAction<{
      classroomId?: number;
      gridTemplateId: number;
      groupTypeId?: number;
    }>>;

};

const ClassroomTypeModal = ({ gridCategories, onComplete, onRequestClose, setGroupTypeData }: Props) => {
  const { t } = useTranslation();
  const [classroomLevelSelection, setClassroomLevelSelection] = useState<GridCategory>();
  const [classroomCategory] = useState(gridCategories && gridCategories[0]);

  const onSelectionSubmission = (classroomId: number, gridTemplateId: number) => {
    setGroupTypeData({ classroomId, gridTemplateId, groupTypeId: classroomCategory.id });
    onComplete();
  };

  return (
    <Modal onClose={onRequestClose}>
      <div className="text-center">
        <h1 className="fk-modalHeader">{t('shared.createAGroup')}</h1>
        <div
          className="classroomTypeModal__classroomTypeWrapper"
          style={{ backgroundColor: `#${classroomCategory.color}` }}>

          <img className="classroomTypeModal__classroomImage" alt="" loading="lazy" src={classroomCategory.image} />
        </div>
        <p className="fk-modalBody">{t('classroomTypeModal.groupsLikeClasses')}</p>
      </div>
      <HorizontalLine />

      <div role="radiogroup" className="fk-radio__verticalContainer" aria-labelledby="chooseLevel">
        <h2 id="chooseLevel" className="fk-h3">
          {t('classroomTypeModal.chooseLevel')}
        </h2>
        {classroomCategory.categories?.map((category) =>
        <Radio
          key={category.name}
          label={category.name}
          name="classroomLevel"
          onChange={() => setClassroomLevelSelection(category)}
          selected={classroomLevelSelection?.name === category.name}
          theme="block" />

        )}
      </div>

      <Modal.Actions className="mt2">
        <Button
          className="classroomTypeModal__button"
          disabled={!classroomLevelSelection}
          data-testid="classroomTypeModal__button__next"
          onClick={() => {
            if (classroomLevelSelection)
            onSelectionSubmission(classroomLevelSelection.id, classroomLevelSelection.template_id);
          }}
          variant="block">

          {t('classroomTypeModal.nextButton')}
        </Button>
      </Modal.Actions>
    </Modal>);

};

export default ClassroomTypeModal;