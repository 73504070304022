import {
  ButtonSubmit,
  DefaultProfilePic,
  EmptyState,
  ProfileCard,
  ProfileImage,
  ProfileImageTypeEnum } from
'@flipgrid/flipkit';
import { useFetcher, useLocation, useNavigate } from '@remix-run/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MemberActionsDropdown, { links as memberActionsDropdownStyles } from './MemberActionsDropdown';
import MemberListPanelProfileActions, {
  links as memberListPanelProfileActionsStyles } from
'./MemberListPanelProfileActions';
import MemberListPanelWrapper, { links as memberListPanelWrapperStyles } from './MemberListPanelWrapper';
import InfiniteScroll from '../Utility/InfiniteScroll';
import routes from '~/constants/routes';
import topicListContext from '~/contexts/topicListContext';
import { formatName, getFirstLastNameOrFallback } from '~/helper/formatting';
import { isMembershipGroupLead } from '~/helper/userRoles';
import memberListPanelStyles from '~/styles/components/Members/MemberListPanel.css';

import type { Dispatch, SetStateAction } from 'react';
import type { ApiResponse, Group, Member, Pagination, RouteTyping } from 'types';

export const links = () => [
...memberActionsDropdownStyles(),
...memberListPanelProfileActionsStyles(),
...memberListPanelWrapperStyles(),
{ rel: 'stylesheet', href: memberListPanelStyles }];


type Props = {
  group: Group;
  isCurrentUserGroupOwner: boolean;
  memberList?: ApiResponse<Member[]>;
  memberStatus: 'members' | 'blocked' | 'requested';
  members?: Member[];
  pagination?: Pagination;
  setMember: Dispatch<SetStateAction<Member[] | undefined>> | Dispatch<SetStateAction<Member[]>>;
  setPagination: Dispatch<SetStateAction<Pagination | undefined>>;
};

const MemberListPanel = ({
  group,
  isCurrentUserGroupOwner,
  memberList,
  memberStatus,
  members,
  pagination,
  setMember,
  setPagination
}: Props) => {
  const { t } = useTranslation();
  const { studentView } = useContext(topicListContext);
  const location = (useLocation() as {state?: {hasUncachedMembers?: boolean;members: Member[];};});
  const navigate = useNavigate();
  const { membership } = group;
  const userActionFetcher = useFetcher();
  const memberFetcher = useFetcher<RouteTyping<'GroupsMembersTab'>>();
  const allMemberFetcher = useFetcher();
  const [allMembers, setAllMembers] = useState<Member[]>();
  const [loadAll, setLoadAll] = useState(true);
  const [hasUncachedMembers, setHasUncachedMembers] = useState(location.state?.hasUncachedMembers);
  const isInvitedMember = (member: Member) => !member.first_name;
  const isGroupLead = isMembershipGroupLead(membership, studentView);
  const isMemberPage = memberStatus === 'members';
  const isUsernameGroup = group.access_control === 'student';
  const isMemberOrRtjTab = memberStatus === 'members' || memberStatus === 'requested';

  const getMembers = (memberFetcherData: RouteTyping<'GroupsMembersTab'>) => {
    const memberData = memberFetcherData[memberStatus];
    if (memberData) {
      if (memberData.metadata?.pagination?.current_page === 1) {
        setMember(memberData.data);
        setPagination(memberData.metadata?.pagination);
      } else {
        if (members && members.length > 0) {
          const fetcherResponse = [...members, ...memberData.data];
          setMember(fetcherResponse);
        }
        setPagination(memberData.metadata?.pagination);
      }
    }
  };

  const navigateToMemberResponsePage = (memberId: number) => {
    if (!isCurrentUserGroupOwner || !isMemberPage) return;
    navigate(routes.GROUPS_ID_MEMBERS_ID_FUNC(group.id, memberId));
  };

  const handleResponsePageNavigation = (e: MouseEvent, memberId: number) => {
    // do not navigate if target is an anchor or button, or a non-lead member
    if ((e.target as HTMLElement).closest('a, button')) return;
    if (!e.defaultPrevented) navigateToMemberResponsePage(memberId);
  };

  const handleProfileCardKeydown = (e: KeyboardEvent, memberId: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      navigateToMemberResponsePage(memberId);
    }
  };
  useEffect(() => {
    if (hasUncachedMembers && memberFetcher.state === 'idle') {
      setHasUncachedMembers(false);
      memberFetcher.load(routes.GROUPS_ID_MEMBERS_FUNC(group.id));
    }
  }, [group.id, hasUncachedMembers, memberFetcher]);

  useEffect(() => {
    if ((memberStatus === 'blocked' || memberStatus === 'requested') && loadAll && allMemberFetcher.state === 'idle') {
      setLoadAll(false);
      const qs = new URLSearchParams([
      ['status', memberStatus],
      ['load_all', 'true']]
      );
      allMemberFetcher.load(`${routes.GROUPS_ID_MEMBERS_FUNC(group.id)}?${qs}`);
    }
  }, [allMemberFetcher, group.id, memberStatus, loadAll, memberFetcher.state]);

  useEffect(() => {
    if (allMemberFetcher.data) {
      setAllMembers(allMemberFetcher.data.data);
    }
  }, [allMemberFetcher.data, allMembers]);

  useEffect(() => {
    setLoadAll(true);
  }, [members]);

  useEffect(() => {
    if (memberFetcher.data) {
      getMembers(memberFetcher.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberFetcher.data]);

  const membersToBatchUpdate = (membersToUpdate: Member[], type: string) => {
    const updatedList = membersToUpdate.map((member) =>
    type === 'unblock' ? { id: member.id, blocked: false } : { id: member.id, role: 'member' }
    );
    return updatedList;
  };

  const getDisplayMeta = (member: Member) => {
    const getMemberRole = () => {
      if (member.role === 'pending') {
        // only show pending text if the member is the current user or the group lead
        if (member.user_id === membership?.user_id || isGroupLead) return t('shared.pendingColead');
        return t('common.student');
      }
      if (member.blocked) return member.email;
      switch (member.role) {
        case 'invited':
          return t('common.invited');
        case 'owner':
          return t('common.educator');
        case 'co_owner':
          return t('common.colead');
        case 'requested':
          return '';
        case 'member':
          return t('common.student');
      }
    };

    const role = getMemberRole();
    const name = getFirstLastNameOrFallback(member);

    // remove falsy values and add a delimiter
    return [name, member.email, role].filter((value) => !!value).join(' • ');
  };

  const shouldShowProfileActions = (memberCard: Member) => {
    const isCurrentUser = memberCard.user_id === membership?.user_id;
    const memberCardIsGroupOwner = memberCard.role === 'owner';
    if (isUsernameGroup && isGroupLead && !(memberCardIsGroupOwner && !isCurrentUser)) return true;
    if (memberCard.role === 'co_owner' && isCurrentUser) return true;
    if (memberCard.role === 'co_owner' && membership?.role !== 'owner') return false;
    return isGroupLead && !isCurrentUser && !memberCardIsGroupOwner;
  };

  const loadMoreMembers = () => {
    if (pagination) {
      const qs = new URLSearchParams([
      ['page', (pagination.current_page + 1).toString()],
      ['_action', memberStatus]]
      );
      memberFetcher.load(`${routes.GROUPS_ID_MEMBERS_FUNC(group.id)}?${qs}`);
    }
  };

  const getTitle = (status: string) => {
    const count = { count: memberList?.metadata?.pagination?.total || 0 };
    switch (status) {
      case 'members':
        return t('members.membersCount', { count: group.member_count });
      case 'blocked':
        return t('members.blockedStudents', count);
      case 'requested':
        return t('members.joinRequests', count);
    }
  };

  return (
    <MemberListPanelWrapper memberPanel={isMemberOrRtjTab} isLead={isGroupLead} title={getTitle(memberStatus) || ''}>
      {members && members.length > 0 &&
      <>
          {memberStatus === 'blocked' || memberStatus === 'requested' ?
        // TODO: Replace with text variant ButtonSubmit when created in Fk
        <userActionFetcher.Form method="post" className="memberListPanel__allButtonForm">
              <ButtonSubmit
            className="memberListPanel__allButton"
            theme="transparent"
            data-testid={`membersList__button__${memberStatus === 'blocked' ? 'unblock' : 'accept'}All`}
            name="_action"
            value="unblockAll"
            loading={userActionFetcher.state !== 'idle'}
            size="26">

                {memberStatus === 'blocked' ? t('shared.unblockAll') : t('members.acceptAll')}
              </ButtonSubmit>
              <input
            type="hidden"
            name="students"
            value={JSON.stringify(membersToBatchUpdate(members, memberStatus === 'blocked' ? 'unblock' : 'accept'))} />

              <input type="hidden" name="gridID" value={group.id} />
            </userActionFetcher.Form> :
        null}
          <div className="fk-profileCardLayout">
            {members.map((member) =>
          <ProfileCard
            role="button"
            className={isCurrentUserGroupOwner && isMemberPage ? 'pointer' : ''}
            tabIndex={isCurrentUserGroupOwner ? 0 : -1}
            onClick={(e: MouseEvent) => handleResponsePageNavigation(e, member.id)}
            onKeyDown={(e: KeyboardEvent) => handleProfileCardKeydown(e, member.id)}
            actions={
            isMemberPage ?
            shouldShowProfileActions(member) && <MemberActionsDropdown group={group} member={member} /> :

            <userActionFetcher.Form
              method="post"
              className={memberStatus === 'requested' ? 'memberListPanel__rtjActions' : ''}>

                      <MemberListPanelProfileActions
                userActionFetcherState={userActionFetcher.state}
                memberStatus={memberStatus}
                group={group}
                member={member} />

                    </userActionFetcher.Form>}


            meta={getDisplayMeta(member) || ''}
            profileName={isInvitedMember(member) ? member.email : formatName(member)}
            profileImage={
            !member.image_url && !isInvitedMember(member) ?
            <DefaultProfilePic size="60" id={member.id} aria-hidden="true" /> :
            member.image_url ?
            <ProfileImage
              src={`${member.image_url}?size=xsmall`}
              type={ProfileImageTypeEnum.default}
              aria-hidden="true" /> :


            <ProfileImage
              type={isInvitedMember(member) ? ProfileImageTypeEnum.domain : ProfileImageTypeEnum.member}
              aria-hidden="true" />}



            key={member.id} />

          )}
          </div>
          <EmptyState loading={memberFetcher.state === 'loading'} hide={!!(members.length && members.length > 0)} />
          <InfiniteScroll pagination={pagination} fetcherState={memberFetcher.state} load={loadMoreMembers} />
        </>}

    </MemberListPanelWrapper>);

};

export default MemberListPanel;