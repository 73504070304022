import ReactDragger from 'react-physics-dragger';

import draggerStyles from '~/styles/components/Dragger/Dragger.css';

import type { LinksFunction } from '@remix-run/node';

type Props = {
  children: JSX.Element | JSX.Element[];
  className: string;
  dataSetIsLoaded: boolean;
  disabled: boolean;
  friction?: number;
  onStaticClick: (value: TSFix) => void;
  setFormState: (values: TSFix) => void;
};

type Frame = {
  x: number;
  innerWidth: number;
  outerWidth: number;
  progress: number;
};

export type DraggerFormState = {
  disableNext: boolean;
  disablePrec: boolean;
  frame: Frame;
  showButtons: boolean;
};

export type DraggerRef = {
  draggerRef: {
    innerWidth: number;
    outerWidth: number;
    setPosition: (position: number, instant: boolean) => void;
  };
};

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: draggerStyles }];

const Dragger = ({
  children,
  className = '',
  dataSetIsLoaded = true,
  disabled = false,
  friction = 0.9,
  onStaticClick = () => {},
  setFormState = () => {}
}: Props) => {
  const onFrame = (frame: Frame) => {
    const progress = frame?.progress;
    const frameInnerWidth = frame.innerWidth;
    const frameOuterWidth = frame.outerWidth;
    const showButtons = frameInnerWidth > frameOuterWidth;
    setFormState({ disableNext: progress >= 1, disablePrev: progress <= 0, frame, showButtons });
  };

  const styles = disabled ? `dragger__cursor -disabled ${className}` : className;

  if (!dataSetIsLoaded) return null;

  return (
    <ReactDragger
      disabled={disabled}
      className={styles}
      friction={friction}
      onStaticClick={onStaticClick}
      onFrame={onFrame}
      draggerRef={(r) => {
        setFormState({ draggerRef: r });
      }}>

      {children}
    </ReactDragger>);

};

export default Dragger;