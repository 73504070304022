import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { has } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '~/components/FkWrappers/Input';
import routes from '~/constants/routes';
import { USER_ERRORS } from '~/helper/usernameCsvUploadHelpers';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, Member } from 'types';

type ErrorResponse = {
  first_name: boolean;
  last_name: boolean;
  identifier: boolean;
};

type FetcherResponse = {
  errors?: ErrorResponse;
  data?: ApiResponse<Member, Member, { model: { [key: string]: { error?: string; value?: string } } }>;
};

type Props = {
  member: Member;
  onRequestClose: OnRequestCloseType;
};

const EditUsernameModal = ({ member, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<FetcherResponse>();
  const [validation, setValidation] = useState({ firstName: false, lastName: false, uid: false, uidDuplicate: false });

  const resetFetcher = () => {
    fetcher.data = undefined;
  };

  useEffect(() => {
    if (fetcher.type === 'done') {
      const hasValidationErrors = Object.keys(fetcher.data?.errors ?? {}).length > 0; // client level validation
      const isDuplicateUid = has(fetcher, 'data.error.model.uid'); // server validation
      if (hasValidationErrors || isDuplicateUid) {
        setValidation({
          firstName: !!fetcher.data.errors?.first_name,
          lastName: !!fetcher.data.errors?.last_name,
          uid: !!fetcher.data.errors?.identifier,
          uidDuplicate: isDuplicateUid,
        });
        return;
      }

      onRequestClose(t('editUsernameModal.successfullyUpdatedMember'));
    }
  }, [fetcher, onRequestClose, t]);

  return (
    <Modal show onClose={onRequestClose}>
      <fetcher.Form method="post" action={routes.GROUPS_ID_MEMBERS_FUNC(member.grid_id)} onSubmit={resetFetcher}>
        <h1 className="fk-modalHeader">{t('shared.editDetails')}</h1>
        <div className="fk-modalBody mt1">
          <Input
            aria-required="true"
            autoComplete="off"
            data-testid="editUsernameModal__input__firstName"
            defaultValue={member.first_name}
            error={validation.firstName ? USER_ERRORS().FIRST_NAME_REQUIRED : undefined}
            label={t('common.firstName')}
            maxLength={50}
            name="first_name"
            onChange={() => (validation.firstName ? setValidation(prev => ({ ...prev, firstName: false })) : undefined)}
            placeholder={t('common.firstName')}
            type="text"
            wrapperClassName="mb1"
            floatingLabel
          />
          <Input
            aria-required="true"
            autoComplete="off"
            data-testid="editUsernameModal__input__lastName"
            defaultValue={member.last_name}
            error={validation.lastName ? USER_ERRORS().LAST_NAME_REQUIRED : undefined}
            label={t('common.lastName')}
            maxLength={50}
            name="last_name"
            onChange={() => (validation.lastName ? setValidation(prev => ({ ...prev, lastName: false })) : undefined)}
            placeholder={t('common.lastName')}
            type="text"
            wrapperClassName="mb1"
            floatingLabel
          />
          <Input
            aria-required="true"
            autoComplete="off"
            data-testid="editUsernameModal__input__identifier"
            defaultValue={member.uid}
            error={
              validation.uid
                ? USER_ERRORS().USERNAME_TOO_SHORT
                : validation.uidDuplicate
                  ? USER_ERRORS().USERNAME_OVERLAP
                  : undefined
            }
            onChange={() =>
              validation.uid || validation.uidDuplicate
                ? setValidation(prev => ({ ...prev, uid: false, uidDuplicate: false }))
                : undefined
            }
            label={t('common.identifier')}
            maxLength={50}
            name="uid"
            placeholder={t('common.identifier')}
            type="text"
            wrapperClassName="mb1"
            floatingLabel
          />
        </div>
        <Modal.Actions className="mt2">
          <Button theme="secondary" data-testid="editUsernameModal__button__cancel" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="updateUsername"
            loading={fetcher.state !== 'idle'}
            data-testid="editUsernameModal__button__block"
          >
            {t('common.save')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="gridID" value={member.grid_id} />
        <input type="hidden" name="memberID" value={member.id} />
      </fetcher.Form>
    </Modal>
  );
};
export default EditUsernameModal;
