import { Button } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import externalLinks from '~/constants/externalLinks';
import Telemetry from '~/constants/telemetry';
import { telemetryAttributes } from '~/helper/helper';
import exploreDiscoveryCard from '~/styles/components/TopicList/ExploreDiscoveryCard.css';

import type { LinksFunction } from '@remix-run/node';

type Props = {
  discoveryTopicTotal: number;
  draggerDisabled: boolean;
  exploreOnClick: () => void;
};

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: exploreDiscoveryCard }];
};

const ExploreDiscoveryCard = ({ discoveryTopicTotal, draggerDisabled, exploreOnClick }: Props) => {
  const { t } = useTranslation();
  const imageUrl = externalLinks.TopicEmptyStateExploreDiscover;

  return (
    <div className="exploreDiscoveryCard__cardContainer" key="explore-discovery-card">
      <Button
        theme="clear"
        className="exploreDiscoveryCard__cardButton"
        data-testid="exploreDiscoveryCard__button__discovery"
        data-explore="exploreDiscovery"
        onClick={() => {
          if (draggerDisabled) {
            exploreOnClick();
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          if (!draggerDisabled && (e.key === 'Enter' || e.key === ' ')) {
            exploreOnClick();
          }
        }}
        {...telemetryAttributes(Telemetry.TopicEmptyStateExploreDiscoveryCard)}>

        <img src={imageUrl} alt="" loading="lazy" className="exploreDiscoveryCard__card" />
        <h4 className="fk-h3 exploreDiscoveryCard__text">
          {t('exploreDiscoveryCard.browseTopics', { discoTopicTotal: discoveryTopicTotal || 0 })}
        </h4>
      </Button>
      <Button
        theme="secondary"
        variant="block"
        size="44"
        data-testid="exploreDiscoveryCard__link__discovery"
        data-explore="exploreDiscovery"
        onClick={() => {
          if (draggerDisabled) {
            exploreOnClick();
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          if (!draggerDisabled && (e.key === 'Enter' || e.key === ' ')) {
            exploreOnClick();
          }
        }}
        {...telemetryAttributes(Telemetry.TopicEmptyStateExploreDiscoveryButton)}>

        {t('exploreDiscoveryCard.exploreDiscovery')}
      </Button>
    </div>);

};

export default ExploreDiscoveryCard;