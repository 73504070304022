import { Anchor, Button, IcFluentChevronLeft24Regular, IcFluentChevronRight24Regular, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationTypes } from 'types';
import resourceRoutes from '~/constants/resourceRoutes';
import notificationsModalStyle from '~/styles/components/Modals/Notification/NotificationsModal.css';

import type { ApiResponse, NotificationData } from 'types';

export const links = () => [{ rel: 'stylesheet', href: notificationsModalStyle }];

const Dots = (active: number, number: number) => {
  return (
    <div className="dots__container">
      {Array(number).map((_, i) =>
      <div className={'dots__dot ' + (active === i ? '-active' : '')} />
      )}
    </div>);

};

const NotificationsModal = () => {
  const fetcher = useFetcher<ApiResponse<NotificationData[]>>();
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [notificationNum, setNotificationNum] = useState(0);

  useEffect(() => {
    fetcher.load(resourceRoutes.notifications);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fetcher.type === 'done' && fetcher?.data?.data?.length > 0) {
      setNotifications(fetcher.data.data);
    }
  }, [fetcher]);

  if (!notifications?.length) return null;

  function pageNotifications(direction: 'left' | 'right') {
    if (direction === 'left') {
      setNotificationNum(notificationNum - 1);
    } else if (direction === 'right') {
      setNotificationNum(notificationNum + 1);
    }
  }

  const clearNotifications = () => {
    const formData = new FormData();
    formData.append('_action', NotificationTypes.clearNotifications);
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.notifications });
    setNotifications([]);
  };

  function previousButton(className: string) {
    if (notificationNum === 0) return null;

    return (
      <Button
        data-testid="notificationsModal__button__previous"
        className={'notificationsModal__previous ' + className}
        icon={<IcFluentChevronLeft24Regular className="draggerPrev__icon" />}
        onClick={() => pageNotifications('left')}
        variant="circle"
        size="36" />);


  }

  function nextButton(className: string) {
    if (notificationNum === notifications.length - 1) return null;

    return (
      <Button
        data-testid="notificationsModal__button__next"
        className={'notificationsModal__next ' + className}
        icon={<IcFluentChevronRight24Regular className="draggerNext__icon" />}
        onClick={() => pageNotifications('right')}
        variant="circle"
        size="36" />);


  }

  function getNotification() {
    const notification = notifications[notificationNum];

    return (
      <Fragment key={notification.id}>
        <div className="notificationModal__imageContainer">
          {previousButton('-inside')}
          <img src={notification.notification.image} className="notificationsModal__image" alt="" />
          {nextButton('-inside')}
        </div>
        <div className="notificationsModal__text">
          <h1 className="fk-modalHeader">{notification.notification.title}</h1>
          <p className="fk-modalBody">{notification.notification.text}</p>
          {notification.notification.link &&
          <Modal.Actions justify="center" className="mt1">
              <Anchor
              onClick={notifications && notifications.length === 1 ? clearNotifications : null}
              to={notification.notification.link}
              variant="button"
              data-testid="notificationsModal__anchor__description"
              newTab>

                {notification.notification.link_description ?
              notification.notification.link_description :
              t('notificationsModal.learnMore')}
              </Anchor>
            </Modal.Actions>}

          {notifications.length > 1 && Dots(notifications.length, notificationNum)}
        </div>
      </Fragment>);

  }

  return (
    <Modal className="notificationsModal" show onClose={clearNotifications} closeButtonTheme="overlay">
      {previousButton('-outside')}
      {getNotification()}
      {nextButton('-outside')}
    </Modal>);

};

export default NotificationsModal;