import { EmptyState } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import TopicListEmptyState from './TopicListEmptyState';
import { InfiniteScroll, TopicListCard } from '~/components';
import topicListContext from '~/contexts/topicListContext';
import { loadMoreTopics } from '~/helper/loadMore';
import { isMemberGroupLead } from '~/helper/userRoles';
import topicIndexStyles from '~/styles/routes/index/topicsIndex.css';

import type { ApiResponse, Group, Topic } from 'types';

export const links = () => {
  return [
  { rel: 'preload', href: topicIndexStyles, as: 'style' },
  { rel: 'stylesheet', href: topicIndexStyles }];

};

type Props = {
  group: Group;
};

const TopicList = ({ group }: Props) => {
  const { topics, setTopics, topicsMetadata, setTopicsMetadata } = useContext(topicListContext);
  const { t } = useTranslation();
  const fetcher = useFetcher<ApiResponse<Topic[]>>();
  const pagination = topicsMetadata?.pagination;
  const { studentView } = useContext(topicListContext);
  const isGroupLeadView = isMemberGroupLead(group, studentView);

  useEffect(() => {
    if (fetcher.data && fetcher.type === 'done') {
      setTopics((prev) => [...(prev || []), ...fetcher.data.data]);
      setTopicsMetadata(fetcher.data.metadata);
    }
  }, [fetcher, setTopics, setTopicsMetadata]);

  const loadMore = () => {
    loadMoreTopics(group.id, fetcher, pagination);
  };

  return (
    <>
      {topics && topics.length > 0 ?
      <>
          <div
          className="topicList__container"
          role="feed"
          aria-label={t('topicListCard.navigateTopicResponses')}
          aria-busy={fetcher.state === 'loading'}>

            {topics.map((topic, index) => {
            return (
              <TopicListCard
                key={topic?.id ?? index}
                cardIndex={index}
                group={group}
                isGroupLead={isGroupLeadView}
                topic={topic}
                variant="groupDetails" />);


          })}
          </div>
          <InfiniteScroll pagination={pagination} fetcherState={fetcher.state} load={loadMore} rootMargin="500px 0px" />
          <EmptyState hide={topics && topics.length > 0} loading={fetcher.state === 'loading'} />
        </> :

      <TopicListEmptyState group={group} />}

    </>);

};

export default TopicList;